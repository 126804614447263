import React, { useContext, useEffect, useState } from 'react'
import "../Home/Home.css"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import calnder from "../../img/calnder.png"
import refress from "../../img/refress.png"
import CreateInvoice from "../../img/CreateInvoice.png"
import  humbleicons_user from "../../img/humbleicons_user-remove.png"
import  basil_notification from "../../img/basil_notification-on-outline.png"
import  teenyicons_box from "../../img/teenyicons_box-outline.png"
import  mingcute_user from "../../img/mingcute_user-add-line.png"
import { useDispatch, useSelector } from 'react-redux';
import { addvendors, addvendors2 } from '../../userSlice';
import { toast } from 'react-toastify';
import { NameContext } from '../../NameContext';
function Vendorshead() {
    const [startDate, setStartDate] = useState(new Date());
    const [name, setname] = useState("")
    const [email, setemail] = useState("")
    const [number, setnumber] = useState("")
    const [gst_number, setgst_number] = useState("")
    const dispatch = useDispatch()

    const { headDate, updateDate } = useContext(NameContext);

    const { isAuthenticated, error, isLoading, userData } = useSelector((state) => state.custom2)
const addvender = async()=>{

  dispatch(addvendors2({name,email,number,gst_number}))
}
useEffect(()=>{

//  if (error) {
//   toast.error(error)
//  }
//  console.log(userData,"kjjssisii");
//   if(userData){
//     toast.success(userData.message)

//   }
},[userData,error])
  return (
    <div className='row mx-1 mt-3 main-div-das m-0 p-0 g-0 '  >
     <div className='col-lg-4 '  >
        <div className='card py-2 '  >
            <div className='sho-date' >
            <div>
            <p className='ms-2 mt-2' style={{color:"#364050",fontWeight:"bold"}}>Showing Data of </p>
            </div>
            <div className='py-2' style={{display:"flex",justifyContent:"center",alignItems:"center",backgroundColor:"#F4F8FC"}} >
            <img src={calnder} alt='s.techooze' style={{height:"20px",width:"20px"}} />
            <DatePicker  className="custom-datepicker" selected={headDate} onChange={(date) => updateDate(date)}  />

            </div>
            <div style={{display:"flex",justifyContent:"center",alignItems:"center"}} >
           <div>
           <p className='mt-3' >Refresh  </p>
           </div>
           <div>

            <img className='ms-2' src={refress} alt='s.techooze' style={{height:"20px",width:"20px"}} />
           </div>
            </div>
            </div>
        </div>
     </div>
     <div className='col-lg-8 m-0 p-0 g-0' >
    <div className='card' style={{width:"38vw"}} >
    <div className='quick-main-div2' >
    <div className='quick-chaild-div ' style={{border:"none"}} >
      <p>Quick Actions</p>
    </div>
    <div className='quick-chaild-div py-4' style={{display:"flex",justifyContent:"center",alignItems:"center",cursor:"pointer"}} data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" >
      <img src={mingcute_user} alt='s.techooze' style={{height:"20px",width:"20px"}} />
      <p className='mt-3 ms-1' >Add Vendor</p>
    </div>
    <div className='quick-chaild-div py-4' style={{display:"flex",justifyContent:"center",alignItems:"center"}} >
      <img src={humbleicons_user} alt='s.techooze' style={{height:"20px",width:"20px"}} />
      <p className='mt-3 ms-1' >Remove Vendor</p>
    </div>
    <div className='quick-chaild-div py-4' style={{display:"flex",justifyContent:"center",alignItems:"center"}} >
      {/* <img src={CreateInvoice} alt='s.techooze' style={{height:"20px",width:"20px"}} /> */}
      <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.25 2.9375C0.25 2.19158 0.546316 1.47621 1.07376 0.948762C1.60121 0.421316 2.31658 0.125 3.0625 0.125H14.9375C15.6834 0.125 16.3988 0.421316 16.9262 0.948762C17.4537 1.47621 17.75 2.19158 17.75 2.9375V11.0625C17.75 11.8084 17.4537 12.5238 16.9262 13.0512C16.3988 13.5787 15.6834 13.875 14.9375 13.875H3.0625C2.31658 13.875 1.60121 13.5787 1.07376 13.0512C0.546316 12.5238 0.25 11.8084 0.25 11.0625V2.9375ZM3.0625 1.375C2.6481 1.375 2.25067 1.53962 1.95765 1.83265C1.66462 2.12567 1.5 2.5231 1.5 2.9375V3.875H16.5V2.9375C16.5 2.5231 16.3354 2.12567 16.0424 1.83265C15.7493 1.53962 15.3519 1.375 14.9375 1.375H3.0625ZM1.5 11.0625C1.5 11.4769 1.66462 11.8743 1.95765 12.1674C2.25067 12.4604 2.6481 12.625 3.0625 12.625H14.9375C15.3519 12.625 15.7493 12.4604 16.0424 12.1674C16.3354 11.8743 16.5 11.4769 16.5 11.0625V5.125H1.5V11.0625ZM12.125 8.875H14C14.1658 8.875 14.3247 8.94085 14.4419 9.05806C14.5592 9.17527 14.625 9.33424 14.625 9.5C14.625 9.66576 14.5592 9.82473 14.4419 9.94194C14.3247 10.0592 14.1658 10.125 14 10.125H12.125C11.9592 10.125 11.8003 10.0592 11.6831 9.94194C11.5658 9.82473 11.5 9.66576 11.5 9.5C11.5 9.33424 11.5658 9.17527 11.6831 9.05806C11.8003 8.94085 11.9592 8.875 12.125 8.875Z" fill="#5560A0"/>
</svg>

      <p className='mt-3 ms-1' >Make Payment</p>
    </div>
    
    
    </div>
    </div>
     </div>

<div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel" style={{width:"50vw"}} >
  <div class="offcanvas-header">
  <div style={{display:"flex"}} >

    <button type="button" class="btn-close text-reset  " data-bs-dismiss="offcanvas" aria-label="Close"></button>
    <h5 id="offcanvasRightLabel" className='ms-4 dhjdhjdh' >Add Vendor</h5>
  </div>
  </div>
  <div class="offcanvas-body">
  <div class="input-group mb-3 kokdkdjd p-3">
  <span class="input-group-text jdjddu" id="basic-addon1">
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_116_1850)">
<g clip-path="url(#clip1_116_1850)">
<path d="M17.25 17.25V19.5H6.375C5.47989 19.5 4.62145 19.1444 3.98851 18.5115C3.35558 17.8786 3 17.0201 3 16.125C3 15.2299 3.35558 14.3715 3.98851 13.7385C4.62145 13.1056 5.47989 12.75 6.375 12.75H6.75V11.25H6.375C5.08207 11.25 3.84209 11.7636 2.92785 12.6779C2.01361 13.5921 1.5 14.8321 1.5 16.125C1.5 17.4179 2.01361 18.6579 2.92785 19.5721C3.84209 20.4864 5.08207 21 6.375 21H17.25V23.25H23.25V17.25H17.25ZM21.75 21.75H18.75V18.75H21.75V21.75Z" fill="#808792"/>
<path d="M15.75 16.5H14.25V14.25H9.75V16.5H8.25V14.25C8.2504 13.8523 8.40856 13.471 8.68978 13.1898C8.971 12.9086 9.3523 12.7504 9.75 12.75H14.25C14.6477 12.7504 15.029 12.9086 15.3102 13.1898C15.5914 13.471 15.7496 13.8523 15.75 14.25V16.5ZM12 12C11.555 12 11.12 11.868 10.75 11.6208C10.38 11.3736 10.0916 11.0222 9.92127 10.611C9.75097 10.1999 9.70642 9.74751 9.79323 9.31105C9.88005 8.87459 10.0943 8.47368 10.409 8.15901C10.7237 7.84434 11.1246 7.63005 11.561 7.54323C11.9975 7.45642 12.4499 7.50097 12.861 7.67127C13.2722 7.84157 13.6236 8.12996 13.8708 8.49997C14.118 8.86998 14.25 9.30499 14.25 9.75C14.2494 10.3466 14.0122 10.9185 13.5903 11.3403C13.1685 11.7622 12.5966 11.9994 12 12ZM12 9C11.8517 9 11.7067 9.04399 11.5833 9.1264C11.46 9.20881 11.3639 9.32594 11.3071 9.46299C11.2503 9.60003 11.2355 9.75083 11.2644 9.89632C11.2933 10.0418 11.3648 10.1754 11.4697 10.2803C11.5746 10.3852 11.7082 10.4567 11.8537 10.4856C11.9992 10.5145 12.15 10.4997 12.287 10.4429C12.4241 10.3861 12.5412 10.29 12.6236 10.1667C12.706 10.0433 12.75 9.89834 12.75 9.75C12.7498 9.55115 12.6707 9.3605 12.5301 9.21989C12.3895 9.07928 12.1989 9.0002 12 9Z" fill="#808792"/>
<path d="M17.625 3H6.75V0.75H0.75V6.75H6.75V4.5H17.625C18.5201 4.5 19.3785 4.85558 20.0115 5.48851C20.6444 6.12145 21 6.97989 21 7.875C21 8.77011 20.6444 9.62855 20.0115 10.2615C19.3785 10.8944 18.5201 11.25 17.625 11.25H17.25V12.75H17.625C18.9179 12.75 20.1579 12.2364 21.0721 11.3221C21.9864 10.4079 22.5 9.16793 22.5 7.875C22.5 6.58207 21.9864 5.34209 21.0721 4.42785C20.1579 3.51361 18.9179 3 17.625 3ZM5.25 5.25H2.25V2.25H5.25V5.25Z" fill="#808792"/>
</g>
</g>
<defs>
<clipPath id="clip0_116_1850">
<rect width="24" height="24" fill="white"/>
</clipPath>
<clipPath id="clip1_116_1850">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>

  </span>
  <input type="text" class="form-control jdjddu" onChange={(e)=>{setname(e.target.value)}} placeholder="Name of Vendor" aria-label="Username" aria-describedby="basic-addon1"/>
</div>
  <div class="input-group mb-3 kokdkdjd p-3">
  <span class="input-group-text jdjddu" id="basic-addon1">
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19.875 4.5H4.125C3.08947 4.5 2.25 5.33947 2.25 6.375V17.625C2.25 18.6605 3.08947 19.5 4.125 19.5H19.875C20.9105 19.5 21.75 18.6605 21.75 17.625V6.375C21.75 5.33947 20.9105 4.5 19.875 4.5Z" stroke="#808792" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.25 7.5L12 12.75L18.75 7.5" stroke="#808792" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


  </span>
  <input type="text" class="form-control jdjddu" onChange={(e)=>{setemail(e.target.value)}} placeholder="Email Address" aria-label="Username" aria-describedby="basic-addon1"/>
</div>
  <div class="input-group mb-3 kokdkdjd p-3">
  <span class="input-group-text jdjddu" id="basic-addon1">
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.6424 6.57251C4.40365 5.00001 5.5124 3.58751 7.20615 3.07001C7.50672 2.97811 7.83102 3.00427 8.11297 3.14316C8.39492 3.28205 8.61331 3.52321 8.72365 3.81751L9.2674 5.26751C9.35493 5.50078 9.37076 5.75488 9.31285 5.99721C9.25494 6.23953 9.12594 6.45902 8.9424 6.62751L7.3249 8.10876C7.24517 8.18193 7.18576 8.27451 7.15246 8.37747C7.11916 8.48043 7.11311 8.59026 7.1349 8.69626L7.1499 8.76126L7.18865 8.92376C7.3898 9.71332 7.69631 10.4722 8.0999 11.18C8.53954 11.9321 9.08513 12.6171 9.7199 13.2138L9.7699 13.2588C9.85065 13.3304 9.94859 13.38 10.0542 13.4025C10.1598 13.4251 10.2694 13.4199 10.3724 13.3875L12.4636 12.7288C12.7013 12.6541 12.9559 12.6522 13.1946 12.7233C13.4334 12.7943 13.6454 12.9351 13.8036 13.1275L14.7936 14.3288C15.2061 14.8288 15.1561 15.5638 14.6824 16.005C13.3861 17.2138 11.6036 17.4613 10.3636 16.465C8.84368 15.239 7.56234 13.7436 6.58365 12.0538C5.59701 10.3652 4.93922 8.50555 4.6424 6.57251ZM8.44615 8.77876L9.78615 7.54876C10.1534 7.21193 10.4117 6.77301 10.5277 6.28835C10.6438 5.8037 10.6123 5.29541 10.4374 4.82876L9.8949 3.37876C9.67283 2.78657 9.23331 2.30134 8.66592 2.02194C8.09852 1.74253 7.44594 1.68999 6.84115 1.87501C4.7374 2.51876 3.04865 4.40501 3.40615 6.76126C3.65615 8.40626 4.2324 10.4988 5.50365 12.6838C6.55954 14.5059 7.94172 16.1182 9.58115 17.44C11.4411 18.9338 13.9236 18.4238 15.5361 16.9213C15.9975 16.4917 16.2776 15.9022 16.3192 15.2731C16.3608 14.6441 16.1607 14.0229 15.7599 13.5363L14.7699 12.3338C14.4532 11.9494 14.0289 11.6683 13.5514 11.5267C13.0739 11.385 12.565 11.3892 12.0899 11.5388L10.3536 12.085C9.90536 11.6228 9.51269 11.1097 9.18365 10.5563C8.8658 9.99671 8.6181 9.40014 8.44615 8.78001V8.77876Z" fill="#808792"/>
</svg>



  </span>
  <input type="text" class="form-control jdjddu" onChange={(e)=>{setnumber(e.target.value)}} placeholder="Contact Number ( without country code )" aria-label="Username" aria-describedby="basic-addon1"/>
</div>
  <div class="input-group mb-3 kokdkdjd p-3">
  <span class="input-group-text jdjddu" id="basic-addon1">
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9 14L15 8" stroke="#808792" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.5 9C9.77614 9 10 8.77614 10 8.5C10 8.22386 9.77614 8 9.5 8C9.22386 8 9 8.22386 9 8.5C9 8.77614 9.22386 9 9.5 9Z" fill="#808792" stroke="#808792" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.5 14C14.7761 14 15 13.7761 15 13.5C15 13.2239 14.7761 13 14.5 13C14.2239 13 14 13.2239 14 13.5C14 13.7761 14.2239 14 14.5 14Z" fill="#808792" stroke="#808792" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5 21V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H17C17.5304 3 18.0391 3.21071 18.4142 3.58579C18.7893 3.96086 19 4.46957 19 5V21L16 19L14 21L12 19L10 21L8 19L5 21Z" stroke="#808792" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


  </span>
  <input type="text" class="form-control jdjddu" onChange={(e)=>{setgst_number(e.target.value)}} placeholder="GST Number" aria-label="Username" aria-describedby="basic-addon1"/>
</div>
<div>
  <button className='btn hdhdhdg p-3' onClick={addvender} > Add Vendor</button>
</div>
  </div>
</div>
    </div>
  )
}

export default Vendorshead
