import { toast } from 'react-toastify';
import axiosInstance from './axiosInstance';
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";





export const getRoute = createAsyncThunk(
    "getRoute",
    async ({id}, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.get(`user/get/route/st?userid=${id}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data.error);
        }
    }
);
export const getDasBord = createAsyncThunk(
    "getDasBord",
    async ({}, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.get(`dasbord`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data.error);
        }
    }
);
export const getcustomalerts = createAsyncThunk(
    "getcustomalerts",
    async (_, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.get(`admin/get/custom/alerts`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data.error);
        }
    }
);
export const usergetcustomer = createAsyncThunk(
    "usergetcustomer",
    async ({date}, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.get(`user/get/customer?date=${date}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data.error);
        }
    }
);
// export const addRoute = createAsyncThunk(
//     "addRoute",
//     async ({route,user_id}, { rejectWithValue }) => {
//         try {
//             const response = await axiosInstance.post(`user/addaccess`,{ route, user_id });
//             return response.data;
//         } catch (error) {
//             return rejectWithValue(error.response.data.error);
//         }
//     }
// );

export const createCustomAlert = createAsyncThunk(
    "createCustomAlert",
    async ({name,description},{ rejectWithValue }) => {
      let url = `admin/create/custom/alerts`;
      try {
        const response = await axiosInstance.post(
          url,
          {name,description}
        ); // replace with your API endpoint and data
        console.log(response.data);
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data.error);
      }
    }
  );
export const AddMember = createAsyncThunk(
    "addmember",
    async ({name,email,password,com_password,ph_number},{ rejectWithValue }) => {
      let url = `user/signup`;
      try {
        const response = await axiosInstance.post(
          url,
          {name,email,password,com_password,ph_number}
        ); // replace with your API endpoint and data
        console.log(response.data);
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data.error);
      }
    }
  );


  export const editCustomer = createAsyncThunk(
    "editCustomer", // Action type prefix
    async ({ id, email, name, number, gst_number, kyc, b_address_line_1, b_area, b_city, b_zip_code, b_state, s_address_line_1, s_area, s_city, s_zip_code, s_state }, { rejectWithValue }) => {
      // Construct the URL for the API endpoint, replacing '4' with the actual customer ID
      let url = `user/edit/customer?id=${id}`;
      try {
        // Make a POST request to the specified URL with customer details as payload
        const response = await axiosInstance.put(
          url,
          { email, name, number, gst_number, kyc, b_address_line_1, b_area, b_city, b_zip_code, b_state, s_address_line_1, s_area, s_city, s_zip_code, s_state }
        );
        console.log(response.data); // Log the response data
        return response.data; // Return the response data
      } catch (error) {
        // If there's an error, return the error message
        return rejectWithValue(error.response.data.error);
      }
    }
  );


  export const deleteCustomer = createAsyncThunk(
    "deleteCustomer",
    async ({ id }, { rejectWithValue }) => {
      // Construct the URL for the API endpoint, replacing '4' with the actual customer ID
      let url = `user/delete/customer?id=${id}`;
      try {
        // Make a POST request to the specified URL with customer details as payload
        const response = await axiosInstance.delete(url);
        console.log(response.data); // Log the response data
        return response.data; // Return the response data
      } catch (error) {
        // If there's an error, return the error message
        return rejectWithValue(error.response.data.error);
      }
    }
  );

export const userAccess = createSlice({
    name: "user",
    initialState: {
        routeData: null,
        error: null,
        isLoading: false,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getRoute.pending, (state,action) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getRoute.fulfilled, (state, action) => {
                state.isLoading = false;
                state.routeData = action.payload;
                toast.success(action.payload.message)
            })
            .addCase(getRoute.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(createCustomAlert.pending, (state,action) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(createCustomAlert.fulfilled, (state, action) => {
                state.isLoading = false;
                state.routeData = action.payload;
                toast.success(action.payload.message)
            })
            .addCase(createCustomAlert.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(getcustomalerts.pending, (state,action) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getcustomalerts.fulfilled, (state, action) => {
                state.isLoading = false;
                state.routeData = action.payload;
                toast.success(action.payload.message)
            })
            .addCase(getcustomalerts.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(usergetcustomer.pending, (state,action) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(usergetcustomer.fulfilled, (state, action) => {
                state.isLoading = false;
                state.routeData = action.payload;
                toast.success(action.payload.message)
            })
            .addCase(usergetcustomer.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(AddMember.pending, (state,action) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(AddMember.fulfilled, (state, action) => {
                state.isLoading = false;
                state.routeData = action.payload;
                toast.success(action.payload.message)
            })
            .addCase(AddMember.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(getDasBord.pending, (state,action) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getDasBord.fulfilled, (state, action) => {
                state.isLoading = false;
                state.routeData = action.payload;
                toast.success(action.payload.message)
            })
            .addCase(getDasBord.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(editCustomer.pending, (state,action) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(editCustomer.fulfilled, (state, action) => {
                state.isLoading = false;
                state.routeData = action.payload;
                toast.success(action.payload.message)
            })
            .addCase(editCustomer.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(deleteCustomer.pending, (state,action) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(deleteCustomer.fulfilled, (state, action) => {
                state.isLoading = false;
                state.routeData = action.payload;
                toast.success(action.payload.message)
            })
            .addCase(deleteCustomer.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
    },
});

export default userAccess.reducer;
