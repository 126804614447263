import {
  Box, Button, Card, Table, TableContainer, Tbody, Td, Th, Thead, Tr, Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Stack,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  ChakraProvider,
  Switch,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { FaRegEdit } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { Useradd, Usergetall, addRoute } from '../../categorySlice'
import { getRoute } from '../../AccessSlice'

function TeamManagement() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [data, setdata] = useState([])
  const [data2, setdata2] = useState({})
  const [data3, setdata3] = useState({})
  const [getRoutes, setgetRoutes] = useState([])
  const [usrid, setusrid] = useState([]);

  const [rData, setrData] = useState([
    {
      id:1,
      route:"",
      status:1,

    } 
  ])

const [Open2, setOpen2] = useState(false)
  const dispatch = useDispatch()
  const onOpen2 = () => {
    console.log("Opening modal 2");
    setOpen2(true); // Assuming setOpen2 is the function to update isOpen2 state
  }
  
  const onClose2 = () => {
    console.log("Closing modal 2");
    setOpen2(false); // Assuming setOpen2 is the function to update isOpen2 state
  }
  const add = async () => {
    dispatch(Useradd(data2))
  }
  const hendelChange = (e) => {
    setdata2({ ...data2, [e.target.name]: e.target.value })
  }
  const hendelChange2 = (e) => {
    setdata3({ ...data3, [e.target.name]: e.target.checked })

  }
  const siws_data = ()=>{
    
    dispatch(addRoute({route:data3,user_id:usrid}))
    console.log({route:data3,user_id:usrid});
    setOpen2(false);
  }

  const getUserRoute = async(uid)=>{
    setusrid(uid)
     // userAccess api call
     dispatch(getRoute({id:uid})).then((response) => {
      console.log(response.payload.data);
      setgetRoutes(response.payload.data)
    })
  }

  

  useEffect(() => {
    dispatch(Usergetall({})).then((response) => {
      console.log(response.payload, "om");
      setdata(response?.payload?.data)
    })
   
  }, [])
  return (
    <ChakraProvider>
      <Box>
        {/* model */}
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Add Member</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Stack spacing={3}>
                <Input onChange={hendelChange} name='name' variant='filled' placeholder='Name' />
                <Input onChange={hendelChange} name='email' variant='filled' placeholder='Email' />
                <Input onChange={hendelChange} name='ph_number' variant='filled' placeholder='Ph. No.' />
                <InputGroup>
                  <Input onChange={hendelChange} name='password' variant='filled' placeholder='Password' type='password' />
                  <InputRightElement pointerEvents='none'>
                    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M9 4.875C8.1712 4.875 7.37634 5.20424 6.79029 5.79029C6.20424 6.37634 5.875 7.1712 5.875 8C5.875 8.8288 6.20424 9.62366 6.79029 10.2097C7.37634 10.7958 8.1712 11.125 9 11.125C9.8288 11.125 10.6237 10.7958 11.2097 10.2097C11.7958 9.62366 12.125 8.8288 12.125 8C12.125 7.1712 11.7958 6.37634 11.2097 5.79029C10.6237 5.20424 9.8288 4.875 9 4.875ZM7.125 8C7.125 7.50272 7.32254 7.02581 7.67417 6.67417C8.02581 6.32254 8.50272 6.125 9 6.125C9.49728 6.125 9.97419 6.32254 10.3258 6.67417C10.6775 7.02581 10.875 7.50272 10.875 8C10.875 8.49728 10.6775 8.97419 10.3258 9.32582C9.97419 9.67746 9.49728 9.875 9 9.875C8.50272 9.875 8.02581 9.67746 7.67417 9.32582C7.32254 8.97419 7.125 8.49728 7.125 8Z" fill="#5560A0" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M8.99999 0.708008C5.23832 0.708008 2.70416 2.96134 1.23332 4.87217L1.20749 4.90634C0.874156 5.33884 0.568323 5.73634 0.360823 6.20634C0.138323 6.71051 0.0416565 7.25967 0.0416565 7.99967C0.0416565 8.73967 0.138323 9.28884 0.360823 9.79301C0.569156 10.263 0.87499 10.6613 1.20749 11.093L1.23416 11.1272C2.70416 13.038 5.23832 15.2913 8.99999 15.2913C12.7617 15.2913 15.2958 13.038 16.7667 11.1272L16.7925 11.093C17.1258 10.6613 17.4317 10.263 17.6392 9.79301C17.8617 9.28884 17.9583 8.73967 17.9583 7.99967C17.9583 7.25967 17.8617 6.71051 17.6392 6.20634C17.4308 5.73634 17.125 5.33884 16.7925 4.90634L16.7658 4.87217C15.2958 2.96134 12.7617 0.708008 8.99999 0.708008ZM2.22499 5.63467C3.58166 3.87051 5.79166 1.95801 8.99999 1.95801C12.2083 1.95801 14.4175 3.87051 15.775 5.63467C16.1417 6.10967 16.355 6.39301 16.4958 6.71134C16.6275 7.00967 16.7083 7.37384 16.7083 7.99967C16.7083 8.62551 16.6275 8.98967 16.4958 9.28801C16.355 9.60634 16.1408 9.88967 15.7758 10.3647C14.4167 12.1288 12.2083 14.0413 8.99999 14.0413C5.79166 14.0413 3.58249 12.1288 2.22499 10.3647C1.85832 9.88967 1.64499 9.60634 1.50416 9.28801C1.37249 8.98967 1.29166 8.62551 1.29166 7.99967C1.29166 7.37384 1.37249 7.00967 1.50416 6.71134C1.64499 6.39301 1.85999 6.10967 2.22499 5.63467Z" fill="#5560A0" />
                    </svg>
                  </InputRightElement>
                </InputGroup>
              </Stack>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme='red' mr={3} onClick={onClose}>
                Close
              </Button>
              <Button color="blueviolet" onClick={add} >+ Add</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        {/* model */}
        {/* model edit */}
        <Modal isOpen={Open2} onClose={onClose2}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Restrict Access</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Stack spacing={3}>
                {
                  getRoutes?.map((res)=>{
                    return(

                <Box borderBottom="1px solid black" display="flex" justifyContent="space-between">
                  <Box fontSize={20} fontWeight={900} >
                    {res.route}
                  </Box>
                  <Box>
                  {res.access_status == 1 ? 
                  
                  <Switch id='email-alerts' isChecked onChange={hendelChange2} name={res.route} />
                  : 
                  <Switch id='email-alerts'  onChange={hendelChange2} name={res.route} />
                  }
                  

                  </Box>
                </Box>
                    )
                  })
                }
              </Stack>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme='red' mr={3} onClick={onClose2}>
                Close
              </Button>
              <Button color="blueviolet" onClick={siws_data} >Save</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        {/* model edit */}
        <Card>
          <Card p={5}>
            <Box display="flex" justifyContent="space-between" >
              <Box>Access</Box>
              <Box><Button onClick={onOpen}>+Add Member</Button></Box>
            </Box>
          </Card>
          <TableContainer>
            <Table variant='simple'>
              <Thead>
                <Tr>
                  <Th>Name</Th>
                  <Th>Email</Th>
                  <Th >Action</Th>
                  <Th >Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {
                  data?.map((res) => {
                    return (
                      <Tr>
                        <Td>{res.name}</Td>
                        <Td> {res.email}</Td>
                        <Td >
                          <Button>
                            <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M9 4.875C8.1712 4.875 7.37634 5.20424 6.79029 5.79029C6.20424 6.37634 5.875 7.1712 5.875 8C5.875 8.8288 6.20424 9.62366 6.79029 10.2097C7.37634 10.7958 8.1712 11.125 9 11.125C9.8288 11.125 10.6237 10.7958 11.2097 10.2097C11.7958 9.62366 12.125 8.8288 12.125 8C12.125 7.1712 11.7958 6.37634 11.2097 5.79029C10.6237 5.20424 9.8288 4.875 9 4.875ZM7.125 8C7.125 7.50272 7.32254 7.02581 7.67417 6.67417C8.02581 6.32254 8.50272 6.125 9 6.125C9.49728 6.125 9.97419 6.32254 10.3258 6.67417C10.6775 7.02581 10.875 7.50272 10.875 8C10.875 8.49728 10.6775 8.97419 10.3258 9.32582C9.97419 9.67746 9.49728 9.875 9 9.875C8.50272 9.875 8.02581 9.67746 7.67417 9.32582C7.32254 8.97419 7.125 8.49728 7.125 8Z" fill="#5560A0" />
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M8.99999 0.708008C5.23832 0.708008 2.70416 2.96134 1.23332 4.87217L1.20749 4.90634C0.874156 5.33884 0.568323 5.73634 0.360823 6.20634C0.138323 6.71051 0.0416565 7.25967 0.0416565 7.99967C0.0416565 8.73967 0.138323 9.28884 0.360823 9.79301C0.569156 10.263 0.87499 10.6613 1.20749 11.093L1.23416 11.1272C2.70416 13.038 5.23832 15.2913 8.99999 15.2913C12.7617 15.2913 15.2958 13.038 16.7667 11.1272L16.7925 11.093C17.1258 10.6613 17.4317 10.263 17.6392 9.79301C17.8617 9.28884 17.9583 8.73967 17.9583 7.99967C17.9583 7.25967 17.8617 6.71051 17.6392 6.20634C17.4308 5.73634 17.125 5.33884 16.7925 4.90634L16.7658 4.87217C15.2958 2.96134 12.7617 0.708008 8.99999 0.708008ZM2.22499 5.63467C3.58166 3.87051 5.79166 1.95801 8.99999 1.95801C12.2083 1.95801 14.4175 3.87051 15.775 5.63467C16.1417 6.10967 16.355 6.39301 16.4958 6.71134C16.6275 7.00967 16.7083 7.37384 16.7083 7.99967C16.7083 8.62551 16.6275 8.98967 16.4958 9.28801C16.355 9.60634 16.1408 9.88967 15.7758 10.3647C14.4167 12.1288 12.2083 14.0413 8.99999 14.0413C5.79166 14.0413 3.58249 12.1288 2.22499 10.3647C1.85832 9.88967 1.64499 9.60634 1.50416 9.28801C1.37249 8.98967 1.29166 8.62551 1.29166 7.99967C1.29166 7.37384 1.37249 7.00967 1.50416 6.71134C1.64499 6.39301 1.85999 6.10967 2.22499 5.63467Z" fill="#5560A0" />
                            </svg>
                          </Button>
                        </Td>
                        <Td style={res.id == 1? {display:"none"}:{display:"block"}}   > <Button onClick={()=>{getUserRoute(res.id);onOpen2()}} ><FaRegEdit />
                        </Button> </Td>
                      </Tr>

                    )
                  })
                }

              </Tbody>
            </Table>
          </TableContainer>
        </Card>
      </Box>
    </ChakraProvider>
  )
}

export default TeamManagement
