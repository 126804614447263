import React from 'react'
import Vendorshead from './Vendorshead'
import Vendorsdata from './Vendorsdata'

function Vendors() {
  return (
    <div className='container-fluid m-0 p-0 g-0 '>
      <Vendorshead/>
      <Vendorsdata/>
    </div>
  )
}

export default Vendors
