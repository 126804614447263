import React, { useContext, useEffect, useState } from 'react'
import ph from "../../img/ph_info.png"
import user2 from "../../img/solar_user-linear.png"
import mails from "../../img/ion_mail-outline.png"
import ant from "../../img/ant-design_file-excel-outlined.png"
import fluent_call from "../../img/fluent_call-16-regular.png"
import ion_mail from "../../img/ion_mail-outline.png"
import view from "../../img/view details.png"
import edit from "../../img/edit details.png"
import deletes from "../../img/delete.png"
import record from "../../img/record payment.png"
import "../Vender/Vendors.css"
import { Button, Drawer } from 'antd';
import { CiSearch } from "react-icons/ci";
import { useDispatch } from 'react-redux'
import { deleteCustomer, editCustomer, usergetcustomer } from '../../AccessSlice'
import { Input } from '@chakra-ui/react'
import { NameContext } from '../../NameContext'

function Customersdata() {
  const [data, setdata] = useState([]);
  const [updatedata, setupdatedata] = useState()
const [userId, setuserId] = useState();
const { headDate, updateDate } = useContext(NameContext);


  const [customer, setCustomer] = useState({
    email: "john.doe@example.com",
    name: "John Doe",
    number: "1234567890",
    gst_number: "22AAAAA0000A1Z5",
    kyc: "Verified",
    b_address_line_1: "123 Business St",
    b_area: "Business Area",
    b_city: "Business City",
    b_zip_code: "123456",
    b_state: "Business State",
    s_address_line_1: "456 Shipping St",
    s_area: "Shipping Area",
    s_city: "Shipping City",
    s_zip_code: "654321",
    s_state: "Shipping State"
  });
  


  const deleteCustomers = (id) => {
    dispatch(deleteCustomer({ id })).then((res) => {
      console.log(res, "delete")
      setupdatedata("delete")
    })
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCustomer(prevCustomer => ({
      ...prevCustomer,
      [name]: value
    }));
  };

  const updateDteals = () =>{
    console.log(customer);
    dispatch(editCustomer({id:userId, ...customer})).then(()=>{
      setupdatedata("update")
    })
  }

  let ffa = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const showDrawer = (id) => {
    setuserId(id)
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(usergetcustomer({date:headDate})).then((res) => {
      setdata(res?.payload?.data)
      console.log("response", res?.payload?.data);
    })
  }, [updatedata,headDate])
  return (
    <div className='ms-3 mt-4'>
      <div className="card m-0 p-0 px-3 pt-2">

        {/* droawer */}

        <Drawer title="Edit Customer" onClose={onClose} open={open} width={700}  >
          <div className="input-wrapper p-2 mb-3" style={{ backgroundColor: "#F1F3F5" }} >
            <img src={user2} alt="" style={{ height: "20px", width: "20px" }} />
            <Input onChange={handleChange}  name="name" type="text" style={{ border: "none", outline: "none", backgroundColor: "transparent" }} placeholder="Username" />
          </div>
          <div className="input-wrapper p-2 mb-3" style={{ backgroundColor: "#F1F3F5" }} >
            <img src={mails} alt="" style={{ height: "20px", width: "20px" }} />
            <Input onChange={handleChange} name='email' type="text" style={{ border: "none", outline: "none", backgroundColor: "transparent" }} placeholder="reemasonkar@gmail.com" />
          </div>
          <div className="input-wrapper p-2 mb-3" style={{ backgroundColor: "#F1F3F5" }} >
            {/* <img src={ph} alt="" style={{ height: "20px", width: "20px" }} /> */}
            <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.64264 5.57257C1.40389 4.00007 2.51264 2.58757 4.20639 2.07007C4.50696 1.97818 4.83126 2.00433 5.11321 2.14322C5.39517 2.28211 5.61356 2.52327 5.72389 2.81757L6.26764 4.26757C6.35517 4.50084 6.371 4.75494 6.31309 4.99727C6.25519 5.23959 6.12618 5.45909 5.94264 5.62757L4.32514 7.10882C4.24541 7.18199 4.186 7.27457 4.1527 7.37753C4.1194 7.48049 4.11335 7.59033 4.13514 7.69632L4.15014 7.76132L4.18889 7.92382C4.39005 8.71338 4.69655 9.47227 5.10014 10.1801C5.53978 10.9322 6.08537 11.6171 6.72014 12.2138L6.77014 12.2588C6.85089 12.3305 6.94883 12.38 7.05442 12.4026C7.16001 12.4252 7.26965 12.42 7.37264 12.3876L9.46389 11.7288C9.70156 11.6542 9.95609 11.6523 10.1949 11.7233C10.4336 11.7944 10.6457 11.9352 10.8039 12.1276L11.7939 13.3288C12.2064 13.8288 12.1564 14.5638 11.6826 15.0051C10.3864 16.2138 8.60389 16.4613 7.36389 15.4651C5.84393 14.239 4.56258 12.7437 3.58389 11.0538C2.59725 9.36525 1.93947 7.50561 1.64264 5.57257ZM5.44639 7.77882L6.78639 6.54882C7.15369 6.21199 7.41193 5.77308 7.52797 5.28842C7.64402 4.80376 7.61256 4.29547 7.43764 3.82882L6.89514 2.37882C6.67307 1.78663 6.23356 1.3014 5.66616 1.022C5.09876 0.742595 4.44618 0.690051 3.84139 0.875073C1.73764 1.51882 0.048891 3.40507 0.406391 5.76132C0.656391 7.40632 1.23264 9.49882 2.50389 11.6838C3.55978 13.5059 4.94196 15.1182 6.58139 16.4401C8.44139 17.9338 10.9239 17.4238 12.5364 15.9213C12.9978 15.4917 13.2778 14.9022 13.3194 14.2732C13.361 13.6441 13.161 13.0229 12.7601 12.5363L11.7701 11.3338C11.4534 10.9494 11.0291 10.6684 10.5516 10.5267C10.0741 10.3851 9.56523 10.3893 9.09014 10.5388L7.35389 11.0851C6.9056 10.6229 6.51294 10.1098 6.18389 9.55632C5.86604 8.99677 5.61835 8.4002 5.44639 7.78007V7.77882Z" fill="#808792" />
            </svg>

            <Input type="number" onChange={handleChange} name='number' style={{ border: "none", outline: "none", backgroundColor: "transparent" }} placeholder="99999 99999" />
          </div>
          <div className="input-wrapper p-2 mb-3" style={{ backgroundColor: "#F1F3F5" }} >
            {/* <img src={user2} alt="" style={{ height: "20px", width: "20px" }} /> */}
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9 14L15 8" stroke="#808792" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.5 9C9.77614 9 10 8.77614 10 8.5C10 8.22386 9.77614 8 9.5 8C9.22386 8 9 8.22386 9 8.5C9 8.77614 9.22386 9 9.5 9Z" fill="#808792" stroke="#808792" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.5 14C14.7761 14 15 13.7761 15 13.5C15 13.2239 14.7761 13 14.5 13C14.2239 13 14 13.2239 14 13.5C14 13.7761 14.2239 14 14.5 14Z" fill="#808792" stroke="#808792" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5 21V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H17C17.5304 3 18.0391 3.21071 18.4142 3.58579C18.7893 3.96086 19 4.46957 19 5V21L16 19L14 21L12 19L10 21L8 19L5 21Z" stroke="#808792" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

            <Input onChange={handleChange} name='gst_number' type="text" style={{ border: "none", outline: "none", backgroundColor: "transparent" }} placeholder="AAAA123458556666" />
          </div>

          {/* addres */}
          <div style={{display:"flex",justifyContent:"space-between"}} >
            <div style={{fontFamily: "Archivo", fontSize: "14px", fontWeight: "600", lineHeight: "15.23px", textAlign: "left"}}><p>Billing Address,</p></div>
            <div>
            <div style={{display:"flex",alignItems:"center",gap:"5px"}}>
            <div style={{fontFamily: "Archivo", fontSize: "14px", fontWeight: "600", lineHeight: "15.23px", textAlign: "left"}}>Shipping Address,</div>
              <input type="checkbox" />
              <div style={{fontFamily: "Archivo", fontSize: "14px", fontWeight: "300", lineHeight: "15.23px", textAlign: "left",color:"gray"}}>Same as Billing Address</div>
            </div>
            </div>
          </div>

          <div style={{display:"grid",gridTemplateColumns:"1fr 1fr",gap:"20px"}}>
            <div className="input-wrapper p-2 mb-3" style={{ backgroundColor: "#F1F3F5" }} >
              <Input onChange={handleChange} name='b_address_line_1' type="text" style={{ border: "none", outline: "none", backgroundColor: "transparent" }} placeholder="116, emirates heights, magarpatta" />
            </div>
            <div className="input-wrapper p-2 mb-3" style={{ backgroundColor: "#F1F3F5" }} >
              <Input onChange={handleChange} name='s_address_line_1' type="text" style={{ border: "none", outline: "none", backgroundColor: "transparent" }} placeholder="magarpatta" />
            </div>
          </div>




{/* addres */}
<div style={{display:"grid",gridTemplateColumns:"1fr 1fr",gap:"20px"}}>
<div style={{display:"grid",gridTemplateColumns:"1fr 1fr",gap:"20px"}}>
            <div className="input-wrapper p-2 mb-3" style={{  }} >
              <Input onChange={handleChange} name='b_area' type="text" style={{ borderTop:"none",borderLeft:"none",borderRight:"none",borderBottom:"1px solid #E2E8EE", outline: "none", backgroundColor: "transparent" }} placeholder="b_area" />
            </div>
            <div className="input-wrapper p-2 mb-3" style={{  }} >
              <Input onChange={handleChange} name='b_city' type="text" style={{ borderTop:"none",borderLeft:"none",borderRight:"none",borderBottom:"1px solid #E2E8EE", outline: "none", backgroundColor: "transparent" }} placeholder="b_city" />
            </div>
          </div><div style={{display:"grid",gridTemplateColumns:"1fr 1fr",gap:"20px"}}>
            <div className="input-wrapper p-2 mb-3" style={{  }} >
              <Input onChange={handleChange} name='s_area' type="text" style={{ borderTop:"none",borderLeft:"none",borderRight:"none",borderBottom:"1px solid #E2E8EE", outline: "none", backgroundColor: "transparent" }} placeholder="s_area" />
            </div>
            <div className="input-wrapper p-2 mb-3" style={{  }} >
              <Input onChange={handleChange} name='s_city' type="text" style={{ borderTop:"none",borderLeft:"none",borderRight:"none",borderBottom:"1px solid #E2E8EE", outline: "none", backgroundColor: "transparent" }} placeholder="s_city" />
            </div>
          </div>
          </div>
{/* addres */}
{/* addres */}
<div style={{display:"grid",gridTemplateColumns:"1fr 1fr",gap:"20px"}}>
<div style={{display:"grid",gridTemplateColumns:"1fr 1fr",gap:"20px"}}>
            <div className="input-wrapper p-2 mb-3" style={{  }} >
              <Input onChange={handleChange} name='b_zip_code' type="text" style={{ borderTop:"none",borderLeft:"none",borderRight:"none",borderBottom:"1px solid #E2E8EE", outline: "none", backgroundColor: "transparent" }} placeholder="b_zip_code" />
            </div>
            <div className="input-wrapper p-2 mb-3" style={{  }} >
              <Input onChange={handleChange} name='b_state' type="text" style={{ borderTop:"none",borderLeft:"none",borderRight:"none",borderBottom:"1px solid #E2E8EE", outline: "none", backgroundColor: "transparent" }} placeholder="b_state" />
            </div>
          </div><div style={{display:"grid",gridTemplateColumns:"1fr 1fr",gap:"20px"}}>
            <div className="input-wrapper p-2 mb-3" style={{  }} >
              <Input onChange={handleChange} name='s_zip_code' type="text" style={{ borderTop:"none",borderLeft:"none",borderRight:"none",borderBottom:"1px solid #E2E8EE", outline: "none", backgroundColor: "transparent" }} placeholder="s_zip_code" />
            </div>
            <div className="input-wrapper p-2 mb-3" style={{  }} >
              <Input onChange={handleChange} name='s_state' type="text" style={{ borderTop:"none",borderLeft:"none",borderRight:"none",borderBottom:"1px solid #E2E8EE", outline: "none", backgroundColor: "transparent" }} placeholder="s_state" />
            </div>
          </div>
          </div>
{/* addres */}
          {/* addres */}


          <Button style={{width:"100%", border: "1px solid #5560A0",height:"40px"}} onClick={updateDteals} >Update Details</Button>
        </Drawer>
        {/* droawer */}

        <div className="row" style={{ borderBottom: "1px solid #D9DBE6" }}>
          <div className="col-lg-3 mt-2" style={{ display: "flex" }} >
            <div>

              <p style={{ color: "#364050", fontSize: "24px", fontWeight: "400" }} >List of Customers</p>
            </div>
            <div className='mt-1'>
              <img className='mt-2 ms-2' src={ph} alt="" style={{ height: "20px", width: "20px" }} />
            </div>
          </div>
          <div className="col-lg-5 mt-1">
            <div class="input-group mb-3">
              <span class="input-group-text" id="basic-addon1" style={{ backgroundColor: "transparent" }}><CiSearch />
              </span>
              <input type="text" class="form-control jacj" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" style={{ borderLeft: "none", lineHeight: "30px", outline: "none" }} />
            </div>

          </div>
          <div className="col-lg-2">

          </div>
          <div className="col-lg-2 mt-1">
            <button className='btn' style={{ border: "1px solid #5F6AA6" }}>
              <span>
                <img src={ant} alt="s.techooze" style={{ height: "20px", width: "20px" }} />
              </span>
              Export Data
            </button>
          </div>
        </div>
        <div>
          <div class="row mt-2  pb-3" style={{ borderBottom: "1px solid #D9DBE6" }}>

            <div className='col-lg-1 jol m-0 p-0 px-4 ' style={{ color: "#364050", textAlign: "start" }} >S.No</div>
            <div className='col-lg-3 jol m-0 p-0 px-4 ' style={{ color: "#364050", textAlign: "start" }} >Name</div>
            <div className='col-lg-2 jol m-0 p-0 px-4' style={{ color: "#364050", textAlign: "start" }} >Contact Details</div>
            <div className='col-lg-2 jol m-0 p-0 px-3' style={{ color: "#364050", textAlign: "start" }} >GST Details</div>
            {/* <div className='col-lg-2 jol m-0 p-0 ' style={{ color: "#364050", textAlign: "start" }} >Amount Pending </div> */}
            <div className='col-lg-2 jol m-0 p-0 ' style={{ color: "#364050", textAlign: "start" }} >Actions</div>
          </div>

          <div className="example m-0 p-0  " style={{ height: "66vh", overflowY: "scroll", width: "79vw" }}>
            {
              data?.map((res) => {

                return (
                  <div className='row m-0 p-0 g-0 mb-1 mt-4' style={{ borderBottom: "1px solid #D9DBE6" }}>

                    <div className="col-lg-1 "><p className='x1'>#{res.id}</p></div>
                    <div className="col-lg-3 "><p className='x2'>{res.name} </p></div>
                    <div className="col-lg-2"><p className='x3'><span> <img src={fluent_call} alt="" style={{ height: "20px", width: "20px" }} /> </span>{res.number} <br /> <span><img style={{ height: "20px", width: "20px" }} src={ion_mail} alt="" /></span> {res.email}</p></div>
                    <div className="col-lg-2"><p className='x4'>{res.gst_number}</p></div>
                    {/* <div className="col-lg-2"> <div className='container' >
                      <span className='text vvd  container p-2' style={{ backgroundColor: "#FFCA0C26", color: "#917B08", width: "auto" }}>₹ 45,56,256</span>
                    </div> </div> */}
                    <div className="col-lg-2">
                      <div className='row'>
                        <div className="col-lg-3"><img src={view} alt="" style={{ height: "40px", width: "40px" }} /></div>
                        <div className="col-lg-3"><img onClick={()=>{showDrawer(res.id)}} src={edit} alt="" style={{ height: "40px", width: "40px", cursor: "pointer" }} /></div>
                        <div className="col-lg-3"><img onClick={() => { deleteCustomers(res.id)}} src={deletes} alt="" style={{ height: "40px", width: "40px" }} /></div>
                        <div className="col-lg-3"><img src={record} alt="" style={{ height: "40px", width: "40px" }} /></div>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>

        </div>
      </div>
    </div>
  )
}

export default Customersdata
