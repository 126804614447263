import React, { createContext, useState } from 'react';

const NameContext = createContext();

const NameProvider = ({ children }) => {
  const [name, setName] = useState('Dashboard');
const [venderdata, setvenderdata]  = useState([]);
const [headDate, setheadDate] = useState("");
  const updateName = (newName) => {
    setName(newName);
  };
  const updateVenderdata = (newName) => 
  {
    console.log("dxata ve", newName);
    setvenderdata(newName);
  };

  
  const updateDate = (newDate) => {
    // alert(newDate)
    // console.log("dxata ve hhhhh", newDate);
    setheadDate(newDate);
  };

  return (
    <NameContext.Provider value={{ name, updateName,updateVenderdata,venderdata,updateDate,headDate }}>
      {children}
    </NameContext.Provider>
  );
};

export { NameContext, NameProvider };
