import React, { useEffect, useState } from 'react'
import "./Receivables.css"
import { useDispatch } from 'react-redux'
import { Defaulters } from '../../categorySlice'
function Defaulter() {
    const [data, setdata] = useState([])
    const dispatch = useDispatch()
    useEffect(() => {
      dispatch(Defaulters({})).then((response) => {
        // console.log(response.payload,"om");
        setdata(response.payload.data)
      })
    }, [])
    
  return (
    <div>
        <div className="main_div_gi">
            <div className="col_div">
                <p className="hed_text_r">Invoice no.</p>
            </div>
            <div className="col_div">
                <p className="hed_text_r">Name</p>
            </div>
            <div className="col_div">
                <p className="hed_text_r">Contact Details</p>
            </div>
            <div className="col_div">
                <p className="hed_text_r">Amount</p>
            </div>
            <div className="col_div">
                <p className="hed_text_r">Status</p>
            </div>
            <div className="col_div">
                <p className="hed_text_r">Due Date</p>
            </div>
            <div className="col_div">
                <p className="hed_text_r">Total Amt.</p>
            </div>
            <div className="col_div"></div>
        </div>
            <hr style={{color:"#E2E8EE",backgroundColor:"#E2E8EE",height:"1px"}} />
            {
                data?.map((res)=>{
                    return(
                        <>
                        <div className="main_div_gi">
            <div className="col_div">
                <p className="hed_text_r">{res.invoices_id}</p>
            </div>
            <div className="col_div">
                <p className="hed_text_r">{res.name}</p>
            </div>
            <div className="col_div">
                <p className="hed_text_r">{res.email}</p>
                <p className="hed_text_r">{res.number}</p>
            </div>
            <div className="col_div">
                <p className="hed_text_r">{res.amount}</p>
            </div>
            <div className="col_div">
                <p className="hed_text_r">{res.status}</p>
            </div>
            <div className="col_div">
                <p className="hed_text_r">{res.due_date}</p>
            </div>
            <div className="col_div">
                <p className="hed_text_r">{res.total_amount}</p>
            </div>
            <div className="col_div"></div>
        </div>
                        </>
                    )
                })
            }
    </div>
  )
}

export default Defaulter