import React from 'react'
import CustomersHead from './CustomersHead'
import Customersdata from './Customersdata'

function Customers() {
  return (
    <div>
    <CustomersHead/>
    <Customersdata/>

    </div>
  )
}

export default Customers
