import React, { useContext, useEffect, useState } from 'react'
import "../Home/Home.css"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import calnder from "../../img/calnder.png"
import refress from "../../img/refress.png"
import CreateInvoice from "../../img/CreateInvoice.png"
import humbleicons_user from "../../img/humbleicons_user-remove.png"
import basil_notification from "../../img/basil_notification-on-outline.png"
import teenyicons_box from "../../img/teenyicons_box-outline.png"
import mingcute_user from "../../img/mingcute_user-add-line.png"
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useDispatch } from 'react-redux';
import { getUserbyid, salary } from '../../userSlice';
import zIndex from '@mui/material/styles/zIndex';
import { toast } from 'react-toastify';
import { NameContext } from '../../NameContext';
// import "./Customers.css"
function AccountsHead(props) {
  const [startDate, setStartDate] = useState(new Date());
  const { children, value, index, ...other } = props;
  const [Datauser, setDatauser] = useState([]);
  const dispatch = useDispatch();
  const [data, setdata] = useState();

  const [employee, setEmployee] = useState('');
  const [amount, setAmount] = useState('');
  const [payment_type, setPaymentType] = useState('');
  const [date, setDate] = useState(new Date());
  const [remarks, setRemarks] = useState('');
  const [img, setImg] = useState('');

  const { headDate, updateDate } = useContext(NameContext);



const getUser = async()=>{
  try {
  
    dispatch(getUserbyid({})).then((res)=>{
      setDatauser(res?.payload?.user)
    })
  } catch (error) {
    console.log('====================================');
    console.log(error);
    console.log('====================================');
  }
}

const addsalyry = async () => {
  // dispatch(salary({ employee, amount, payment_type, date:startDate, remarks, img }))

  
  try {
    const myHeaders = new Headers();
myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);

const formdata = new FormData();
formdata.append("employee", employee);
formdata.append("amount", amount);
formdata.append("payment_type", payment_type);
formdata.append("date", startDate);
formdata.append("remarks", remarks);
formdata.append("img", img);

const requestOptions = {
  method: "POST",
  headers: myHeaders,
  body: formdata,
  redirect: "follow"
};

fetch("https://erpapp.mintersreclaimers.in/minters/admin/paysalary/create", requestOptions)
  .then((response) => response.json())
  .then((result) => toast.success(result.message))
  .catch((error) => console.error(error));
  } catch (error) {
    console.log('====================================');
    console.log(error);
    console.log('====================================');
  }
}




  return (
    <div className='row mx-1 mt-3 main-div-das m-0 p-0 g-0 '  >



      {/* add salary */}


      <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRightslary" aria-labelledby="offcanvasRightLabel" style={{ width: "40vw" }}>
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="offcanvasRightLabel">Pay Salary</h5>
          <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body"  >
          <div style={{ display: "flex", gap: "20px" }} >
            <div>
              <p style={{ fontFamily: "Archivo", fontSize: "16px", fontWeight: "500", lineHeight: "17.41px", textAlign: "left" }}>Record Date</p>
            </div>
            <div>
              <div style={{ display: "flex", alignItems: "center", backgroundColor: "#F4F8FC",zIndex:"99" }}>
                <img src={calnder} alt='s.techooze' style={{ height: "20px", width: "20px", marginRight: "10px" }} />
                <DatePicker style={{zIndex:99}} className="custom-datepicker" selected={headDate} onChange={(date) => updateDate(date)} />
              </div>
            </div>
          </div>

          <div>
            <div class="input-group mb-3" onMouseEnter={getUser} >
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 10C14.2091 10 16 8.20914 16 6C16 3.79086 14.2091 2 12 2C9.79086 2 8 3.79086 8 6C8 8.20914 9.79086 10 12 10Z" stroke="#808792" stroke-width="1.5" />
                    <path d="M20 17.5C20 19.985 20 22 12 22C4 22 4 19.985 4 17.5C4 15.015 7.582 13 12 13C16.418 13 20 15.015 20 17.5Z" stroke="#808792" stroke-width="1.5" />
                  </svg>

                </span>
              </div>
              <select  class="form-select" onChange={(e) => setEmployee(e.target.value)} id="inputGroupSelect01">
                <option selected>Select Employee</option>

                {
                  Datauser?.map((item, index) => {
                    return (
                      <option value={item.id}>{item?.name}</option>
                    )
                  })
                }
              </select>
            </div>

          </div>


          <div>
            <div  class="input-group mb-3" style={{zIndex:"0"}}>
              <div class="input-group-prepend" >
                <span class="input-group-text">₹</span>
              </div>
              <input type="number" class="form-control" onChange={(e)=>{setAmount(e.target.value)}} placeholder='Salary Amount' aria-label="Amount (to the nearest rupee)" />
            </div>

            <div>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4 3C3.73478 3 3.48043 3.10536 3.29289 3.29289C3.10536 3.48043 3 3.73478 3 4V8H5V5H8V3H4ZM20 3H16V5H19V8H21V4C21 3.73478 20.8946 3.48043 20.7071 3.29289C20.5196 3.10536 20.2652 3 20 3ZM5 16V19H8V21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V16H5ZM19 16V19H16V21H20C20.2652 21 20.5196 20.8946 20.7071 20.7071C20.8946 20.5196 21 20.2652 21 20V16H19ZM10 9C9.73478 9 9.48043 9.10536 9.29289 9.29289C9.10536 9.48043 9 9.73478 9 10V14C9 14.2652 9.10536 14.5196 9.29289 14.7071C9.48043 14.8946 9.73478 15 10 15H14C14.2652 15 14.5196 14.8946 14.7071 14.7071C14.8946 14.5196 15 14.2652 15 14V10C15 9.73478 14.8946 9.48043 14.7071 9.29289C14.5196 9.10536 14.2652 9 14 9H10Z" fill="#808792" />
                  </svg>
                  </span>
                </div>
                <select onChange={(e) => setPaymentType(e.target.value)} class="form-select" id="inputGroupSelect02">
                  <option value="Cash">Cash</option>
                  <option value="Cheque">Cheque</option>
                  <option value="UPI">UPI</option>
                  <option value="Online Banking">Online Banking</option>
                  <option value="Debit/Credit Card">Debit/Credit Card</option>
                </select>
              </div>



              <div>
                <div class="input-group mb-3" style={{zIndex:"0"}}>

                  <input style={{zIndex:"-1"}} type="text" class="form-control"  id="inputGroupSelect03" aria-label="Add UTR / cheque number ( not for cash )" placeholder="Note/ remarks" onChange={(e) => setRemarks(e.target.value)} />
                </div>
              </div>

            <div className='mt-5' style={{display:"flex",justifyContent:"center",width:"100%"}}>
            <div style={{display:"flex",justifyContent:"center",width:"100%"}} >
                <div class="input-group mb-3" style={{backgroundColor:"#F4F8FC",border:"none",outline:"none",borderRadius:"5px",width:"100%"}}>
                  <label class="input-group-text" for="inputGroupFile02" style={{backgroundColor:"#F4F8FC",border:"none",outline:"none"}}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.5 15.577V6.927L9.17 9.257L8.462 8.538L12 5L15.538 8.538L14.831 9.258L12.5 6.927V15.577H11.5ZM5 19V14.962H6V18H18V14.962H19V19H5Z" fill="#838993" />
                    </svg>
                   <p  > Upload Screenshot/file</p>
                  </label>
                  <input  style={{ visibility: "hidden",zIndex:"999" }} type="file" class="form-control" id="inputGroupFile02" onChange={(e) => setImg(e.target.files[0])} />
                </div>
              </div>
            </div>

<div>
<div style={{display:"flex",justifyContent:"center",width:"100%"}}>
  <button onClick={addsalyry} class="btn btn-primary p-4" style={{fontFamily:"Archivo",fontSize:"16px",fontWeight:"500",lineHeight:"17.41px",textAlign:"center",color:"#01C466",backgroundColor:"#E5FFE8",border:"1px solid #01C466",width:"100%"}}>
    Confirm Salary & Send Acknowledgement
  </button>
</div>
</div>


            </div>

          </div>

        </div>
      </div>
      {/* add salary */}

      <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel" style={{ width: "40vw" }}>
        <div class="offcanvas-header">
          <h5 id="offcanvasRightLabel">Make Payout </h5>
          <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
            <label class="form-check-label" for="flexRadioDefault1">
              Vendor
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
            <label class="form-check-label" for="flexRadioDefault1" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRightslary" aria-controls="offcanvasRight">
              Employee Salary
            </label>
          </div>
        </div>
      </div>
      <div className='col-lg-4 '  >
        <div className='card py-2'  >
          <div className='sho-date' >
            <div>
              <p className='ms-2 mt-2' style={{ color: "#364050", fontWeight: "bold" }}>Showing Data of </p>
            </div>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#F4F8FC" }} >
              <img src={calnder} alt='s.techooze' style={{ height: "20px", width: "20px" }} />
              <DatePicker className="custom-datepicker" selected={headDate} onChange={(date) => updateDate(date)} />

            </div>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
              <div>
                <p className='mt-3' >Refresh  </p>
              </div>
              <div>

                <img className='ms-2' src={refress} alt='s.techooze' style={{ height: "20px", width: "20px" }} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='col-lg-8 m-0 p-0 g-0' >
        <div className='card' style={{ width: "100%" }} >
          <div className='quick-main-div3' >
            <div className='quick-chaild-div' style={{ border: "none" }} >
              <p>Quick Actions</p>
            </div>
            <div className='quick-chaild-div py-4' style={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
              <img src={mingcute_user} alt='s.techooze' style={{ height: "20px", width: "20px" }} />
              <p className='mt-3 ms-1' >Create Invoice</p>
            </div>
            <div className='quick-chaild-div py-4' style={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
              <img src={humbleicons_user} alt='s.techooze' style={{ height: "20px", width: "20px" }} />
              <p className='mt-3 ms-1' data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" >Make Payment</p>
            </div>
            <div className='quick-chaild-div py-4' style={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
              <img src={CreateInvoice} alt='s.techooze' style={{ height: "20px", width: "20px" }} />
              <p className='mt-3 ms-1' >Record Payment</p>
            </div>
            <div className='quick-chaild-div py-4' style={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
              <img src={CreateInvoice} alt='s.techooze' style={{ height: "20px", width: "20px" }} />
              <p className='mt-3 ms-1' >Send Alerts</p>
            </div>


          </div>
        </div>
      </div>
    </div>
  )
}

export default AccountsHead
