import React, { useContext, useEffect, useState } from 'react'
import "./Receivables.css"
import { useDispatch } from 'react-redux'
import { Receivablesget } from '../../categorySlice'
import { Button, Drawer } from 'antd';
import { NameContext } from '../../NameContext';
function Receivables() {
    const [data, setdata] = useState([])

    const [open, setOpen] = useState(false);
const [id, setid] = useState();

    const [image, setImage] = useState(null)
    const [note, setnote] = useState();
    const [methode, setmethode] = useState();

    const { headDate, updateDate } = useContext(NameContext);



    const handleImageChange = (e) => {
        setImage(e.target.files[0])
    }

    const showDrawer = () => {
      setOpen(true);
    };
  
    const onClose = () => {
      setOpen(false);
    };

//     const payupdate = (id) =>{
//         try {
//             const myHeaders = new Headers();
// myHeaders.append("Authorization", "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxLCJpYXQiOjE3MTg1MTI4MjJ9.bmU2kIcCf_hoLg7jvRFwU_u8hOFImPkrnLEnvubEhaM");

// const requestOptions = {
//   method: "GET",
//   headers: myHeaders,
//   redirect: "follow"
// };

// fetch(`https://erpapp.mintersreclaimers.in/minters/receivables/update/id?id=${id}`, requestOptions)
//   .then((response) => response.json())
//   .then((result) => console.log(result))
//   .catch((error) => console.error(error));
//         } catch (error) {
//             console.log(error);
//         }
//     }
    const payupdate = (id) =>{
        const myHeaders = new Headers();
myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);

const formdata = new FormData();
formdata.append("notes", note);
formdata.append("img", image);
formdata.append("method", methode);

const requestOptions = {
  method: "POST",
  headers: myHeaders,
  body: formdata,
  redirect: "follow"
};

fetch(`https://erpapp.mintersreclaimers.in/minters/receivables/update/id?id=${id}`, requestOptions)
  .then((response) => response.json())
  .then((result) => console.log(result))
  .catch((error) => console.error(error));
    }

    const dataGet = async(id)=>{
        try {
            const myHeaders = new Headers();
myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);

const requestOptions = {
  method: "GET",
  headers: myHeaders,
  redirect: "follow"
};

fetch(`https://erpapp.mintersreclaimers.in/minters/receivables/get/id?id=${id}`, requestOptions)
  .then((response) => response.json())
  .then((result) => {console.log(result.data)

    setdata(result.data)
  })
  .catch((error) => console.error(error));
        } catch (error) {
            console.log(error);
        }
    }

    const dispatch = useDispatch()
    useEffect(() => {
     
      dispatch(Receivablesget({date:headDate})).then((response) => {
        // console.log(response.payload,"om");
        setdata(response.payload.data)
      })
    }, [headDate])
    
  return (
    <div>

<Drawer width={"1000px"} title="Basic Drawer" onClose={onClose} open={open}>

    {data?.map((res) => (
        <div className="receivables_dive" key={res.id}>
            <p className="name_text" style={{fontWeight: 'bold', fontSize: '16px', color: '#333'}}>Name {res.name}</p>
            <p className="email_text" style={{fontWeight: 'bold', fontSize: '16px', color: '#333'}}>Email {res.email}</p>
            <p className="phone_number_text" style={{fontWeight: 'bold', fontSize: '16px', color: '#333'}}>Phone Number {res.phone_number}</p>
            <p className="gst_number_text" style={{fontWeight: 'bold', fontSize: '16px', color: '#333'}}>GST Number {res.gst_number}</p>
            <p className="in_number_text" style={{fontWeight: 'bold', fontSize: '16px', color: '#333'}}>Invoice no. {res.in_number}</p>
            <p className="in_number_text" style={{fontWeight: 'bold', fontSize: '16px', color: '#333'}}> Amount {res.amount}</p>

            {
                res?.status == 0? 
                <p className="in_number_text" style={{fontWeight: 'bold', fontSize: '16px', color: '#333'}}> Status : Unpaid </p>
                :
                <p className="in_number_text" style={{fontWeight: 'bold', fontSize: '16px', color: '#333'}}> Status : Paid </p>
                
            }
            <p className="in_number_text" style={{fontWeight: 'bold', fontSize: '16px', color: '#333'}}> Due Date : {res.due_date}</p>







            <select onCanPlay={(e)=>{setmethode(e.target.value)}} className="form-select" aria-label="Default select example" style={{width:"100%"}}>
                <option selected>Select Payment Method</option>
                <option value="UPI">UPI</option>
                <option value="Cash">Cash</option>
                <option value="Card">Card</option>
                <option value="Cheque">Cheque</option>
                <option value="Bank Transfer">Bank Transfer</option>
                <option value="Online Payment">Online Payment</option>
            </select>

            <textarea className="form-control mt-3 " id="exampleFormControlTextarea1" rows="3" onChange={(e)=>{setnote(e.target.value)}} placeholder="Add Note"></textarea>

            <input className='mt-4' type='file' onChange={handleImageChange} />
           
            {/* <input type="file" onChange={handleImageChange} /> */}

<button className='btn py-3 '  onClick={()=>{payupdate(res.id)} } style={{background: "#E5FFE8",width:"100%",fontFamily: 'Archivo', fontSize: '16px', fontWeight: '500', lineHeight: '17.41px', textAlign: 'center'}}  >Record Payment</button>
        </div>
    ))}
     
</Drawer>


        <div className="main_div_gi">
            <div className="col_div">
                <p className="hed_text_r">Invoice no.</p>
            </div>
            <div className="col_div">
                <p className="hed_text_r">Name</p>
            </div>
            <div className="col_div">
                <p className="hed_text_r">Contact Details</p>
            </div>
            <div className="col_div">
                <p className="hed_text_r">Amount</p>
            </div>
            <div className="col_div">
                <p className="hed_text_r">Status</p>
            </div>
            <div className="col_div">
                <p className="hed_text_r">Due Date</p>
            </div>
            <div className="col_div">
                <p className="hed_text_r">Total Amt.</p>
            </div>
            <div className="col_div"></div>
        </div>
            <hr style={{color:"#E2E8EE",backgroundColor:"#E2E8EE",height:"1px"}} />
            {
                data?.map((res)=>{
                    return(
                        <>
                        <div className="main_div_gi">
            <div className="col_div">
                <p className="hed_text_r">{res.invoices_id}</p>
            </div>
            <div className="col_div">
                <p className="hed_text_r">{res.name}</p>
            </div>
            <div className="col_div">
                <p className="hed_text_r">{res.email}</p>
                <p className="hed_text_r">{res.number}</p>
            </div>
            <div className="col_div">
                <p className="hed_text_r">{res.amount}</p>
            </div>
            <div className="col_div">
                <p className="hed_text_r">{res.status}</p>
            </div>
            <div className="col_div">
                <p className="hed_text_r">{res.due_date}</p>
            </div>
            <div className="col_div">
                <p className="hed_text_r">{res.total_amount}</p>
            </div>
            <div className="col_div">
                <button onClick={()=>{showDrawer(); dataGet(res.id)}} style={{backgroundColor:"#F0F6FC",border:"none"}} >
                <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.72925 10.7466C2.02091 9.82658 1.66675 9.36575 1.66675 7.99992C1.66675 6.63325 2.02091 6.17409 2.72925 5.25325C4.14341 3.41659 6.51508 1.33325 10.0001 1.33325C13.4851 1.33325 15.8567 3.41659 17.2709 5.25325C17.9792 6.17492 18.3334 6.63409 18.3334 7.99992C18.3334 9.36659 17.9792 9.82575 17.2709 10.7466C15.8567 12.5833 13.4851 14.6666 10.0001 14.6666C6.51508 14.6666 4.14341 12.5833 2.72925 10.7466Z" stroke="#5560A0" stroke-width="1.5"/>
<path d="M12.5 8C12.5 8.66304 12.2366 9.29893 11.7678 9.76777C11.2989 10.2366 10.663 10.5 10 10.5C9.33696 10.5 8.70107 10.2366 8.23223 9.76777C7.76339 9.29893 7.5 8.66304 7.5 8C7.5 7.33696 7.76339 6.70107 8.23223 6.23223C8.70107 5.76339 9.33696 5.5 10 5.5C10.663 5.5 11.2989 5.76339 11.7678 6.23223C12.2366 6.70107 12.5 7.33696 12.5 8Z" stroke="#5560A0" stroke-width="1.5"/>
</svg>

                </button>
            </div>
        </div>
                        </>
                    )
                })
            }
    </div>
  )
}

export default Receivables