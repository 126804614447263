import React, { useContext, useEffect, useState } from 'react'
import "./Receivables.css"
import { useDispatch } from 'react-redux'
import { Payables } from '../../categorySlice'
import { Textarea } from '@chakra-ui/react'
import { toast } from 'react-toastify'
import { NameContext } from '../../NameContext'
function Payable() {
    const [data, setdata] = useState([])
    const dispatch = useDispatch();
const [img, setimg] = useState();
const [method, setmethod] = useState();
const [note, setnote] = useState();
const [id, setid] = useState();
const [dataget, setdataget] = useState();

const { headDate, updateDate } = useContext(NameContext);


const uapdate = async()=>{
    
    try {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);


const formdata = new FormData();
formdata.append("img",img);
formdata.append("method", method);
formdata.append("note", note);

const requestOptions = {
  method: "POST",
  headers: myHeaders,
  body: formdata,
  redirect: "follow"
};

fetch(`https://erpapp.mintersreclaimers.in/minters/vendor/pay/update/id?id=${id}`, requestOptions)
  .then((response) => response.json())
  .then((result) => {console.log(result)
    toast.success("✔"+result.message)
  })
  .catch((error) => console.error(error));
    } catch (error) {
        console.log(error);
    }
}


const demoGet = async()=>{
    try {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);

const requestOptions = {
  method: "GET",
  headers: myHeaders,
  redirect: "follow"
};

fetch("https://erpapp.mintersreclaimers.in/minters/user/get/payables", requestOptions)
  .then((response) => response.json())
  .then((result) =>{ console.log(result,"stec")
    setdata(result.data)
  })
  .catch((error) => console.error(error));
    } catch (error) {
        console.log(error);
    }
}



const idbydataget = (id)=>{
    try {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);


const requestOptions = {
  method: "GET",
  headers: myHeaders,
  redirect: "follow"
};

fetch(`https://erpapp.mintersreclaimers.in/minters/user/get/payables/id?id=${id}`, requestOptions)
  .then((response) => response.json())
  .then((result) => {console.log(result)
    setdata(result.data)
  })
  .catch((error) => console.error(error));
    } catch (error) {
        console.log(error);
    }
}


    useEffect(() => {
        demoGet()
    //   dispatch(Payables({})).then((response) => {
    //     // console.log(response.payload,"om");
    //     setdata(response.payload.data)
    //   })
    }, [])
    
  return (
    <div>

{/* offcamra recodrd */}

<div style={{width:"50vw"}} class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight6" aria-labelledby="offcanvasRightLabel">
  <div class="offcanvas-header">
    {/* <h5 class="offcanvas-title" id="offcanvasRightLabel">Offcanvas right</h5> */}
    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <div className="mb-3">
        <label className="form-label">Select Payment Method</label>
        <select className="form-select" aria-label="Default select example" value={method} onChange={(e) => setmethod(e.target.value)}>
            <option value="">Select Payment Method</option>
            <option value="UPI">UPI</option>
            <option value="Cash">Cash</option>
            <option value="Card">Card</option>
            <option value="Cheque">Cheque</option>
            <option value="Bank Transfer">Bank Transfer</option>
            <option value="Online Payment">Online Payment</option>
        </select>
    </div>


    <div className='mb-3'>
        <label className="form-label">Upload File</label>
        <input type='file' className="form-control" onChange={(e) => setimg(e.target.files[0])}/>
    </div>

    <div>
        <label className="form-label">Note</label>
        <textarea className="form-control" rows="3" value={note} onChange={(e) => setnote(e.target.value)}></textarea>
    </div>

<div>
    <button type="button" class="btn btn-primary mt-3" style={{width:"100%"}} onClick={()=>{uapdate(id)}} >Record</button>
</div>

  </div>
</div>
{/* offcamra recodrd */}



<div style={{width:"50vw"}} class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight4" aria-labelledby="offcanvasRightLabel">
  <div class="offcanvas-header">
    {/* <h5 class="offcanvas-title" id="offcanvasRightLabel">Offcanvas right</h5> */}
    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
{data.map((item)=>{
    return(
        <div className="card" style={{width:"100%"}}>
            <div className="card-body">
                <h5 className="card-title">Id : {item.id}</h5>
                <h6 className="card-subtitle mb-2 text-muted">Name : {item.name}</h6>
                <p className="card-text">Email : {item.email}</p>
                <p className="card-text">Phone number : {item.ph_number}</p>
                <p className="card-text">Amount : {item.amount}</p>
                <p className="card-text">Status : {item.status}</p>
                <p className="card-text">Stoke id : {item.stoke_id}</p>
                <p className="card-text">Pay date : {item.paydate}</p>
                <p className="card-text">Image : <img src={item.img} width="100" height="100" /></p>
                <p className="card-text">Method : {item.method}</p>
                <p className="card-text">Note : {item.note}</p>
            </div>
        </div>
    )
})}

  </div>
</div>



        <div className="main_div_gi">
            <div className="col_div">
                <p className="hed_text_r">Invoice no.</p>
            </div>
            <div className="col_div">
                <p className="hed_text_r">Name</p>
            </div>
            <div className="col_div">
                <p className="hed_text_r">Contact Details</p>
            </div>
            <div className="col_div">
                <p className="hed_text_r">Amount</p>
            </div>
            <div className="col_div">
                <p className="hed_text_r">Status</p>
            </div>
            <div className="col_div">
                <p className="hed_text_r">Due Date</p>
            </div>
            <div className="col_div">
                <p className="hed_text_r">Record Payment</p>
            </div>
            <div className="col_div">
                <p className="hed_text_r">Total Amt.</p>
            </div>
        </div>
            <hr style={{color:"#E2E8EE",backgroundColor:"#E2E8EE",height:"1px"}} />
            {
                data?.map((res)=>{
                    return(
                        <>
                        <div className="main_div_gi">
            <div className="col_div">
                <p className="hed_text_r">{res.id}</p>
            </div>
            <div className="col_div">
                <p className="hed_text_r">{res.name}</p>
            </div>
            <div className="col_div">
                <p className="hed_text_r">{res.email}</p>
                <p className="hed_text_r">{res.number}</p>
            </div>
            <div className="col_div">
                <p className="hed_text_r">{res.amount}</p>
            </div>
            <div className="col_div">
                <p className="hed_text_r"> {res.status == 0 ? <span>Unpaid</span> :<span>Pay</span> } </p>
            </div>
            <div className="col_div">
                <p className="hed_text_r">{new Date(res.paydate).toLocaleDateString()}</p>
            </div>
            <div className="col_div my-1">
            {res.status  == 0 ? <button onClick={()=>{setid(res.id)}} className='btn btn-outline-primary' data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight6" aria-controls="offcanvasRight" style={{borderRadius:'5px',padding:'5px 10px', color:'#5560A0'}} >Record</button>
            :
            <button className='btn btn-outline-primary' style={{borderRadius:'5px',padding:'5px 10px', color:'#FFFFFF',backgroundColor:"#00803E"}} >Record</button>
            }
            </div>
            <div className="col_div">
           
           <span style={{cursor:"pointer"}} onClick={(e)=>{idbydataget(res.id)}}   data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight4" aria-controls="offcanvasRight" >
           <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.72949 12.7468C2.02116 11.8268 1.66699 11.366 1.66699 10.0002C1.66699 8.6335 2.02116 8.17433 2.72949 7.2535C4.14366 5.41683 6.51533 3.3335 10.0003 3.3335C13.4853 3.3335 15.857 5.41683 17.2712 7.2535C17.9795 8.17516 18.3337 8.63433 18.3337 10.0002C18.3337 11.3668 17.9795 11.826 17.2712 12.7468C15.857 14.5835 13.4853 16.6668 10.0003 16.6668C6.51533 16.6668 4.14366 14.5835 2.72949 12.7468Z" stroke="#5560A0" stroke-width="1.5"/>
<path d="M12.5 10C12.5 10.663 12.2366 11.2989 11.7678 11.7678C11.2989 12.2366 10.663 12.5 10 12.5C9.33696 12.5 8.70107 12.2366 8.23223 11.7678C7.76339 11.2989 7.5 10.663 7.5 10C7.5 9.33696 7.76339 8.70107 8.23223 8.23223C8.70107 7.76339 9.33696 7.5 10 7.5C10.663 7.5 11.2989 7.76339 11.7678 8.23223C12.2366 8.70107 12.5 9.33696 12.5 10Z" stroke="#5560A0" stroke-width="1.5"/>
</svg>

           </span>
            </div>
        </div>
                        </>
                    )
                })
            }
    </div>
  )
}

export default Payable