import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./userSlice";
import categorySlice from "./categorySlice";
import { userAccess } from "./AccessSlice";

const store = configureStore({
  reducer: {
    custom2: userSlice,
    custom3: categorySlice,
    custom4:userAccess
  },
});

export default store;
