import React from 'react'
import AccountsHead from './AccountsHead'
import AccountsData from './AccountsData'

function Accounts() {
  return (
    <div className='man_div_ac' >
      <AccountsHead/>
      <AccountsData/>
    </div>
  )
}

export default Accounts
