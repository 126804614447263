import React, { useEffect, useState } from 'react'
import "./Receivables.css"
import { useDispatch } from 'react-redux'
import { Invoices } from '../../categorySlice'
function Invoice() {
    const [data, setdata] = useState([])
    const dispatch = useDispatch()

const allDatafetch = async()=>{
    try {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);

const requestOptions = {
  method: "GET",
  headers: myHeaders,
  redirect: "follow"
};

fetch("https://erpapp.mintersreclaimers.in/minters/invoices/get/all", requestOptions)
  .then((response) => response.json())
  .then((result) => {console.log(result)
    setdata(result.data)

  })
  .catch((error) => console.error(error));
    } catch (error) {
        console.log(error);
    }
}


const invoiseDataGet = async()=>{
    try {
        const myHeaders = new Headers();
myHeaders.append("Authorization", "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxLCJpYXQiOjE3MTkwMzc2MDd9.LsZ4LOByxJ5diZx-hndvgf6WFh7ZUiNM-iIrOfvu6dg");

const requestOptions = {
  method: "GET",
  headers: myHeaders,
  redirect: "follow"
};

fetch("https://erpapp.mintersreclaimers.in/minters/invice/data/id/by/get?id=8", requestOptions)
  .then((response) => response.text())
  .then((result) => console.log(result))
  .catch((error) => console.error(error));
    } catch (error) {
        console.log(error);
    }
}


    useEffect(() => {
        allDatafetch()
    //   dispatch(Invoices({})).then((response) => {
        // console.log(response.payload,"om");
        // setdata(response?.payload?.data)
    //   })
    }, [])
    
  return (
    <div>
        <div className="main_div_gi">
            <div className="col_div">
                <p className="hed_text_r">Invoice no.</p>
            </div>
            <div className="col_div">
                <p className="hed_text_r">Name</p>
            </div>
            <div className="col_div">
                <p className="hed_text_r">Contact Details</p>
            </div>
            <div className="col_div">
                <p className="hed_text_r">Total Amt</p>
            </div>
            {/* <div className="col_div"> */}
                {/* <p className="hed_text_r">Pending Amt</p> */}
            {/* </div> */}
            <div className="col_div">
                <p className="hed_text_r">EMIs</p>
            </div>
            <div className="col_div">
                <p className="hed_text_r">Next Payment.</p>
            </div>
            {/* <div className="col_div"></div> */}
        </div>
            <hr style={{color:"#E2E8EE",backgroundColor:"#E2E8EE",height:"1px"}} />
            {
                data?.map((res)=>{
                    return(
                        <>
                        <div className="main_div_gi">
            <div className="col_div">
                <p className="hed_text_r">{res.id}</p>
            </div>
            <div className="col_div">
                <p className="hed_text_r">{res.customer_name}</p>
            </div>
            <div className="col_div">
                <p className="hed_text_r">{res.email}</p>
                <p className="hed_text_r">{res.phone_number}</p>
            </div>
            <div className="col_div">
                <p className="hed_text_r">{res.grand_total}</p>
            </div>
            <div className="col_div">
                <p className="hed_text_r">{res.payment_cycle_count}</p>
            </div>
            {/* <div className="col_div">
                <p className="hed_text_r">{res.pay_emi}/{parseInt(res.pay_emi)+parseInt(res.unpay_emi)}</p>
            </div> */}
           
            <div className="col_div">
                <p className="hed_text_r">12/3/12</p>
            </div>
            <div className="col_div">
                
            </div>
        </div>
                        </>
                    )
                })
            }
    </div>
  )
}

export default Invoice