import React from 'react'
import AccessMessage from './AccessMessage'

function Notaccess() {
  return (
    <div className="app" style={{height:"100vh",display:"flex",justifyContent:"center",alignItems:"center"}}>
    
    <div>
    <h1 style={{color:"gray",textAlign:"center"}} >Welcome to the Minters</h1>
    
   
    <AccessMessage message="Access denied. You don't have permission to view this page." />
    </div>
  </div>
  )
}

export default Notaccess
