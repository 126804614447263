import logo from './logo.svg';
import './App.css';
import AllRoute from './AllRoute/AllRoute';
import SideBar from './componet/SideBar/SideBar';
import Head from './componet/Hade/Head';
import { Route, Routes, useLocation } from 'react-router-dom';
import Login from './componet/Login/Login';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
function App() {
  const Location = useLocation()
  return (
    <>
    <ToastContainer />
    {
      Location.pathname =="/" ? 
      <Routes>
  <Route path='/' element={<Login/>} />

  </Routes>
      :
      <div className='row' style={{height:"100vh",width:"100%"}} >
    <div className='col-lg-2' >
<SideBar/>
    </div>
    <div className="col-lg-10">
      {
        Location.pathname =="/notaccess" ? null :

    <Head/>
      }
      <AllRoute/>
    </div>
    </div>
    }
 
   
    </>
  );
}

export default App;
