import React from 'react'
import Homedata1 from './Homedata1'
import Homedata2 from './Homedata2'

function Homedata() {
  return (
    <div className='row mt-4 ' >
      <div className='col-lg-5' >
        <Homedata1/>
      </div>
      {/* <div className='col-lg-7' >
    <Homedata2/>
      </div> */}
    </div>
  )
}

export default Homedata
