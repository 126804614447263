import React, { useContext, useEffect, useState } from 'react'
import ph from "../../img/ph_info.png"
import ant from "../../img/ant-design_file-excel-outlined.png"
import fluent_call from "../../img/fluent_call-16-regular.png"
import ion_mail from "../../img/ion_mail-outline.png"
import view from "../../img/view details.png"
import edit from "../../img/edit details.png"
import deletes from "../../img/delete.png"
import "./Vendors.css"

import { CiSearch } from "react-icons/ci";
import { useDispatch, useSelector } from 'react-redux'
import { deletevendors, editvendors, getvendors, getvendorsid } from '../../userSlice'
import { NameContext } from '../../NameContext'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};
function Vendorsdata() {
  const [name, setname] = useState("")
  const [email, setemail] = useState("")
  const [number, setnumber] = useState("")
  const [gst_number, setgst_number] = useState("")
  const [id, setid] = useState("")
  const [pageRefares, setpageRefares] = useState(false)
  const [vanderstokedata, setvanderstokedata] = useState([])
  const dispatch = useDispatch();

  const { headDate, updateDate } = useContext(NameContext);


  const vendors = useSelector(state => state.custom2.getVendors); // Assuming userData contains vendors data
  const vendorsid = useSelector(state => state.custom2.singervendedata); // Assuming userData contains vendors data
  const { isAuthenticated, error, isLoading, singervendedata } = useSelector((state) => state.custom2)
  const { venderdata, updateName } = useContext(NameContext);
  const [vanderData, setvanderData] = useState({
    name: "",
    email: "",
    number: "",
    gst_number: "",
  })
  

const [tabof, settabof] = useState();

  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);
  const [defaltdata, setdefaltdata] = useState()

  const updateVendor = () => {
    dispatch(editvendors({ id, name, email, number, gst_number }))
    dispatch(getvendors({date:headDate}));
    setpageRefares(!pageRefares)
  };

  const detaShow = async () => {
    console.log(singervendedata);
    setdefaltdata(singervendedata.data[0])
    setname(singervendedata.data[0].name)
    setnumber(singervendedata.data[0].number)
    setgst_number(singervendedata.data[0].gst_number)
    setemail(singervendedata.data[0].email)
    handleClose()
  }
  const deleteVender = async () => {
    dispatch(deletevendors({ id }))
    dispatch(getvendors({date:headDate}));
    setpageRefares(!pageRefares)
    handleClose2()
  }

  useEffect(() => {
    dispatch(getvendors({date:headDate}));
  }, [dispatch, pageRefares,headDate]);

  useEffect(() => {
    if (id !== "") {
      dispatch(getvendorsid({ id: id }));
    }
  }, [dispatch, id]);


const getvenderstoke = async(id)=>{
  try {
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
myHeaders.append("Authorization", `Bearer ${token}`);

const requestOptions = {
  method: "GET",
  headers: myHeaders,
  redirect: "follow"
};

fetch(`https://erpapp.mintersreclaimers.in/minters/user/get/vendors/id/stoke?id=${id}`, requestOptions)
  .then((response) => response.json())
  .then((result) => {console.log(result.data)
    setvanderstokedata(result.data)

  })
  .catch((error) => console.error(error));
  } catch (error) {
    console.log(error);
  }
}


  const handleVendorClick = (vendorId) => {
    setid(JSON.stringify(vendorId));
  };

  
  return (
    <div className='ms-3 mt-4'>

{/* getvendors */}

      <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight8" aria-labelledby="offcanvasRightLabel" style={{ width: "50vw" }} >
        <div class="offcanvas-header">
          <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          <h5 class="offcanvas-title" id="offcanvasRightLabel">View Vendor Details</h5>
        </div>
        <div class="offcanvas-body">
          <div>
            <span>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_116_1900)">
                  <g clip-path="url(#clip1_116_1900)">
                    <path d="M14.375 14.375V16.25H5.3125C4.56658 16.25 3.85121 15.9537 3.32376 15.4262C2.79632 14.8988 2.5 14.1834 2.5 13.4375C2.5 12.6916 2.79632 11.9762 3.32376 11.4488C3.85121 10.9213 4.56658 10.625 5.3125 10.625H5.625V9.375H5.3125C4.23506 9.375 3.20175 9.80301 2.43988 10.5649C1.67801 11.3267 1.25 12.3601 1.25 13.4375C1.25 14.5149 1.67801 15.5483 2.43988 16.3101C3.20175 17.072 4.23506 17.5 5.3125 17.5H14.375V19.375H19.375V14.375H14.375ZM18.125 18.125H15.625V15.625H18.125V18.125Z" fill="#808792" />
                    <path d="M13.125 13.75H11.875V11.875H8.125V13.75H6.875V11.875C6.87533 11.5436 7.00713 11.2258 7.24148 10.9915C7.47583 10.7571 7.79358 10.6253 8.125 10.625H11.875C12.2064 10.6253 12.5242 10.7571 12.7585 10.9915C12.9929 11.2258 13.1247 11.5436 13.125 11.875V13.75ZM10 10C9.62916 10 9.26665 9.89003 8.95831 9.68401C8.64996 9.47798 8.40964 9.18514 8.26773 8.84253C8.12581 8.49992 8.08868 8.12292 8.16103 7.75921C8.23337 7.39549 8.41195 7.0614 8.67417 6.79918C8.9364 6.53695 9.27049 6.35838 9.63421 6.28603C9.99792 6.21368 10.3749 6.25081 10.7175 6.39273C11.0601 6.53464 11.353 6.77496 11.559 7.08331C11.765 7.39165 11.875 7.75416 11.875 8.125C11.8745 8.62213 11.6768 9.09875 11.3253 9.45028C10.9738 9.8018 10.4971 9.9995 10 10ZM10 7.5C9.87639 7.5 9.75555 7.53666 9.65277 7.60533C9.54999 7.67401 9.46988 7.77162 9.42258 7.88582C9.37527 8.00003 9.36289 8.12569 9.38701 8.24693C9.41112 8.36817 9.47065 8.47953 9.55806 8.56694C9.64547 8.65435 9.75683 8.71388 9.87807 8.73799C9.99931 8.76211 10.125 8.74973 10.2392 8.70243C10.3534 8.65512 10.451 8.57501 10.5197 8.47223C10.5883 8.36945 10.625 8.24861 10.625 8.125C10.6248 7.95929 10.5589 7.80042 10.4418 7.68324C10.3246 7.56607 10.1657 7.50017 10 7.5Z" fill="#808792" />
                    <path d="M14.6875 2.5H5.625V0.625H0.625V5.625H5.625V3.75H14.6875C15.4334 3.75 16.1488 4.04632 16.6762 4.57376C17.2037 5.10121 17.5 5.81658 17.5 6.5625C17.5 7.30842 17.2037 8.02379 16.6762 8.55124C16.1488 9.07868 15.4334 9.375 14.6875 9.375H14.375V10.625H14.6875C15.7649 10.625 16.7983 10.197 17.5601 9.43512C18.322 8.67325 18.75 7.63994 18.75 6.5625C18.75 5.48506 18.322 4.45175 17.5601 3.68988C16.7983 2.92801 15.7649 2.5 14.6875 2.5ZM4.375 4.375H1.875V1.875H4.375V4.375Z" fill="#808792" />
                  </g>
                </g>
                <defs>
                  <clipPath id="clip0_116_1900">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                  <clipPath id="clip1_116_1900">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>

            </span>
            <span className='ms-2' style={{ fontFamily: 'Archivo', fontSize: '14px', fontWeight: '500', lineHeight: '15.23px', textAlign: 'left', color: '#808792' }}>
              Name of Vendor
            </span>
          </div>



          <div className='my-4'>
            <span className='' style={{ fontFamily: 'Archivo', fontSize: '20px', fontWeight: '500', lineHeight: '21.76px', textAlign: 'left' }}> {vanderData?.name}</span>
            <br />
            <span className='pb-2' style={{ fontFamily: 'Archivo', fontSize: '12px', fontWeight: '700', lineHeight: '13.06px', textAlign: 'left', borderBottom: '1px solid #E2E8EE' }}> GST Number : {vanderData?.gst}</span>
          </div>


          <div style={{ display: 'flex', gap: "50px" }} >
            <div style={{ borderBottom: '1px solid #E2E8EE' }}>
              <div>
                <span className=""><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.56109 2.551C5.48711 2.34961 5.33888 2.18416 5.1468 2.08858C4.95472 1.99301 4.73334 1.97455 4.52809 2.037C3.55509 2.337 2.87509 3.172 3.02109 4.138C3.18437 5.21014 3.54768 6.24205 4.09209 7.18C4.63068 8.1158 5.33675 8.94458 6.17509 9.625C6.92909 10.235 7.98709 10.067 8.73309 9.367C8.89001 9.21972 8.98522 9.0184 8.99951 8.80367C9.0138 8.58894 8.94611 8.37678 8.81009 8.21L8.27609 7.557C8.16913 7.42569 8.02518 7.32955 7.86292 7.28105C7.70066 7.23255 7.52756 7.23393 7.36609 7.285L6.13409 7.675L5.91909 7.453C5.64819 7.17205 5.41124 6.86024 5.21309 6.524C5.02077 6.18397 4.87098 5.82158 4.76709 5.445L4.68409 5.15L5.63409 4.275C5.75945 4.1586 5.84738 4.00758 5.88673 3.8411C5.92608 3.67462 5.91507 3.50021 5.85509 3.34L5.56109 2.551ZM4.23409 1.081C4.68324 0.943579 5.16794 0.983131 5.58888 1.19155C6.00981 1.39997 6.3351 1.76148 6.49809 2.202L6.79109 2.99C6.92034 3.3362 6.94372 3.71301 6.85825 4.07253C6.77277 4.43204 6.58231 4.75802 6.31109 5.009L5.82109 5.46C5.88409 5.634 5.96909 5.833 6.07909 6.026C6.18909 6.216 6.31709 6.389 6.43409 6.53L7.06409 6.33C7.4163 6.2188 7.79378 6.21587 8.14768 6.3216C8.50157 6.42732 8.81559 6.63683 9.04909 6.923L9.58309 7.576C9.87986 7.93891 10.0278 8.40095 9.99697 8.86873C9.96616 9.33652 9.7589 9.77516 9.41709 10.096C8.42309 11.029 6.80009 11.416 5.54709 10.402C4.61343 9.64456 3.82702 8.72188 3.22709 7.68C2.61974 6.63384 2.21437 5.48287 2.03209 4.287C1.79209 2.694 2.93509 1.481 4.23409 1.082" fill="#364050" />
                </svg>
                </span>
                <span className="" style={{ fontFamily: 'Archivo', fontSize: '10px', fontWeight: '400', lineHeight: '10.88px', textAlign: 'left' }}>
                  Phone number
                </span>
              </div>
              <div>
                <p>+91 {vanderData.number}</p>
              </div>
            </div>
            <div style={{ borderBottom: '1px solid #E2E8EE' }} >
              <div>
                <span>
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.5 4.5L6 6.25L8.5 4.5" stroke="#364050" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M1 8.5V3.5C1 3.23478 1.10536 2.98043 1.29289 2.79289C1.48043 2.60536 1.73478 2.5 2 2.5H10C10.2652 2.5 10.5196 2.60536 10.7071 2.79289C10.8946 2.98043 11 3.23478 11 3.5V8.5C11 8.76522 10.8946 9.01957 10.7071 9.20711C10.5196 9.39464 10.2652 9.5 10 9.5H2C1.73478 9.5 1.48043 9.39464 1.29289 9.20711C1.10536 9.01957 1 8.76522 1 8.5Z" stroke="#364050" stroke-width="0.5" />
                  </svg>

                </span>
                <span style={{ fontFamily: 'Archivo', fontSize: '10px', fontWeight: 400, lineHeight: '10.88px', textAlign: 'left' }}>
                  Email
                </span>
              </div>
              <div>
                <p>{vanderData.email}</p>
              </div>
            </div>
          </div>



          <div className="my-4">
            <div className='pb-3' style={{display:"flex",gap:"90px",borderBottom:"1px solid #E2E8EE"}} >
              <div onClick={()=>{settabof(1)}} style={{cursor:"pointer"}}> 
                <span><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M15.075 2.12416C14.615 1.86583 14.125 1.84083 13.6075 1.905C13.1158 1.96666 12.5166 2.12166 11.7975 2.30916L10.115 2.74583C9.39581 2.93333 8.79748 3.08833 8.33998 3.27416C7.85748 3.46916 7.44081 3.73083 7.17081 4.185C6.89915 4.64166 6.87248 5.13 6.93998 5.64C7.00415 6.12083 7.16498 6.70416 7.35665 7.39916L7.80915 9.03583C8.00081 9.73166 8.16248 10.3142 8.35498 10.7608C8.55915 11.2358 8.83165 11.64 9.29498 11.9C9.75498 12.1575 10.245 12.1833 10.7633 12.1183C11.255 12.0575 11.8541 11.9017 12.5733 11.7142L14.2558 11.2775C14.975 11.0908 15.5733 10.935 16.0308 10.7492C16.5133 10.5542 16.93 10.2925 17.2 9.83833C17.4716 9.38166 17.4983 8.89333 17.4308 8.38416C17.3666 7.90333 17.2058 7.31916 17.0141 6.62416L16.5616 4.9875C16.37 4.2925 16.2083 3.70916 16.0158 3.2625C15.8116 2.7875 15.5375 2.38333 15.075 2.12416ZM12.0708 3.52916C12.8416 3.32916 13.3608 3.19583 13.7625 3.14583C14.1466 3.0975 14.3308 3.14 14.465 3.215C14.5958 3.28833 14.7216 3.41583 14.8675 3.75666C15.0216 4.115 15.1616 4.61416 15.3683 5.3625L15.7966 6.915C16.0041 7.66333 16.1408 8.16333 16.1916 8.54833C16.24 8.91416 16.1966 9.08 16.125 9.19916C16.0525 9.32166 15.9208 9.44583 15.5625 9.59083C15.1891 9.74166 14.6708 9.87833 13.9 10.0783L12.3 10.4942C11.5291 10.6942 11.01 10.8275 10.6083 10.8775C10.2233 10.9267 10.04 10.8842 9.90581 10.8092C9.77498 10.7358 9.64915 10.6075 9.50331 10.2675C9.34915 9.90916 9.20915 9.40916 9.00248 8.66083L8.57415 7.10916C8.36665 6.35916 8.22998 5.86 8.17915 5.47583C8.13081 5.10916 8.17415 4.94416 8.24581 4.82416C8.31831 4.70166 8.44998 4.5775 8.80831 4.4325C9.18165 4.28166 9.69998 4.14583 10.4708 3.945L12.0708 3.52916Z" fill="#5560A0" />
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M2.66664 3.93747C2.50877 3.89877 2.34202 3.92309 2.20178 4.00529C2.06154 4.08749 1.95884 4.22109 1.91548 4.37776C1.87211 4.53442 1.89148 4.70181 1.96949 4.84443C2.0475 4.98704 2.178 5.09365 2.33331 5.14164L3.75248 5.53581C4.10748 5.63414 4.37164 5.90247 4.46164 6.22831L6.08831 12.1141C6.02537 12.1266 5.96284 12.1411 5.90081 12.1575C4.25331 12.585 3.24664 14.2425 3.69831 15.88C4.14831 17.5091 5.85998 18.4483 7.50081 18.0216C8.93748 17.6491 9.88664 16.3416 9.80664 14.9283L16.8233 13.105C16.9028 13.0843 16.9774 13.0483 17.0429 12.9988C17.1084 12.9493 17.1635 12.8875 17.2051 12.8167C17.2467 12.7459 17.2739 12.6677 17.2853 12.5864C17.2966 12.5051 17.2919 12.4223 17.2712 12.3429C17.2506 12.2634 17.2145 12.1888 17.1651 12.1233C17.1156 12.0578 17.0537 12.0027 16.983 11.9611C16.9122 11.9195 16.8339 11.8923 16.7526 11.8809C16.6713 11.8696 16.5886 11.8743 16.5091 11.895L9.47748 13.7225C9.26711 13.3205 8.97105 12.9696 8.6102 12.6945C8.24936 12.4195 7.83252 12.2271 7.38914 12.1308L5.66664 5.89497C5.56091 5.51985 5.3598 5.17851 5.0829 4.90424C4.806 4.62997 4.46276 4.43212 4.08664 4.32997L2.66748 3.93664L2.66664 3.93747ZM6.21581 13.3666C7.22581 13.105 8.23914 13.6908 8.49914 14.6325C8.75748 15.5658 8.19081 16.5516 7.18748 16.8125C6.17748 17.075 5.16414 16.4883 4.90414 15.5466C4.64581 14.6133 5.21248 13.6275 6.21581 13.3666Z" fill="#5560A0" />
                </svg>
                </span>

                <span>
                  Deliveries
                </span>
              </div>
              {/* <div onClick={()=>{settabof(2)}} style={{cursor:"pointer"}}>
                <span><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.8332 6.25002V2.91669H4.1665V6.25002" stroke="#667286" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M17.9166 11.25V6.25H2.08325V17.0833H17.9166" stroke="#667286" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M12.0833 11.25L10 14.1667H17.9167" stroke="#667286" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M10.8332 8.75H9.1665" stroke="#667286" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M6.66667 8.75H5" stroke="#667286" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                </span>

                <span>
                  Credit
                </span>
              </div> */}
            </div>

            <div>
              {
                tabof===1?
                <div>
                  {vanderstokedata?.map((res)=>{
                    return (
                    <div className="row" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", borderBottom: "1px solid #D9DBE6",padding: "19px 0" }}>
                      <div className="col-3" style={{ display: "flex", flexDirection: "column" }}>
                        <span style={{ fontSize: "16px", fontWeight: "600" }}>
                          {new Date(res.deliverydate).toLocaleDateString()}
                        </span>
                        <span style={{ fontSize: "16px", fontWeight: "600" }}>
                          {res.deliverytime}
                        </span>
                      </div>
                      <div className="col-3" style={{ display: "flex", flexDirection: "column" }}>
                        <span style={{ fontSize: "16px", fontWeight: "600" }}>₹ {res.enter_unit_price}</span>
                        <span style={{ fontSize: "16px", fontWeight: "600" }}>Qty. {res.enter_qty}</span>
                      </div>
                      <div className="col-3" style={{ display: "flex", flexDirection: "column" }}>
                        <span style={{ fontSize: "16px", fontWeight: "600" }}>{res.category_name}</span>
                      </div>
                      <div className="col-3" style={{ display: "flex", flexDirection: "column" }}>
                        <span style={{ fontSize: "16px", fontWeight: "600" }}>{res.sub_category_name}</span>
                      </div>
                    </div>

                    )
                  })}
                </div>
                :<h1> 2</h1>
              }
            </div>
          </div>
        </div>
      </div>
      <div>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <h4 className='text-warning mb-3' >confirm update data</h4>
            <div className="row">
              <div className="col-6">
                <button className='btn btn-danger' onClick={handleClose} >Close</button>
              </div>
              <div className="col-6"><button className='btn btn-success' data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight2" aria-controls="offcanvasRight" onClick={detaShow} >Yes</button></div>
            </div>
          </Box>
        </Modal>
      </div>
      <div>

        <Modal
          open={open2}
          onClose={handleClose2}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <h4 className='text-warning mb-3' >confirm Delete Vender</h4>
            <div className="row">
              <div className="col-6">
                <button className='btn btn-danger' onClick={handleClose2} >Close</button>
              </div>
              <div className="col-6"><button className='btn btn-success' onClick={deleteVender} >Yes</button></div>
            </div>
          </Box>
        </Modal>
      </div>
      <div className="card m-0 p-0 px-3 pt-2">
        <div className="row" style={{ borderBottom: "1px solid #D9DBE6" }}>
          <div className="col-lg-3 mt-2" style={{ display: "flex" }} >
            <div>Vender

              <p style={{ color: "#364050", fontSize: "24px", fontWeight: "400" }} >List of Vendors</p>
            </div>
            <div className='mt-1'>
              <img className='mt-2 ms-2' src={ph} alt="" style={{ height: "20px", width: "20px" }} />
            </div>
          </div>
          <div className="col-lg-5 mt-1">
            <div class="input-group mb-3">
              <span class="input-group-text" id="basic-addon1" style={{ backgroundColor: "transparent" }}><CiSearch />
              </span>
              <input type="text" class="form-control jacj" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" style={{ borderLeft: "none", lineHeight: "30px", outline: "none" }} />
            </div>

          </div>
          <div className="col-lg-2">

          </div>
          <div className="col-lg-2 mt-1">
            <button className='btn' style={{ border: "1px solid #5F6AA6" }}>
              <span>
                <img src={ant} alt="s.techooze" style={{ height: "20px", width: "20px" }} />
              </span>
              Export Data
            </button>
          </div>
        </div>
        <div>
          <div class="row mt-2 pb-3" style={{ borderBottom: "1px solid #D9DBE6" }}>

            <div className='col-lg-1 jol m-0 p-0 px-4' style={{ color: "#364050", textAlign: "start" }} >S.No</div>
            <div className='col-lg-3 jol m-0 p-0 px-4 ' style={{ color: "#364050", textAlign: "start" }} >Name</div>
            <div className='col-lg-2 jol m-0 p-0 px-4 ' style={{ color: "#364050", textAlign: "start" }} >Contact Details</div>
            <div className='col-lg-2 jol m-0 p-0 px-3' style={{ color: "#364050", textAlign: "start" }} >GST Details</div>
            <div className='col-lg-2 jol m-0 p-0 ' style={{ color: "#364050", textAlign: "start" }} >KYC </div>
            <div className='col-lg-2 jol m-0 p-0 ' style={{ color: "#364050", textAlign: "start" }} >Actions</div>
          </div>

          <div className="example  " style={{ height: "66vh", overflowY: "scroll" }}>
            {
              vendors?.data?.map((res) => {
                return (
                  <div className='row m-0 p-0 g-0 mb-1 mt-4' style={{ borderBottom: "1px solid #D9DBE6" }}>
                    <div className="col-lg-1 "><p className='x1'>#{res.id}</p></div>
                    <div className="col-lg-2 "><p className='x2'>{res.name} </p></div>
                    <div className="col-lg-3"><p className='x3'><span> <img src={fluent_call} alt="" style={{ height: "20px", width: "20px" }} /> </span>+91 ${res.number} <br /> <span><img style={{ height: "20px", width: "20px" }} src={ion_mail} alt="" /></span> {res.email}</p></div>
                    <div className="col-lg-2"><p className='x4'>{res.gst_number}</p></div>
                    <div className="col-lg-2"><button className='btn td_btn' >Yes</button></div>
                    <div className="col-lg-2">
                      <div className='row'>
                        <div className="col-lg-4" onClick={() => { setvanderData({...vanderData, name: res.name, email: res.email, number: res.number, gst_number: res.gst_number});getvenderstoke(res.id) }} style={{ cursor: "pointer" }} data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight8" aria-controls="offcanvasRight"><img src={view} alt="" style={{ height: "40px", width: "40px" }} /></div>
                        <div className="col-lg-4" onClick={() => { handleVendorClick(res.id); handleOpen() }} style={{ cursor: "pointer" }}  ><img src={edit} alt="" style={{ height: "40px", width: "40px" }} /></div>
                        <div className="col-lg-4" onClick={() => { handleVendorClick(res.id); handleOpen2() }} ><img src={deletes} alt="" style={{ height: "40px", width: "40px" }} /></div>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>

        </div>
      </div>
      <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight2" aria-labelledby="offcanvasRightLabel" style={{ width: "50vw" }} >
        <div class="offcanvas-header">
          <div style={{ display: "flex" }} >

            <button type="button" class="btn-close text-reset  " data-bs-dismiss="offcanvas" aria-label="Close"></button>
            <h5 id="offcanvasRightLabel" className='ms-4 dhjdhjdh' >Add Vendor</h5>
          </div>
        </div>
        <div class="offcanvas-body">
          <div class="input-group mb-3 kokdkdjd p-3">
            <span class="input-group-text jdjddu" id="basic-addon1">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_116_1850)">
                  <g clip-path="url(#clip1_116_1850)">
                    <path d="M17.25 17.25V19.5H6.375C5.47989 19.5 4.62145 19.1444 3.98851 18.5115C3.35558 17.8786 3 17.0201 3 16.125C3 15.2299 3.35558 14.3715 3.98851 13.7385C4.62145 13.1056 5.47989 12.75 6.375 12.75H6.75V11.25H6.375C5.08207 11.25 3.84209 11.7636 2.92785 12.6779C2.01361 13.5921 1.5 14.8321 1.5 16.125C1.5 17.4179 2.01361 18.6579 2.92785 19.5721C3.84209 20.4864 5.08207 21 6.375 21H17.25V23.25H23.25V17.25H17.25ZM21.75 21.75H18.75V18.75H21.75V21.75Z" fill="#808792" />
                    <path d="M15.75 16.5H14.25V14.25H9.75V16.5H8.25V14.25C8.2504 13.8523 8.40856 13.471 8.68978 13.1898C8.971 12.9086 9.3523 12.7504 9.75 12.75H14.25C14.6477 12.7504 15.029 12.9086 15.3102 13.1898C15.5914 13.471 15.7496 13.8523 15.75 14.25V16.5ZM12 12C11.555 12 11.12 11.868 10.75 11.6208C10.38 11.3736 10.0916 11.0222 9.92127 10.611C9.75097 10.1999 9.70642 9.74751 9.79323 9.31105C9.88005 8.87459 10.0943 8.47368 10.409 8.15901C10.7237 7.84434 11.1246 7.63005 11.561 7.54323C11.9975 7.45642 12.4499 7.50097 12.861 7.67127C13.2722 7.84157 13.6236 8.12996 13.8708 8.49997C14.118 8.86998 14.25 9.30499 14.25 9.75C14.2494 10.3466 14.0122 10.9185 13.5903 11.3403C13.1685 11.7622 12.5966 11.9994 12 12ZM12 9C11.8517 9 11.7067 9.04399 11.5833 9.1264C11.46 9.20881 11.3639 9.32594 11.3071 9.46299C11.2503 9.60003 11.2355 9.75083 11.2644 9.89632C11.2933 10.0418 11.3648 10.1754 11.4697 10.2803C11.5746 10.3852 11.7082 10.4567 11.8537 10.4856C11.9992 10.5145 12.15 10.4997 12.287 10.4429C12.4241 10.3861 12.5412 10.29 12.6236 10.1667C12.706 10.0433 12.75 9.89834 12.75 9.75C12.7498 9.55115 12.6707 9.3605 12.5301 9.21989C12.3895 9.07928 12.1989 9.0002 12 9Z" fill="#808792" />
                    <path d="M17.625 3H6.75V0.75H0.75V6.75H6.75V4.5H17.625C18.5201 4.5 19.3785 4.85558 20.0115 5.48851C20.6444 6.12145 21 6.97989 21 7.875C21 8.77011 20.6444 9.62855 20.0115 10.2615C19.3785 10.8944 18.5201 11.25 17.625 11.25H17.25V12.75H17.625C18.9179 12.75 20.1579 12.2364 21.0721 11.3221C21.9864 10.4079 22.5 9.16793 22.5 7.875C22.5 6.58207 21.9864 5.34209 21.0721 4.42785C20.1579 3.51361 18.9179 3 17.625 3ZM5.25 5.25H2.25V2.25H5.25V5.25Z" fill="#808792" />
                  </g>
                </g>
                <defs>
                  <clipPath id="clip0_116_1850">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                  <clipPath id="clip1_116_1850">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>

            </span>
            <input value={name} defaultValue={defaltdata?.name} type="text" class="form-control jdjddu" onChange={(e) => { setname(e.target.value) }} placeholder="Name of Vendor" aria-label="Username" aria-describedby="basic-addon1" />
          </div>
          <div class="input-group mb-3 kokdkdjd p-3">
            <span class="input-group-text jdjddu" id="basic-addon1">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.875 4.5H4.125C3.08947 4.5 2.25 5.33947 2.25 6.375V17.625C2.25 18.6605 3.08947 19.5 4.125 19.5H19.875C20.9105 19.5 21.75 18.6605 21.75 17.625V6.375C21.75 5.33947 20.9105 4.5 19.875 4.5Z" stroke="#808792" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M5.25 7.5L12 12.75L18.75 7.5" stroke="#808792" stroke-linecap="round" stroke-linejoin="round" />
              </svg>


            </span>
            <input value={email} defaultValue={defaltdata?.email} type="text" class="form-control jdjddu" onChange={(e) => { setemail(e.target.value) }} placeholder="Email Address" aria-label="Username" aria-describedby="basic-addon1" />
          </div>
          <div class="input-group mb-3 kokdkdjd p-3">
            <span class="input-group-text jdjddu" id="basic-addon1">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.6424 6.57251C4.40365 5.00001 5.5124 3.58751 7.20615 3.07001C7.50672 2.97811 7.83102 3.00427 8.11297 3.14316C8.39492 3.28205 8.61331 3.52321 8.72365 3.81751L9.2674 5.26751C9.35493 5.50078 9.37076 5.75488 9.31285 5.99721C9.25494 6.23953 9.12594 6.45902 8.9424 6.62751L7.3249 8.10876C7.24517 8.18193 7.18576 8.27451 7.15246 8.37747C7.11916 8.48043 7.11311 8.59026 7.1349 8.69626L7.1499 8.76126L7.18865 8.92376C7.3898 9.71332 7.69631 10.4722 8.0999 11.18C8.53954 11.9321 9.08513 12.6171 9.7199 13.2138L9.7699 13.2588C9.85065 13.3304 9.94859 13.38 10.0542 13.4025C10.1598 13.4251 10.2694 13.4199 10.3724 13.3875L12.4636 12.7288C12.7013 12.6541 12.9559 12.6522 13.1946 12.7233C13.4334 12.7943 13.6454 12.9351 13.8036 13.1275L14.7936 14.3288C15.2061 14.8288 15.1561 15.5638 14.6824 16.005C13.3861 17.2138 11.6036 17.4613 10.3636 16.465C8.84368 15.239 7.56234 13.7436 6.58365 12.0538C5.59701 10.3652 4.93922 8.50555 4.6424 6.57251ZM8.44615 8.77876L9.78615 7.54876C10.1534 7.21193 10.4117 6.77301 10.5277 6.28835C10.6438 5.8037 10.6123 5.29541 10.4374 4.82876L9.8949 3.37876C9.67283 2.78657 9.23331 2.30134 8.66592 2.02194C8.09852 1.74253 7.44594 1.68999 6.84115 1.87501C4.7374 2.51876 3.04865 4.40501 3.40615 6.76126C3.65615 8.40626 4.2324 10.4988 5.50365 12.6838C6.55954 14.5059 7.94172 16.1182 9.58115 17.44C11.4411 18.9338 13.9236 18.4238 15.5361 16.9213C15.9975 16.4917 16.2776 15.9022 16.3192 15.2731C16.3608 14.6441 16.1607 14.0229 15.7599 13.5363L14.7699 12.3338C14.4532 11.9494 14.0289 11.6683 13.5514 11.5267C13.0739 11.385 12.565 11.3892 12.0899 11.5388L10.3536 12.085C9.90536 11.6228 9.51269 11.1097 9.18365 10.5563C8.8658 9.99671 8.6181 9.40014 8.44615 8.78001V8.77876Z" fill="#808792" />
              </svg>



            </span>
            <input value={number} defaultValue={defaltdata?.number} type="text" class="form-control jdjddu" onChange={(e) => { setnumber(e.target.value) }} placeholder="Contact Number ( without country code )" aria-label="Username" aria-describedby="basic-addon1" />
          </div>
          <div class="input-group mb-3 kokdkdjd p-3">
            <span class="input-group-text jdjddu" id="basic-addon1">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 14L15 8" stroke="#808792" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M9.5 9C9.77614 9 10 8.77614 10 8.5C10 8.22386 9.77614 8 9.5 8C9.22386 8 9 8.22386 9 8.5C9 8.77614 9.22386 9 9.5 9Z" fill="#808792" stroke="#808792" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M14.5 14C14.7761 14 15 13.7761 15 13.5C15 13.2239 14.7761 13 14.5 13C14.2239 13 14 13.2239 14 13.5C14 13.7761 14.2239 14 14.5 14Z" fill="#808792" stroke="#808792" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M5 21V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H17C17.5304 3 18.0391 3.21071 18.4142 3.58579C18.7893 3.96086 19 4.46957 19 5V21L16 19L14 21L12 19L10 21L8 19L5 21Z" stroke="#808792" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>


            </span>
            <input value={gst_number} defaultValue={defaltdata?.gst_number} type="text" class="form-control jdjddu" onChange={(e) => { setgst_number(e.target.value) }} placeholder="GST Number" aria-label="Username" aria-describedby="basic-addon1" />
          </div>
          <div>
            <button className='btn hdhdhdg p-3' onClick={updateVendor} > Update Vendor</button>
          </div>
        </div>

      </div>

    </div>
  )
}

export default Vendorsdata
