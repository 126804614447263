import React, { useEffect, useState } from 'react'
import mainlogo from "../../img/mainlogo.png"
import { useDispatch, useSelector } from "react-redux";
import "./Login.css"
import { login } from '../../userSlice';
import { useNavigate } from 'react-router-dom';
function Login() {
    const [email, setname] = useState("")
    const [password, setpassword] = useState("")
    const naviget =useNavigate()
    const dispatch = useDispatch()
    const { isAuthenticated, error, isLoading, userData } = useSelector((state) => state.custom2)
    const Loginapi = async()=>{
       
        dispatch(login({ email, password }));
    }
    useEffect(()=>{
        localStorage.setItem("Token",userData?.token)
        if (isAuthenticated == true) {
            naviget("/home")
            
        }
    },[userData])
  return (
    <div style={{display:"flex",justifyContent:"center",alignItems:"center",height:"100vh"}} >
      <div style={{width:"30vw"}} >
     <div className='mb-4' style={{display:"flex",justifyContent:"center"}} >
     <div style={{ fontStyle: 'oblique', color: 'black',fontWeight:'700' }}>
        <img src={mainlogo} alt="" /> MINTERS
        </div>
     </div>
        <div className="card p-5" style={{borderRadius:"22px"}}>
        <p className='kjwhhwwh' >Login</p>
        <p className="jsm">
        Login to Minters Reclaimers Administration Panel
        </p>
        <div class="mb-3">
 
  <input type="email" onChange={(e)=>{setname(e.target.value)}} class="form-control gsshsshs" id="exampleFormControlInput1" placeholder="Enter Username/Registered Email"/>
</div>
        <div class="mb-3">
 
  <input type="password" onChange={(e)=>{setpassword(e.target.value)}} class="form-control gsshsshs" id="exampleFormControlInput1" placeholder="Enter Username/Registered Email"/>
</div>

<div className="mb-3">
<button className="btn hhwhwhh p-3 " style={{borderRadius:"15px"}} onClick={Loginapi} >Login</button>
</div>


        </div>
        <div className='mt-5' >
            <p className='sjsshshhs' >Note : By Accessing to this portal you agree to Terms & Conditions, User License Agreement, Employee Working Agreement & Privacy Policy of Minters Reclaimers Pvt Ltd.</p>
        </div>
      </div>
    </div>
  )
}

export default Login
