import React from 'react'
import HomeHead from './HomeHead'
import Homedata from './Homedata'

function Home() {
  return (
    <div className='container-fluid m-0 p-0 g-0 '  >
    <HomeHead/>
      <Homedata/>
    </div>
  )
}

export default Home
