import React, { useEffect, useState } from 'react'
import "./Categories.css"
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { categoryAdd, categoryGet } from '../../categorySlice';
import { useNavigate } from 'react-router-dom';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};
function Categories() {
    const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [cat_name, setcat_name] = useState("")
  const [categories, setCategories] = useState([]);
  const dispatch = useDispatch();
const Naviget = useNavigate()
  const nextPage = async(id,name)=>{
    Naviget("/subcategories",{ state: { id: id,name:name } })
  }

const addCategory = () => {
    dispatch(categoryAdd({name:cat_name}))
    dispatch(categoryGet({})).then((response) => {
      setCategories(response?.payload?.data); // Assuming the API response has a data field containing category information
    });
    handleClose()
}

useEffect(() => {
    dispatch(categoryGet({})).then((response) => {
      setCategories(response?.payload?.data); // Assuming the API response has a data field containing category information
    });
  }, []);
  return (
  <>
  {/* model */}
  <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{display:"flex",justifyContent:"space-between"}} >
            <div>Add Product Category</div>
            <div onClick={handleClose} ><svg width="25" height="25" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M26.6666 26.6668L5.33325 5.3335M26.6666 5.3335L5.33325 26.6668" stroke="#364050" stroke-width="2" stroke-linecap="round"/>
</svg>
</div>
          </div>

          <div className='mb-3' >
            <input type="text" className='kkwjwjwhhw form-control' value={cat_name} placeholder='Enter Product Category Name' onChange={(e)=>{setcat_name(e.target.value)}} /> 
          </div>
          <div className="mb-3 mt-5">
            <button className="btn dggddfgddggd p-3 " style={{width:"100%"}} onClick={addCategory} >
            Add Product Category
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  {/* model */}
       <div style={{display:"flex",justifyContent:"right"}} >
       <button className="btn_add_caty me-5 mt-4" onClick={handleOpen} style={{color:"#5560A0",border:"1px solid #5F6AA6"}} >
        +Create Category
        </button>
       </div>
      <div className=' mt-5 ' >
      <div className="main_div_gird">
{
    categories?.map((res)=>{
        return(
            <div className='chail_girid' >
            <div className="card p-4">
                <p className='shgsgdfgdf' >{res.name}</p>
               <div style={{lineHeight:"0px"}} >
               <p className='jsjdhudsaydushy' >{res.sub_category_count} - Sub Categories</p>
                <p>Created : {new Date(res.created_at).toLocaleDateString()}</p>
               </div>
                <div>
                    <button className='btn hjdhddu p-4 mt-4' onClick={()=>{nextPage(res.id,res.name)}} > <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M10 6.875C9.1712 6.875 8.37634 7.20424 7.79029 7.79029C7.20424 8.37634 6.875 9.1712 6.875 10C6.875 10.8288 7.20424 11.6237 7.79029 12.2097C8.37634 12.7958 9.1712 13.125 10 13.125C10.8288 13.125 11.6237 12.7958 12.2097 12.2097C12.7958 11.6237 13.125 10.8288 13.125 10C13.125 9.1712 12.7958 8.37634 12.2097 7.79029C11.6237 7.20424 10.8288 6.875 10 6.875ZM8.125 10C8.125 9.50272 8.32254 9.02581 8.67417 8.67417C9.02581 8.32254 9.50272 8.125 10 8.125C10.4973 8.125 10.9742 8.32254 11.3258 8.67417C11.6775 9.02581 11.875 9.50272 11.875 10C11.875 10.4973 11.6775 10.9742 11.3258 11.3258C10.9742 11.6775 10.4973 11.875 10 11.875C9.50272 11.875 9.02581 11.6775 8.67417 11.3258C8.32254 10.9742 8.125 10.4973 8.125 10Z" fill="#5560A0"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.99999 2.7085C6.23832 2.7085 3.70416 4.96183 2.23332 6.87266L2.20749 6.90683C1.87416 7.33933 1.56832 7.73683 1.36082 8.20683C1.13832 8.711 1.04166 9.26016 1.04166 10.0002C1.04166 10.7402 1.13832 11.2893 1.36082 11.7935C1.56916 12.2635 1.87499 12.6618 2.20749 13.0935L2.23416 13.1277C3.70416 15.0385 6.23832 17.2918 9.99999 17.2918C13.7617 17.2918 16.2958 15.0385 17.7667 13.1277L17.7925 13.0935C18.1258 12.6618 18.4317 12.2635 18.6392 11.7935C18.8617 11.2893 18.9583 10.7402 18.9583 10.0002C18.9583 9.26016 18.8617 8.711 18.6392 8.20683C18.4308 7.73683 18.125 7.33933 17.7925 6.90683L17.7658 6.87266C16.2958 4.96183 13.7617 2.7085 9.99999 2.7085ZM3.22499 7.63516C4.58166 5.871 6.79166 3.9585 9.99999 3.9585C13.2083 3.9585 15.4175 5.871 16.775 7.63516C17.1417 8.11016 17.355 8.3935 17.4958 8.71183C17.6275 9.01016 17.7083 9.37433 17.7083 10.0002C17.7083 10.626 17.6275 10.9902 17.4958 11.2885C17.355 11.6068 17.1408 11.8902 16.7758 12.3652C15.4167 14.1293 13.2083 16.0418 9.99999 16.0418C6.79166 16.0418 4.58249 14.1293 3.22499 12.3652C2.85832 11.8902 2.64499 11.6068 2.50416 11.2885C2.37249 10.9902 2.29166 10.626 2.29166 10.0002C2.29166 9.37433 2.37249 9.01016 2.50416 8.71183C2.64499 8.3935 2.85999 8.11016 3.22499 7.63516Z" fill="#5560A0"/>
</svg>
 View</button>
                </div>
            </div>
        </div>
        )
    })
}

       
      </div>
    </div>


  </>
  )
}

export default Categories