import React,{useEffect, useState} from 'react'
import { Button, Drawer } from 'antd';
import { useDispatch } from 'react-redux';
import { createPaymentCycle } from '../../categorySlice';
import "./Home.css"
function OffDrawer({valueof,tm,gst,invoiceNumber,total_amount}) {
    const [open, setOpen] = useState();


// ac
const [lines, setLines] = useState([{ amount: '', date: '' }]);
  const [oldamaunt, setoldamaunt] = useState();
const [amount, setamount] = useState(100);
  const addNewLine = () => {
    if (amount >= 0) {
      setamount(amount - oldamaunt)
      
    }
    if ((amount - oldamaunt) <= 0) {
      return null
    }
    setLines([...lines, { amount: '', date: '' }]);
    
    
  };

  const handleAmountChange = (index, value) => {
    if (amount < value) {
      alert("amount chek")
      return null
    }
    const newLines = [...lines];
    newLines[index].amount = value;
    setLines(newLines);
    setoldamaunt(value)
    // console.log(amount,value);
  };

const dispatch = useDispatch();

  const craetinstol = () => {
    dispatch(createPaymentCycle({ data: lines, total_amount, total_gst: gst, grand_total:tm, invoices_id: invoiceNumber }))
   
  }
  const handleDateChange = (index, value) => {
    const newLines = [...lines];
    newLines[index].date = value;
    setLines(newLines);
  };

const addNew = () =>{
  console.log(lines);
}

  // useEffect to ensure there is always at least one line
  useEffect(() => {
    if (lines.length === 0) {
      setLines([{ amount: '', date: '' }]);
    }
  }, [lines]);
// ac



  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
useEffect(() => {
    setOpen(valueof)
    setamount(tm)
}, [valueof]);
  return (
    <div>
      <Drawer width={"1000px"} title="Payment Cycle" onClose={onClose} open={open}>
       <h3>Payment Cycle</h3>
       <div className='mt-5' style={{display:"flex",gap:"20px"}} >
        <h3>Total Amount</h3>
        <p  style={{fontFamily:'Archivo', fontSize:'20px', fontWeight:'400', textAlign:'left'}} > ₹ {tm}</p>
        
       </div>
       <div className='' style={{display:"flex",gap:"20px"}} >
        <h3>GST ( 18% )</h3>
        <p  style={{fontFamily:'Archivo', fontSize:'20px', fontWeight:'400', textAlign:'left'}} > ₹ {gst}</p>



        <div  className='5' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '10px' }}>
      {/* {amount} */}
      {lines.map((line, index) => (
        <div key={index} style={{ display: 'flex', width: '100%', gap: '60px', marginBottom: '10px' }}>
          <div style={{ width: '100%' }}>
            <input className='form-control ghdghdgdhgdhgddg'
              placeholder='amount'
              style={{ width: '100%' }}
              value={line.amount}
              onChange={(e) => handleAmountChange(index, e.target.value)}
            />
          </div>
          <div style={{ width: '100%' }}>
            <input className='form-control ghdghdgdhgdhgddg'
              type='date'
              style={{ width: '100%' }}
              value={line.date}
              onChange={(e) => handleDateChange(index, e.target.value)}
            />
          </div>
        </div>
      ))}
      <button className="btn mt-5" style={{ color: "rgba(95, 106, 166, 1)", fontSize: "20px" }} onClick={addNewLine}>
      +Add Installment
      </button>

      <button style={{ backgroundColor: "rgba(8, 145, 88, 1)", color: "white", width: "100%" }} onClick={craetinstol} >Creating Invoice</button>
    </div>



       </div>
      </Drawer>
    </div>
  )
}

export default OffDrawer