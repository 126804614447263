import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { getSalery } from '../../userSlice';

function Salaries() {
  const [salaries, setSalaries] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
   dispatch(getSalery({})).then((res)=>{

setSalaries(res?.payload?.data);

   })
  }, [dispatch]);
    
  return (
    <div>
      <div className="table-responsive">
        <table className="table" style={{
          borderCollapse: 'collapse',
          borderSpacing: 0,
          width: '100%',
        }}>
          <thead>
            <tr>
              <th scope="col" style={{borderBottom: '1px solid #dee2e6', padding: '0.5rem'}}>S.No</th>
              <th scope="col" style={{borderBottom: '1px solid #dee2e6', padding: '0.5rem'}}>Employee Name</th>
              <th scope="col" style={{borderBottom: '1px solid #dee2e6', padding: '0.5rem'}}>Payout Amount</th>
              <th scope="col" style={{borderBottom: '1px solid #dee2e6', padding: '0.5rem'}}>Paid on</th>
              <th scope="col" style={{borderBottom: '1px solid #dee2e6', padding: '0.5rem'}}>Status</th>
              <th scope="col" style={{borderBottom: '1px solid #dee2e6', padding: '0.5rem'}}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {
                salaries?.map((res,i)=>{
                    return(
                        <tr className='pt-5' >
                        <td style={{borderBottom: '1px solid #dee2e6', padding: '1rem 0.5rem 0rem 0.5rem',marginTop:"16px"}}>{i+1}</td>
                        <td style={{borderBottom: '1px solid #dee2e6', padding: '2rem 0.5rem 0rem 0.5rem',marginTop:"16px"}}>{res.employee_name}</td>
                        <td style={{borderBottom: '1px solid #dee2e6', padding: '2rem 0.5rem 0rem 0.5rem',marginTop:"16px"}}>{res.amount}</td>
                        <td style={{borderBottom: '1px solid #dee2e6', padding: '2rem 0.5rem 0rem 0.5rem',marginTop:"16px"}}>{new Date(res.created_at).toLocaleDateString()}</td>
                        <td style={{borderBottom: '1px solid #dee2e6', padding: '2rem 0.5rem 0rem 0.5rem',marginTop:"16px"}}> <span style={{marginTop:"10px",background: '#E5FFE8',color:"#00B35D",padding: '0.5rem'}} >Paid</span> </td>
                        <td style={{borderBottom: '1px solid #dee2e6', padding: '2rem 0.5rem 0rem 0.5rem',marginTop:"16px"}}>
                          {/* <button className="btn btn-primary btn-sm">View</button> */}
                          <svg width="37" height="34" viewBox="0 0 37 34" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="36.6667" height="33.3333" rx="5" fill="#F0F6FC"/>
<path d="M11.0625 19.4133C10.3542 18.4933 10 18.0325 10 16.6667C10 15.3 10.3542 14.8408 11.0625 13.92C12.4767 12.0833 14.8483 10 18.3333 10C21.8183 10 24.19 12.0833 25.6042 13.92C26.3125 14.8417 26.6667 15.3008 26.6667 16.6667C26.6667 18.0333 26.3125 18.4925 25.6042 19.4133C24.19 21.25 21.8183 23.3333 18.3333 23.3333C14.8483 23.3333 12.4767 21.25 11.0625 19.4133Z" stroke="#5560A0" stroke-width="1.5"/>
<path d="M20.833 16.6667C20.833 17.3298 20.5696 17.9657 20.1008 18.4345C19.6319 18.9034 18.996 19.1667 18.333 19.1667C17.67 19.1667 17.0341 18.9034 16.5652 18.4345C16.0964 17.9657 15.833 17.3298 15.833 16.6667C15.833 16.0037 16.0964 15.3678 16.5652 14.899C17.0341 14.4301 17.67 14.1667 18.333 14.1667C18.996 14.1667 19.6319 14.4301 20.1008 14.899C20.5696 15.3678 20.833 16.0037 20.833 16.6667Z" stroke="#5560A0" stroke-width="1.5"/>
</svg>

                        </td>
                      </tr>
                    )
                })
            }
           
            
          </tbody>
        </table>
      </div>
    
    </div>
  )
}

export default Salaries
