import React, { useContext, useEffect, useState } from 'react'
import ph_info from "../../img/ph_info.png"
import fluent_people from "../../img/carbon_scis-transparent-supply.png"
import Group from "../../img/Group.png"
import fluent_people2 from "../../img/fluent_people-team-48-regular (1).png"
import solar_cash from "../../img/solar_cash-out-linear.png"
import { useDispatch } from 'react-redux'
import { getDasBord } from '../../AccessSlice'
import { Worcqpital } from '../../categorySlice'
import { NameContext } from '../../NameContext'
function Homedata1() {
    const [homeData, sethomeData] = useState()
    const [data, setdata] = useState({})
    const { headDate, updateDate } = useContext(NameContext);

    const Dispatch = useDispatch();
    const getHomeData = async () => {
        Dispatch(getDasBord({})).then((res) => {
            console.log(res?.payload
                , "jhdfffffffffffffffff");
            sethomeData(res?.payload)
        })
    }

    useEffect(() => {
        Dispatch(Worcqpital({date:headDate})).then((response) => {
          console.log(response.payload,"om");
          setdata(response.payload)
        })
      }, [headDate])

    useEffect(() => {
        getHomeData()
    }, [])
    return (
        <div className='container-fluid' >
            {/* card 1 */}
            <div className='card ms-1' >
                <div className='card-header' style={{ background: "transparent" }} >
                    <div style={{ display: "flex" }} >
                        <p className='card-title' style={{ color: "##364050", fontWeight: "600" }} > Total Receivables</p>
                        <img className='ms-4' src={ph_info} alt='s.techooze' style={{ width: "25px", height: "25px" }} />
                    </div>
                </div>
                <div className='card-body' >
                    <div className='card_data1_body' >
                        <div className='card_data1_chaild'  >
                            <div style={{ display: "flex" }} >
                                <p style={{ color: "##ACACAC" }} >Received</p>
                                <img className='ms-4 mt-1 ' src={ph_info} alt='s.techooze' style={{ width: "15px", height: "15px" }} />


                            </div>
                            <div>
                                <h4 style={{ color: "#00A880" }} > ₹ {Number(data?.Payable).toFixed(0)} </h4>
                            </div>
                        </div>
                        <div className='card_data1_chaild' >
                            <div style={{ display: "flex" }} >
                                <p style={{ color: "##ACACAC" }} >Due</p>
                                <img className='ms-4 mt-1 ' src={ph_info} alt='s.techooze' style={{ width: "15px", height: "15px" }} />


                            </div>
                            <div>
                                <h4 style={{ color: "#CF8800" }} > ₹ {data?.inventry || 0} </h4>
                            </div>
                        </div>
                        {/* <div className='card_data1_chaild' style={{ border: "none" }} >
                            <div style={{ display: "flex" }} >
                                <p style={{ color: "##ACACAC" }} >Default</p>
                                <img className='ms-4 mt-1 ' src={ph_info} alt='s.techooze' style={{ width: "15px", height: "15px" }} />


                            </div>
                            <div>
                                <h4 style={{ color: "#EC0000" }} > ₹ 45,67,789 </h4>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            {/* card 2 */}
            <div className='card ms-1 mt-5 ' >
                <div className='card-header' style={{ background: "transparent" }} >
                    <div style={{ display: "flex" }} >
                        <p className='card-title' style={{ color: "##364050", fontWeight: "600" }} > Inventory</p>
                        <img className='ms-4' src={ph_info} alt='s.techooze' style={{ width: "25px", height: "25px" }} />
                    </div>
                </div>
                <div className='card-body' >
                    <div className='card_data1_body' >
                        <div className='card_data1_chaild'  >
                            <div style={{ display: "flex" }} >
                                <p style={{ color: "##ACACAC" }} >Categories</p>
                                <img className='ms-4 mt-1 ' src={ph_info} alt='s.techooze' style={{ width: "15px", height: "15px" }} />


                            </div>
                            <div>
                                <h4 style={{ color: "#00A880" }} > {homeData?.total_category} </h4>
                            </div>
                        </div>
                        <div className='card_data1_chaild' >
                            <div style={{ display: "flex" }} >
                                <p style={{ color: "##ACACAC" }} >Sub Categories</p>
                                <img className='ms-4 mt-1 ' src={ph_info} alt='s.techooze' style={{ width: "15px", height: "15px" }} />


                            </div>
                            <div>
                                <h4 style={{ color: "#CF8800" }} > {homeData?.total_sub_category} </h4>
                            </div>
                        </div>
                        <div className='card_data1_chaild' style={{ border: "none" }} >
                            <div style={{ display: "flex" }} >
                                <p style={{ color: "##ACACAC" }} >Nil</p>
                                <img className='ms-4 mt-1 ' src={ph_info} alt='s.techooze' style={{ width: "15px", height: "15px" }} />


                            </div>
                            <div>
                                <h4 style={{ color: "#EC0000" }} > 25 </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* row next */}
            <div className='row mt-5' >
                <div className='col-lg-6' >
                    <div className="card p-2">
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} >
                            <div style={{ display: "flex" }} >
                                <div  >
                                    <img className='ms-1 ' src={fluent_people} alt="s.techooze" style={{ height: "18px", width: "18px" }} />
                                </div>
                                <div>
                                    <p className='ms-1' style={{ color: "#364050" }} >Vendors</p>
                                </div>
                            </div>
                            <div>
                                {/* <button className='btn' style={{ backgroundColor: "#F4F8FC", color: "#5560A0", fontWeight: 500 }} > + Add </button> */}
                            </div>
                        </div>
                        <h2 className='ms-4' style={{ color: "#364050" }} >{homeData?.vendors}</h2>
                    </div>
                </div>
                <div className='col-lg-6' >
                    <div className="card p-2">
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} >
                            <div style={{ display: "flex" }} >
                                <div  >
                                    <img className='ms-1 ' src={Group} alt="s.techooze" style={{ height: "18px", width: "18px" }} />
                                </div>
                                <div>
                                    <p className='ms-1' style={{ color: "#364050" }} >Customers</p>
                                </div>
                            </div>
                            <div>
                                <button className='btn' style={{ backgroundColor: "#F4F8FC", color: "#5560A0", fontWeight: 500 }} > + Add </button>
                            </div>
                        </div>
                        <h2 className='ms-4' style={{ color: "#364050" }} >{homeData?.total_customers}</h2>
                    </div>
                </div>
                <div className='col-lg-6 mt-5 ' >
                    <div className="card p-2">
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} >
                            <div style={{ display: "flex" }} >
                                <div  >
                                    <img className='ms-1 ' src={fluent_people2} alt="s.techooze" style={{ height: "18px", width: "18px" }} />
                                </div>
                                <div>
                                    <p className='ms-1' style={{ color: "#364050" }} >Members</p>
                                </div>
                            </div>
                            <div>
                                <button className='btn' style={{ backgroundColor: "#F4F8FC", color: "#5560A0", fontWeight: 500 }} > + Add </button>
                            </div>
                        </div>
                        <h2 className='ms-4' style={{ color: "#364050" }} >{homeData?.total_users}</h2>
                    </div>
                </div>
                <div className='col-lg-6 mt-5' >
                    <div className="card p-2">
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} >
                            <div style={{ display: "flex" }} >
                                <div  >
                                    <img className='ms-1 ' src={solar_cash} alt="s.techooze" style={{ height: "18px", width: "18px" }} />
                                </div>
                                <div>
                                    <p className='ms-1' style={{ color: "#364050" }} >Payables</p>
                                </div>
                            </div>
                            <div>
                                <button className='btn' style={{ backgroundColor: "#F4F8FC", color: "#5560A0", fontWeight: 500 }} > + Add </button>
                            </div>
                        </div>
                        <h2 className='ms-4' style={{ color: "#C90000" }} >₹ 1,50,000</h2>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Homedata1
