import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { PdfGnret, Worcqpital } from '../../categorySlice'
import ReactToPrint, { useReactToPrint } from 'react-to-print'
import logo from "../../img/logo.jpg"


import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import { NameContext } from '../../NameContext'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};



// Modal.setAppElement('#yourAppElement');


function Statements() {

  
    const [data, setdata] = useState({})
    const [show, setshow] = useState("none")
    const dispatch = useDispatch()
    const componentRef = useRef()
    const componentRef2 = useRef()
    const componentRef3 = useRef()
    const componentRef4 = useRef()
const [pdfData, setpdfData] = useState();

const { headDate, updateDate } = useContext(NameContext);


    // model
    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);
  
    function openModal() {
      setIsOpen(true);
    }
  
    function afterOpenModal() {
      // references are now sync'd and can be accessed.
      subtitle.style.color = '#f00';
    }
  
    function closeModal() {
      setIsOpen(false);
    }
    // model

    const handlePrint =  useCallback(useReactToPrint({
    
      content: () => componentRef.current,
    }),[show]);
    const handlePrint2 =  useCallback(useReactToPrint({
    
      content: () => componentRef2.current,
    }),[show]);
    const handlePrint3 =  useCallback(useReactToPrint({
    
      content: () => componentRef3.current,
    }),[show]);
    const handlePrint4 =  useCallback(useReactToPrint({
    
      content: () => componentRef4.current,
    }),[show]);

    
    const handleChangeShow = async(e) => {
     await setshow("block")
      setTimeout(()=>{
        handlePrint()
        setshow("none")
      },1000)
    }
    const handleChangeShow2 = async(e) => {
     await setshow("block")
      setTimeout(()=>{
        handlePrint2()
        setshow("none")
      },1000)
    }
    const handleChangeShow3 = async(e) => {
     await setshow("block")
      setTimeout(()=>{
        handlePrint3()
        setshow("none")
      },1000)
    }
    const handleChangeShow4 = async(e) => {
     await setshow("block")
      setTimeout(()=>{
        handlePrint4()
        setshow("none")
      },1000)
    }


    const pdfGenret = async() =>{
      dispatch(PdfGnret({wca:data?.worcqpital_am+data?.receivables,Inventory: data?.inventry,Receivables: data?.receivables,Payable:data?.vande_price,Salaries:data?.salary})).then((res)=>{
        console.log(res.payload,"om 2");
        setpdfData(res?.payload)
      })
    }

    useEffect(() => {
        dispatch(Worcqpital({date:headDate})).then((response) => {
          console.log(response.payload,"om");
          setdata(response.payload)
        })

       

      }, [headDate])
  return (
    <div className="container py-5">
      <div className="row">
        <div className="col-lg-6" >
         <div style={{display:show}}>
         <div className='my-3' style={{display:"flex",justifyContent:"center"}}>
            <img src={logo} alt="logo" width="220px" />
          </div>
         </div>
            <div className="card shadow-lg p-4">
                <p style={{ 
                  color: "#364050",
                  fontSize: "24px",
                  fontWeight: 700,
                  lineHeight: "30px",
                  textAlign: "left",
                  marginBottom: "20px",
                  }}>
                  Working Capital </p>
                <div style={{ 
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  }}>
                  <p style={{ 
                    fontFamily: "Archivo", 
                    fontSize: "18px", 
                    fontWeight: 700, 
                    lineHeight: "19.58px", 
                    textAlign: "left",
                    color: "#364050",
                  }}>
                    Total Current Assets
                  </p>
                  <p style={{ 
                    fontFamily: "Archivo",
                    fontSize: "18px",
                    fontWeight: 700,
                    lineHeight: "19.58px",
                    textAlign: "left",
                    color: "#089158",
                  }}>
                    {data?.worcqpital_am+data?.receivables}
                  </p>
                </div>
                <div style={{ 
                  fontFamily: "Archivo", 
                  fontSize: "16px", 
                  fontWeight: 400, 
                  lineHeight: "17.41px", 
                  textAlign: "left",
                  color: "#364050",
                  display: "flex",
                  justifyContent:"space-between"
                  }
                  }
                  
                  >
                  <p>Inventory</p>
                  <p style={{ 
                    fontFamily: "Archivo",
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: "17.41px",
                    textAlign: "",
                    color: "#089158",
                  }}>
                    {data?.inventry}
                  </p>
                </div>
                <div style={{ 
                  fontFamily: "Archivo", 
                  fontSize: "16px", 
                  fontWeight: 400, 
                  lineHeight: "17.41px", 
                  textAlign: "left",
                  color: "#364050",
                  display: "flex",
                  justifyContent:"space-between"
                  }
                  }>
                  <p>Receivables</p>
                  <p style={{ 
                    fontFamily: "Archivo",
                    fontSize: "18px",
                    fontWeight: 700,
                    lineHeight: "19.58px",
                    textAlign: "left",
                    color: "#089158",
                  }}>
                    {/* {data?.receivables} */}
                    {data?.Payable}
                  </p>
                </div>
                <div style={{display:"flex",justifyContent:"end"}}>
                
                </div>

                <hr />
                {/* data 2 */}
                <div className="mt-0">
                <p 
                  style={{
                    fontFamily: "Archivo",
                    fontSize: "18px",
                    fontWeight: 700,
                    lineHeight: "19.58px",
                    textAlign: "left",
                  }}
                >
                  Total Current Liabilities
                </p>
                <div className='py-2'
                  style={{ 
                    fontFamily: "Archivo", 
                    fontSize: "16px", 
                    fontWeight: 400, 
                    lineHeight: "17.41px", 
                    textAlign: "left",
                    color: "#364050",
                    display: "flex",
                    justifyContent:"space-between"
                    }
                    }
                >
                  <span>Payable</span>
                  <span style={{ 
                    fontFamily: "Archivo",
                    fontSize: "18px",
                    fontWeight: 700,
                    lineHeight: "19.58px",
                    textAlign: "left",
                    color: "#DA0808",
                  }}>
                    {data?.vande_price}
                  </span>
                </div>
                <div 
                style={{ 
                  fontFamily: "Archivo", 
                  fontSize: "16px", 
                  fontWeight: 400, 
                  lineHeight: "17.41px", 
                  textAlign: "left",
                  color: "#364050",
                  display: "flex",
                  justifyContent:"space-between"
                  }
                  }
                >
                  <p>Salaries</p>
                  <p
                  style={{ 
                    fontFamily: "Archivo",
                    fontSize: "18px",
                    fontWeight: 700,
                    lineHeight: "19.58px",
                    textAlign: "left",
                    color: "#E10A0A",
                  }}
                  >
                    {data?.salary}
                  </p>
                </div>
            <div style={{display:"flex",justifyContent:"end"}}>
            <div className='px-3' style={{display:"flex",alignItems:"center", backgroundColor:"#F2F2F2",borderRadius:"10px"}} >
<div>
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.75 11.25V10H15V16.25H16.25V13.75H18.125V12.5H16.25V11.25H18.75ZM11.875 16.25H9.375V10H11.875C12.3721 10.0005 12.8488 10.1982 13.2003 10.5497C13.5518 10.9012 13.7495 11.3779 13.75 11.875V14.375C13.7495 14.8721 13.5518 15.3488 13.2003 15.7003C12.8488 16.0518 12.3721 16.2495 11.875 16.25ZM10.625 15H11.875C12.0407 14.9998 12.1996 14.9339 12.3168 14.8168C12.4339 14.6996 12.4998 14.5407 12.5 14.375V11.875C12.4998 11.7093 12.4339 11.5504 12.3168 11.4332C12.1996 11.3161 12.0407 11.2502 11.875 11.25H10.625V15ZM6.875 10H3.75V16.25H5V14.375H6.875C7.20637 14.3745 7.52402 14.2426 7.75834 14.0083C7.99265 13.774 8.1245 13.4564 8.125 13.125V11.25C8.12467 10.9186 7.99287 10.6008 7.75852 10.3665C7.52417 10.1321 7.20642 10.0003 6.875 10ZM5 13.125V11.25H6.875L6.87563 13.125H5Z" fill="black"/>
<path d="M13.75 8.74997V6.24997C13.7522 6.16783 13.7366 6.08619 13.7042 6.01066C13.6719 5.93513 13.6235 5.86752 13.5625 5.81247L9.1875 1.43747C9.13248 1.37643 9.06486 1.32806 8.98933 1.29569C8.9138 1.26332 8.83215 1.24772 8.75 1.24997H2.5C2.16878 1.25096 1.85142 1.38297 1.61721 1.61718C1.383 1.85138 1.25099 2.16875 1.25 2.49997V17.5C1.25 17.8315 1.3817 18.1494 1.61612 18.3839C1.85054 18.6183 2.16848 18.75 2.5 18.75H12.5V17.5H2.5V2.49997H7.5V6.24997C7.50099 6.58118 7.633 6.89855 7.86721 7.13276C8.10142 7.36697 8.41878 7.49898 8.75 7.49997H12.5V8.74997H13.75ZM8.75 6.24997V2.74997L12.25 6.24997H8.75Z" fill="black"/>
</svg>

</div>
{/* handleChangeShow */}
<button style={{ 
 backgroundColor:"transparent",

  
  border: "none",
  padding: "10px 20px",
  textAlign: "center",
  textDecoration: "none",
  borderRadius: "10px",
  cursor: "pointer",
  }} onClick={()=>{pdfGenret();openModal()}}>Download Report</button>
</div>
                </div>
            </div>


            {/* model */}
            <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"

      >
      <div style={{height:"100vh",overflowY:"scroll"}} >

      <div className='mb-4' style={{display:"flex",justifyContent:"space-between"}} >
       <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Minter Reclaimers Pvt Ltd.</h2>
       <button className='btn btn-outline-danger ' onClick={handleChangeShow}>Download Report</button>
       </div>
        <div ref={componentRef} dangerouslySetInnerHTML={{ __html: pdfData }} />
      </div>

        
      </Modal>
            {/* model */}
                {/* data 2 */}
            </div>
        </div>
        <div className="col-lg-6" ref={componentRef2}>
        <div style={{display:show}}>
         <div className='my-3' style={{display:"flex",justifyContent:"center"}}>
            <img src={logo} alt="logo" width="220px" />
          </div>
         </div>
            <div className="card shadow-lg p-4">
                <p style={{ 
                  color: "#364050",
                  fontSize: "24px",
                  fontWeight: 700,
                  lineHeight: "30px",
                  textAlign: "left",
                  marginBottom: "20px",
                  }}>
                  Income Statement
                </p>
                <p style={{ 
                  color: "#364050",
                  fontSize: "20px",
                  fontWeight: 600,
                  lineHeight: "21.76px",
                  textAlign: "left",
                  marginBottom: "20px",
                  }}>
                  <span>Total Income</span>
                  <span style={{ 
                    color: "#364050",
                    fontSize: "20px",
                    fontWeight: 600,
                    lineHeight: "21.76px",
                    textAlign: "left",
                    marginLeft: "10px",
                    }}>
                    {data?.worcqpital_am+data?.receivables}
                  </span>
                </p>
            <div style={{display:"flex",justifyContent:"end"}}>
                <div >

                  <button style={{ 
                    background: "#5F6AA6",
                    color: "white", 
                    border: "none",
                    padding: "10px 20px",
                    textAlign: "center",
                    textDecoration: "none",
                    borderRadius: "10px",
                    cursor: "pointer",
                    }} onClick={handleChangeShow2}>Download Report</button>
                </div>
                </div>
            </div>
        </div>
      </div>
      <div className="row mt-5">
        {/* <div className="col-lg-6" ref={componentRef3}>
        <div style={{display:show}}>
         <div className='my-3' style={{display:"flex",justifyContent:"center"}}>
            <img src={logo} alt="logo" width="220px" />
          </div>
         </div>
           
        </div> */}
        <div className="col-lg-6" ref={componentRef4}>
        <div style={{display:show}}>
         <div className='my-3' style={{display:"flex",justifyContent:"center"}}>
            <img src={logo} alt="logo" width="220px" />
          </div>
         </div>
            <div className="card shadow-lg p-4">
                <p style={{ 
                  color: "#364050",
                  fontSize: "24px",
                  fontWeight: 700,
                  lineHeight: "30px",
                  textAlign: "left",
                  marginBottom: "20px",
                  }}>
                  Expenditure
                </p>
                <p style={{ 
                  color: "#364050",
                  fontSize: "20px",
                  fontWeight: 600,
                  lineHeight: "21.76px",
                  textAlign: "left",
                  marginBottom: "20px",
                  }}>
                  <span>Total Expenditure</span>
                  <span style={{ 
                    color: "#364050",
                    fontSize: "20px",
                    fontWeight: 600,
                    lineHeight: "21.76px",
                    textAlign: "left",
                    marginLeft: "10px",
                    }}>
                    {data?.Payable}
                  </span>
                </p>
            <div style={{display:"flex",justifyContent:"end"}}>
                <div >
                  <button style={{ 
                    background: "#5F6AA6",
                    color: "white", 
                    border: "none",
                    padding: "10px 20px",
                    textAlign: "center",
                    textDecoration: "none",
                    borderRadius: "10px",
                    cursor: "pointer",
                    }}
                  onClick={handleChangeShow4}
                  >
                    Download Report
                  </button>
                </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Statements



