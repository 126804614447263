import axios from 'axios';
import { toast } from "react-toastify";

const axiosInstance = axios.create({
    // baseURL: 'https://erpapp.mintersreclaimers.in/minters', // Your API base URL
    baseURL: 'https://erpapp.mintersreclaimers.in/minters', // Your API base URL
    // baseURL: 'https://erpapi.mintersreclaimers.in/minters', // Your API base URL
    // baseURL: 'https://erpbtobapi.signaturecart.in/minters', // Your API base URL
    headers: {
        'Content-Type': 'application/json',
    },
});

// Add a request interceptor to attach the token and pathname to outgoing requests
axiosInstance.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token');
        const pathname = window.location.pathname; // Using window.location.pathname
        // alert(pathname)
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        config.headers['pathname'] = pathname; // Attaching pathname to headers
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

// Add a response interceptor to log the API response
axiosInstance.interceptors.response.use(
    response => {

        if (response.data && response.data.access == false && response.data.status == 403) {
            window.location.href = "/notaccess"
        }

        if (response.status === 401) {
            // console.log("xxxxxxxxxxxxxxxxxxxxxxxxxx");
            window.location.href = '/';
        }
        return response;
    },
    error => {

        window.location.href = '/';
        return Promise.reject(error);
    }
);

export default axiosInstance;
