import React from 'react'
import "./Alerts.css"
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { createCustomAlert, getcustomalerts } from '../../AccessSlice';
function Alerts() {
const [data, setdata] = useState([])
const [data2, setdata2] = useState([])
const [name, setname] = useState();

const [selectedValue, setSelectedValue] = useState('');
const [afterWebinarAlert, setAfterWebinarAlert] = useState('');
const [alertdata, setalertdata] = useState([])
const dispatch = useDispatch();

const handleSelectChange4 = (event) => {
  console.log('====================================');
  console.log(event.target.value);
  console.log('====================================');
  setAfterWebinarAlert (afterWebinarAlert + event.target.value);
};

const creatAlert = async()=>{
  dispatch(createCustomAlert({name,description:afterWebinarAlert}))
}

  const apiweb = async()=>{
    try {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
var requestOptions = {
  method: 'GET',
  headers: myHeaders,
  redirect: 'follow'
};

fetch("http://localhost:3000/kayapalat/admin/get/alerts", requestOptions)
  .then(response => response.json())
  .then(result => {console.log(result)
    setdata(result.data)
  })
  .catch(error => console.log('error', error));
    } catch (error) {
      console.log(error);
    }
  }


  const apicustom = async()=>{
    try {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
var requestOptions = {
  method: 'GET',
  headers: myHeaders,
  redirect: 'follow'
};

fetch("http://localhost:3000/kayapalat/admin/get/custom/alerts", requestOptions)
  .then(response => response.json())
  .then(result =>{ console.log(result)
    setdata2(result.data)
  })
  .catch(error => console.log('error', error));
    } catch (error) {
      console.log(error);
    }
  }

  const alertData = async(id)=>{
    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);

const requestOptions = {
  method: "GET",
  headers: myHeaders,
  redirect: "follow"
};

fetch(`http://localhost:3000/kayapalat/admin/get/alerts/id?id=${id}`, requestOptions)
  .then((response) => response.json())
  .then((result) => {console.log(result)
  
    setalertdata(result.data)
  })
  .catch((error) => console.error(error));
    } catch (error) {
      console.log(error);
    }
  }
  
  useEffect(()=>{
    // apiweb()
    // apicustom()
dispatch(getcustomalerts()).then((res)=>{
console.log(res.payload.data);
setdata2(res.payload.data)
})
  },[])
  return (
    <div className='card mt-4 cardsc p-3 ' >
      <div className="row">
        <div className="col-6">
          <div>
            <p className='alkjj' >Alerts</p>
          </div>
        </div>
        <div className="col-6">
          <div className='lslsls' >
            <button className='btn jdjdjdjdj' data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" >+Create Custom Alert</button>
          </div>
        </div>
      </div>
      {/*  */}
      <div className="maindg example">
      {
        data?.map((res)=>{
          return(
            <div className="card chaiff ps-4 py-4 ">
<div>
  <p className='lssl' > {res.name} </p>
  <p className='kdkdkdk' >Created : {new Date(res.created_at).toLocaleDateString()}</p>
  <button className='btn jjjaj p-3 ' onClick={()=>{alertData(res.id)}} data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight23" aria-controls="offcanvasRight"  > <span><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M10 6.875C9.1712 6.875 8.37634 7.20424 7.79029 7.79029C7.20424 8.37634 6.875 9.1712 6.875 10C6.875 10.8288 7.20424 11.6237 7.79029 12.2097C8.37634 12.7958 9.1712 13.125 10 13.125C10.8288 13.125 11.6237 12.7958 12.2097 12.2097C12.7958 11.6237 13.125 10.8288 13.125 10C13.125 9.1712 12.7958 8.37634 12.2097 7.79029C11.6237 7.20424 10.8288 6.875 10 6.875ZM8.125 10C8.125 9.50272 8.32254 9.02581 8.67417 8.67417C9.02581 8.32254 9.50272 8.125 10 8.125C10.4973 8.125 10.9742 8.32254 11.3258 8.67417C11.6775 9.02581 11.875 9.50272 11.875 10C11.875 10.4973 11.6775 10.9742 11.3258 11.3258C10.9742 11.6775 10.4973 11.875 10 11.875C9.50272 11.875 9.02581 11.6775 8.67417 11.3258C8.32254 10.9742 8.125 10.4973 8.125 10Z" fill="#CF8800"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.99999 2.70825C6.23832 2.70825 3.70416 4.96159 2.23332 6.87242L2.20749 6.90659C1.87416 7.33909 1.56832 7.73658 1.36082 8.20658C1.13832 8.71075 1.04166 9.25992 1.04166 9.99992C1.04166 10.7399 1.13832 11.2891 1.36082 11.7933C1.56916 12.2633 1.87499 12.6616 2.20749 13.0933L2.23416 13.1274C3.70416 15.0383 6.23832 17.2916 9.99999 17.2916C13.7617 17.2916 16.2958 15.0383 17.7667 13.1274L17.7925 13.0933C18.1258 12.6616 18.4317 12.2633 18.6392 11.7933C18.8617 11.2891 18.9583 10.7399 18.9583 9.99992C18.9583 9.25992 18.8617 8.71075 18.6392 8.20658C18.4308 7.73658 18.125 7.33909 17.7925 6.90659L17.7658 6.87242C16.2958 4.96159 13.7617 2.70825 9.99999 2.70825ZM3.22499 7.63492C4.58166 5.87075 6.79166 3.95825 9.99999 3.95825C13.2083 3.95825 15.4175 5.87075 16.775 7.63492C17.1417 8.10992 17.355 8.39325 17.4958 8.71159C17.6275 9.00992 17.7083 9.37408 17.7083 9.99992C17.7083 10.6258 17.6275 10.9899 17.4958 11.2883C17.355 11.6066 17.1408 11.8899 16.7758 12.3649C15.4167 14.1291 13.2083 16.0416 9.99999 16.0416C6.79166 16.0416 4.58249 14.1291 3.22499 12.3649C2.85832 11.8899 2.64499 11.6066 2.50416 11.2883C2.37249 10.9899 2.29166 10.6258 2.29166 9.99992C2.29166 9.37408 2.37249 9.00992 2.50416 8.71159C2.64499 8.39325 2.85999 8.10992 3.22499 7.63492Z" fill="#CF8800"/>
</svg>
</span> View Alert</button>
</div>
</div>
          )
        })
      }
      </div>

      {/*  */}

      <div>
        <p className='mmcmmc' >Custom Alerts</p>
      </div>

{/*  data2*/}
<div className="maindg example">
      {
        data2?.map((res)=>{
          return(
            <div className="card  ps-4 py-3 ">
<div>
  <p className='lssl' > {res.name} </p>
  <p className='kdkdkdk' >Created : {new Date(res.created_at).toLocaleDateString()}</p>
  <button className='btn jjjaj p-3 ' onClick={()=>{alertData(res.id)}} data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" > <span><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M10 6.875C9.1712 6.875 8.37634 7.20424 7.79029 7.79029C7.20424 8.37634 6.875 9.1712 6.875 10C6.875 10.8288 7.20424 11.6237 7.79029 12.2097C8.37634 12.7958 9.1712 13.125 10 13.125C10.8288 13.125 11.6237 12.7958 12.2097 12.2097C12.7958 11.6237 13.125 10.8288 13.125 10C13.125 9.1712 12.7958 8.37634 12.2097 7.79029C11.6237 7.20424 10.8288 6.875 10 6.875ZM8.125 10C8.125 9.50272 8.32254 9.02581 8.67417 8.67417C9.02581 8.32254 9.50272 8.125 10 8.125C10.4973 8.125 10.9742 8.32254 11.3258 8.67417C11.6775 9.02581 11.875 9.50272 11.875 10C11.875 10.4973 11.6775 10.9742 11.3258 11.3258C10.9742 11.6775 10.4973 11.875 10 11.875C9.50272 11.875 9.02581 11.6775 8.67417 11.3258C8.32254 10.9742 8.125 10.4973 8.125 10Z" fill="#CF8800"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.99999 2.70825C6.23832 2.70825 3.70416 4.96159 2.23332 6.87242L2.20749 6.90659C1.87416 7.33909 1.56832 7.73658 1.36082 8.20658C1.13832 8.71075 1.04166 9.25992 1.04166 9.99992C1.04166 10.7399 1.13832 11.2891 1.36082 11.7933C1.56916 12.2633 1.87499 12.6616 2.20749 13.0933L2.23416 13.1274C3.70416 15.0383 6.23832 17.2916 9.99999 17.2916C13.7617 17.2916 16.2958 15.0383 17.7667 13.1274L17.7925 13.0933C18.1258 12.6616 18.4317 12.2633 18.6392 11.7933C18.8617 11.2891 18.9583 10.7399 18.9583 9.99992C18.9583 9.25992 18.8617 8.71075 18.6392 8.20658C18.4308 7.73658 18.125 7.33909 17.7925 6.90659L17.7658 6.87242C16.2958 4.96159 13.7617 2.70825 9.99999 2.70825ZM3.22499 7.63492C4.58166 5.87075 6.79166 3.95825 9.99999 3.95825C13.2083 3.95825 15.4175 5.87075 16.775 7.63492C17.1417 8.10992 17.355 8.39325 17.4958 8.71159C17.6275 9.00992 17.7083 9.37408 17.7083 9.99992C17.7083 10.6258 17.6275 10.9899 17.4958 11.2883C17.355 11.6066 17.1408 11.8899 16.7758 12.3649C15.4167 14.1291 13.2083 16.0416 9.99999 16.0416C6.79166 16.0416 4.58249 14.1291 3.22499 12.3649C2.85832 11.8899 2.64499 11.6066 2.50416 11.2883C2.37249 10.9899 2.29166 10.6258 2.29166 9.99992C2.29166 9.37408 2.37249 9.00992 2.50416 8.71159C2.64499 8.39325 2.85999 8.10992 3.22499 7.63492Z" fill="#CF8800"/>
</svg>
</span> View Alert</button>
  <button className='btn jjjaj2 p-3 mt-4 '  > <span><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M10 6.875C9.1712 6.875 8.37634 7.20424 7.79029 7.79029C7.20424 8.37634 6.875 9.1712 6.875 10C6.875 10.8288 7.20424 11.6237 7.79029 12.2097C8.37634 12.7958 9.1712 13.125 10 13.125C10.8288 13.125 11.6237 12.7958 12.2097 12.2097C12.7958 11.6237 13.125 10.8288 13.125 10C13.125 9.1712 12.7958 8.37634 12.2097 7.79029C11.6237 7.20424 10.8288 6.875 10 6.875ZM8.125 10C8.125 9.50272 8.32254 9.02581 8.67417 8.67417C9.02581 8.32254 9.50272 8.125 10 8.125C10.4973 8.125 10.9742 8.32254 11.3258 8.67417C11.6775 9.02581 11.875 9.50272 11.875 10C11.875 10.4973 11.6775 10.9742 11.3258 11.3258C10.9742 11.6775 10.4973 11.875 10 11.875C9.50272 11.875 9.02581 11.6775 8.67417 11.3258C8.32254 10.9742 8.125 10.4973 8.125 10Z" fill="#CF8800"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.99999 2.70825C6.23832 2.70825 3.70416 4.96159 2.23332 6.87242L2.20749 6.90659C1.87416 7.33909 1.56832 7.73658 1.36082 8.20658C1.13832 8.71075 1.04166 9.25992 1.04166 9.99992C1.04166 10.7399 1.13832 11.2891 1.36082 11.7933C1.56916 12.2633 1.87499 12.6616 2.20749 13.0933L2.23416 13.1274C3.70416 15.0383 6.23832 17.2916 9.99999 17.2916C13.7617 17.2916 16.2958 15.0383 17.7667 13.1274L17.7925 13.0933C18.1258 12.6616 18.4317 12.2633 18.6392 11.7933C18.8617 11.2891 18.9583 10.7399 18.9583 9.99992C18.9583 9.25992 18.8617 8.71075 18.6392 8.20658C18.4308 7.73658 18.125 7.33909 17.7925 6.90659L17.7658 6.87242C16.2958 4.96159 13.7617 2.70825 9.99999 2.70825ZM3.22499 7.63492C4.58166 5.87075 6.79166 3.95825 9.99999 3.95825C13.2083 3.95825 15.4175 5.87075 16.775 7.63492C17.1417 8.10992 17.355 8.39325 17.4958 8.71159C17.6275 9.00992 17.7083 9.37408 17.7083 9.99992C17.7083 10.6258 17.6275 10.9899 17.4958 11.2883C17.355 11.6066 17.1408 11.8899 16.7758 12.3649C15.4167 14.1291 13.2083 16.0416 9.99999 16.0416C6.79166 16.0416 4.58249 14.1291 3.22499 12.3649C2.85832 11.8899 2.64499 11.6066 2.50416 11.2883C2.37249 10.9899 2.29166 10.6258 2.29166 9.99992C2.29166 9.37408 2.37249 9.00992 2.50416 8.71159C2.64499 8.39325 2.85999 8.10992 3.22499 7.63492Z" fill="#CF8800"/>
</svg>
</span> Schedule</button>
</div>
</div>
          )
        })
      }
      </div>
{/*  */}

<div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel" style={{width:"50vw"}} >
  <div class="offcanvas-header">
  <div>
    
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    <span className='ms-3' >Create Alert</span>
  </div>
  </div>
  <div class="offcanvas-body">
  <div class="mb-3">
  <input type="text" onChange={(e)=>{setname(e.target.value)}}  class="form-control" id="exampleFormControlInput1" placeholder="Title of Alert"/>
</div>  

<div className="mb-3">
  <p className='hdhdh' >Description</p>
</div>
  <div className="mb-3">
  <div style={{border:"1px solid rgba(234, 234, 235, 1)"}} >
     <div className='p-3' style={{display:"flex",borderBottom:"1px solid rgba(234, 234, 235, 1)"}} >
     <div><p className='hool mt-2 ' >Variables</p></div>
     <div className='ms-3' ><select className='kolll' onChange={handleSelectChange4} value={selectedValue}>
        <option >select Variable</option>
        <option value="{{customer_name}}">customer_name</option>
        <option value="{{event_date&time}}">event_date&time</option>
        <option value="{{phone_number}}">phone_number</option>
        <option value="{{email_address}}">email_address</option>
      </select>
</div>
     
     </div>
      <textarea
      placeholder='Send Message'
        rows={10}
        style={{ width: '100%',border:"none" }}
        value={afterWebinarAlert}
        onChange={(event) => setAfterWebinarAlert(event.target.value)}
      ></textarea>
    </div>
  </div>

  <div>
    <button className='btn jjjsjs p-3' onClick={creatAlert} >Create Alert</button>
  </div>
</div>
</div>

<div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight23" aria-labelledby="offcanvasRightLabel" style={{width:"40vw"}} >
  <div class="offcanvas-header">
   <div style={{display:"flex"}} >
   <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    <p className='ms-2' >View Alert</p>
   </div>
  </div>
  <div className="row ms-2" style={{width:"15vw"}} >
    <div className="col-6">
      <p className='gdggdhddhdh' >Details</p>
    </div>
    <div className="col-6">
      <p className='gdggdhddhdh' >Trigger Logs</p>
    </div>
  </div>
  <hr className='mx-2' />
  <div class="offcanvas-body">
  {
    alertdata?.map((res)=>{
      return(
        <>
        <div>
  <p className='sjsjsjsjj' >Title of Alert</p>
  <p className='sjjskksks' >{res.name}</p>
</div>
<hr className='mx-2' />
<div>
  <p className='sjshusss7' >Alert Template</p>
</div>
<div className="card p-2 ">
<p className='llwlwlwlwo' >{res.data}</p>

</div>
        </>
      )
    })
  }

  </div>
</div>
    </div>
  )
}

export default Alerts