import React from 'react'
import {Route,Routes} from "react-router-dom"
import Home from '../componet/Home/Home'
import Vendors from '../componet/Vender/Vendors'
import Customers from '../componet/Customers/Customers'
import Login from '../componet/Login/Login'
import Categories from '../componet/Categories/Categories'
import SubCategories from '../componet/Categories/SubCategories'
import Accounts from '../componet/Accounts/Accounts'
import TeamManagement from '../componet/TeamManagement/TeamManagement'
import Notaccess from '../componet/Notaccess/Notaccess'
import Alerts from '../componet/Alerts/Alerts'
import WasteManagement from '../componet/WasteManagement/WasteManagement'
import TaskPage from '../componet/TaskPage/TaskPage'
function AllRoute() {
  return (
   
      <Routes>
        <Route path='/home' element={<Home/>} />
        <Route path='/vendors' element={<Vendors/>} />
        <Route path='/customers' element={<Customers/>} />
        <Route path='/categories' element={<Categories/>} />
        <Route path='/subcategories' element={<SubCategories/>} />
        <Route path='/accounts' element={<Accounts/>} />
        <Route path='/team-management' element={<TeamManagement/>} />
        <Route path='/alerts' element={<Alerts/>} />
        <Route path='/waste-management' element={<WasteManagement/>} />
        <Route path='/notaccess' element={<Notaccess/>} />
      </Routes>
    
  )
}

export default AllRoute
