import { Box, Button, Grid, GridItem, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { getStock, getWesthome } from '../../userSlice';

function WasteManagement() {
    const [tab, settab] = useState(1);
    const [data, setdata] = useState([])
    const [data2, setdata2] = useState([])
    const [hiwestdata, sethiwestdata] = useState("")
    const [card1, setcard1] = useState("")
    const [card3, setcard3] = useState("")
    const [highestWaste, sethighestWaste] = useState();
    const [highestWastePrice, sethighestWastePrice] = useState();
    const [lowestWastePrice, setlowestWastePrice] = useState();
    const Dispatch = useDispatch();


    useEffect(() => {
        Dispatch(getStock({})).then((res) => {
            setdata(res?.payload?.data)

        })
        Dispatch(getWesthome({})).then((res) => {
            console.log("res data ",JSON.stringify(res?.payload?.categoryData2));

// waste_price highest waste qty
const data2 = res?.payload?.categoryData2

// low waste qty
let lowestWastePrice = { key: "", value: { waste_price: Infinity } };

for (const key in data2) {
    if (data2[key].waste_price < lowestWastePrice.value.waste_price) {
        lowestWastePrice = { key: key, value: data2[key] };
    }
}

console.log(lowestWastePrice,"stechooze");
setlowestWastePrice(lowestWastePrice)
// low waste qty



let highestWastePrice = { key: "", value: { waste_price: 0 } };

for (const key in data2) {
    if (data2[key].waste_price > highestWastePrice.value.waste_price) {
        highestWastePrice = { key: key, value: data2[key] };
    }
}

console.log(highestWastePrice,"sethighestWastePrice");
sethighestWastePrice(highestWastePrice)
// waste_price highest waste qty


// catregoryData highest waste qty
let highestWaste = { key: "", value: { waste_qty: 0 } };
const data = res?.payload?.categoryData
for (const key in data) {
    if (data[key].waste_qty > highestWaste.value.waste_qty) {
        highestWaste = { key: key, value: data[key] };
    }
}

sethighestWaste(highestWaste)
// console.log(highestWaste,"stechooze");
// catregoryData highest waste qty


            const vendorData = res?.payload?.vendorData


            
            let maxWasteVendor = null;
let maxWasteAvgPer = -Infinity;

for (const vendor in vendorData) {
    if (vendorData[vendor].ind_waste_avg_per > maxWasteAvgPer) {
        maxWasteAvgPer = vendorData[vendor].ind_waste_avg_per;
        maxWasteVendor = vendor;
    }
}

const result = maxWasteVendor ? { [maxWasteVendor]: vendorData[maxWasteVendor] } : {};

sethiwestdata(result)



// 2
let maxWasteQty2 = 0;
let maxWasteVendor2 = null;

for (const vendor in vendorData) {
    const wasteQty = vendorData[vendor].waste_qty;
    if (wasteQty > maxWasteQty2) {
        maxWasteQty2 = wasteQty;
        maxWasteVendor2 = vendor;
    }
}

// Filter data to include only the vendor with the highest waste_qty
const filteredData = {
    [maxWasteVendor2]: vendorData[maxWasteVendor2]
};

// console.log(filteredData,"filterdata");
setcard1(filteredData)
// 2
// 3
let minIndWasteAvgPer3 = Infinity;
let minIndWasteVendor3 = null;

for (const vendor in vendorData) {
    const indWasteAvgPer = vendorData[vendor].ind_waste_avg_per;
    if (indWasteAvgPer < minIndWasteAvgPer3) {
        minIndWasteAvgPer3 = indWasteAvgPer;
        minIndWasteVendor3 = vendor;
    }
}

// Filter data to include only the vendor with the lowest ind_waste_avg_per
const filteredData3 = {
    [minIndWasteVendor3]: vendorData[minIndWasteVendor3]
};
setcard3(filteredData3)
console.log(filteredData3,"filteredData3");
// 3
        })


       

    }, [Dispatch])
    return (
        <Box pb={10} bg={"#FFFFFF"} style={{ border: "1px solid #D9DBE6", boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)" }}>
            <Box px={30} mt={30} display={"flex"} justifyContent={"space-between"} >
                <Box display={"flex"} gap={50}>
                    <Box  >
                        <Box>
                            <Box onClick={() => { settab(1) }} cursor={"pointer"} display={"flex"} gap={10} >
                                <Box>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4.16671 9.99984C3.94569 9.99984 3.73373 10.0876 3.57745 10.2439C3.42117 10.4002 3.33337 10.6122 3.33337 10.8332V17.4998C3.33337 17.7208 3.42117 17.9328 3.57745 18.0891C3.73373 18.2454 3.94569 18.3332 4.16671 18.3332C4.38772 18.3332 4.59968 18.2454 4.75596 18.0891C4.91224 17.9328 5.00004 17.7208 5.00004 17.4998V10.8332C5.00004 10.6122 4.91224 10.4002 4.75596 10.2439C4.59968 10.0876 4.38772 9.99984 4.16671 9.99984ZM8.33337 1.6665C8.11236 1.6665 7.9004 1.7543 7.74412 1.91058C7.58784 2.06686 7.50004 2.27882 7.50004 2.49984V17.4998C7.50004 17.7208 7.58784 17.9328 7.74412 18.0891C7.9004 18.2454 8.11236 18.3332 8.33337 18.3332C8.55439 18.3332 8.76635 18.2454 8.92263 18.0891C9.07891 17.9328 9.16671 17.7208 9.16671 17.4998V2.49984C9.16671 2.27882 9.07891 2.06686 8.92263 1.91058C8.76635 1.7543 8.55439 1.6665 8.33337 1.6665ZM16.6667 13.3332C16.4457 13.3332 16.2337 13.421 16.0775 13.5772C15.9212 13.7335 15.8334 13.9455 15.8334 14.1665V17.4998C15.8334 17.7208 15.9212 17.9328 16.0775 18.0891C16.2337 18.2454 16.4457 18.3332 16.6667 18.3332C16.8877 18.3332 17.0997 18.2454 17.256 18.0891C17.4122 17.9328 17.5 17.7208 17.5 17.4998V14.1665C17.5 13.9455 17.4122 13.7335 17.256 13.5772C17.0997 13.421 16.8877 13.3332 16.6667 13.3332ZM12.5 6.6665C12.279 6.6665 12.0671 6.7543 11.9108 6.91058C11.7545 7.06686 11.6667 7.27882 11.6667 7.49984V17.4998C11.6667 17.7208 11.7545 17.9328 11.9108 18.0891C12.0671 18.2454 12.279 18.3332 12.5 18.3332C12.7211 18.3332 12.933 18.2454 13.0893 18.0891C13.2456 17.9328 13.3334 17.7208 13.3334 17.4998V7.49984C13.3334 7.27882 13.2456 7.06686 13.0893 6.91058C12.933 6.7543 12.7211 6.6665 12.5 6.6665Z" fill="#667286" />
                                    </svg>

                                </Box>
                                <Box>
                                    <Text>Analytics</Text>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box>
                        <Box>
                            <Box onClick={() => { settab(2) }} cursor={"pointer"} display={"flex"} gap={10} >
                                <Box>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M17.5 5.83333C17.5 7.675 14.1417 9.16667 10 9.16667C5.85833 9.16667 2.5 7.675 2.5 5.83333M17.5 5.83333C17.5 3.99167 14.1417 2.5 10 2.5C5.85833 2.5 2.5 3.99167 2.5 5.83333M17.5 5.83333V10M2.5 5.83333V10M17.5 10C17.5 11.8417 14.1417 13.3333 10 13.3333C5.85833 13.3333 2.5 11.8417 2.5 10M17.5 10V14.1667C17.5 16.0083 14.1417 17.5 10 17.5C5.85833 17.5 2.5 16.0083 2.5 14.1667V10" stroke="#364050" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>


                                </Box>
                                <Box>
                                    <Text>Records</Text>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box>
                    <button className='btn' >Refresh  <span><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.625 5.78126L14.8 4.82149C14.1005 4.08679 13.2588 3.5021 12.3262 3.103C11.3935 2.7039 10.3894 2.49874 9.375 2.50001C5.23438 2.50001 1.875 5.85938 1.875 10C1.875 14.1406 5.23438 17.5 9.375 17.5C10.9262 17.4999 12.4392 17.0191 13.7058 16.1237C14.9725 15.2283 15.9305 13.9623 16.448 12.5" stroke="#5560A0" stroke-miterlimit="10" stroke-linecap="round" />
                        <path d="M18.125 3.80555V8.12508C18.125 8.29084 18.0591 8.44981 17.9419 8.56702C17.8247 8.68423 17.6657 8.75008 17.5 8.75008H13.1804C12.6234 8.75008 12.3445 8.07704 12.7383 7.68329L17.0582 3.36336C17.4519 2.96883 18.125 3.24852 18.125 3.80555Z" fill="#5560A0" />
                    </svg>
                    </span> </button>
                </Box>


            </Box>

            <hr style={{ borderTop: "2px solid #D9DBE6" }} />
            {/* data 1 */}
            {
                tab == 1
                
                ? 
                
                <>
                <Box>
            <Box display='grid' gridTemplateColumns='repeat(3, 1fr)' gap='2rem'>
                <Box bg='white' borderRadius='md' p='2rem'>
                <div
      style={{
        width: "100%",
        position: "relative",
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        lineHeight: "normal",
        letterSpacing: "normal",
      }}
    >
      <section
        style={{
          flex: "1",
          borderRadius: "5px",
          backgroundColor: "#fff",
          border: "1px solid #d9dbe6",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          padding: "1.375rem 1.437rem 1.75rem",
          gap: "2.068rem",
          maxWidth: "100%",
          textAlign: "left",
          fontSize: "1rem",
          color: "#999db5",
          fontFamily: "Archivo",
        }}
      >
        <div
          style={{
            width: "25.338rem",
            height: "12.394rem",
            position: "relative",
            borderRadius: "5px",
            backgroundColor: "#fff",
            border: "1px solid #d9dbe6",
            boxSizing: "border-box",
            display: "none",
            maxWidth: "100%",
          }}
        />
        <div
          style={{
            width: "18.038rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: "0.556rem",
          }}
        >
          <a
            style={{
              textDecoration: "none",
              // width: "8.538rem",
              position: "relative",
              color: "inherit",
              display: "inline-block",
              zIndex: "1",
            }}
          >
            Highest Waste by
          </a>
          <h3
            style={{
              margin: "0",
              alignSelf: "stretch",
              position: "relative",
              fontSize: "1.5rem",
              fontWeight: "600",
              fontFamily: "inherit",
              color: "#364050",
              zIndex: "1",
            }}
          >{Object.keys(card1)[0]}</h3>
        </div>
        <div
          style={{
            width: "20.45rem",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: "2.206rem",
            maxWidth: "100%",
          }}
        >
          <div
            style={{
              width: "8.469rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "0.55rem",
            }}
          >
            <div
              style={{
                position: "relative",
                display: "inline-block",
                minWidth: "5.713rem",
                zIndex: "1",
              }}
            >
              Total Waste
            </div>
            <b
              style={{
                alignSelf: "stretch",
                position: "relative",
                fontSize: "1.5rem",
                fontWeight: "600",
                color: "#364050",
                zIndex: "1",
              }}
            >
              {JSON.stringify(card1[Object.keys(card1)[0]]?.waste_qty)} Units
            </b>
          </div>
          <div
            style={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "0.55rem",
            }}
          >
            <a
              style={{
                textDecoration: "none",
                alignSelf: "stretch",
                position: "relative",
                color: "inherit",
                zIndex: "1",
              }}
            >
              Waste by proportion
            </a>
            <a
              style={{
                textDecoration: "none",
                position: "relative",
                fontSize: "1.5rem",
                fontWeight: "600",
                color: "#e00404",
                display: "inline-block",
                minWidth: "3.856rem",
                zIndex: "1",
              }}
            >
              {Number.parseInt(JSON.stringify(card1[Object.keys(card1)[0]]?.ind_waste_avg_per)).toFixed(2)}%
            </a>
          </div>
        </div>
      </section>
    </div>
                </Box>
                <Box bg='white' borderRadius='md' p='2rem'>
                <div
      style={{
        width: "100%",
        position: "relative",
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        lineHeight: "normal",
        letterSpacing: "normal",
      }}
    >
      <section
        style={{
          flex: "1",
          borderRadius: "5px",
          backgroundColor: "#fff",
          border: "1px solid #d9dbe6",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          padding: "1.375rem 1.437rem 1.75rem",
          gap: "2.068rem",
          maxWidth: "100%",
          textAlign: "left",
          fontSize: "1rem",
          color: "#999db5",
          fontFamily: "Archivo",
        }}
      >
        <div
          style={{
            width: "25.338rem",
            height: "12.394rem",
            position: "relative",
            borderRadius: "5px",
            backgroundColor: "#fff",
            border: "1px solid #d9dbe6",
            boxSizing: "border-box",
            display: "none",
            maxWidth: "100%",
          }}
        />
        <div
          style={{
            width: "18.038rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: "0.556rem",
          }}
        >
          <a
            style={{
              textDecoration: "none",
              // width: "8.538rem",
              position: "relative",
              color: "inherit",
              display: "inline-block",
              zIndex: "1",
            }}
          >
            Highest Waste by
          </a>
          <h3
            style={{
              margin: "0",
              alignSelf: "stretch",
              position: "relative",
              fontSize: "1.5rem",
              fontWeight: "600",
              fontFamily: "inherit",
              color: "#364050",
              zIndex: "1",
            }}
          >{Object.keys(hiwestdata)[0]}</h3>
        </div>
        <div
          style={{
            width: "20.45rem",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: "2.206rem",
            maxWidth: "100%",
          }}
        >
          <div
            style={{
              width: "8.469rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "0.55rem",
            }}
          >
            <div
              style={{
                position: "relative",
                display: "inline-block",
                minWidth: "5.713rem",
                zIndex: "1",
              }}
            >
              Total Waste
            </div>
            <b
              style={{
                alignSelf: "stretch",
                position: "relative",
                fontSize: "1.5rem",
                fontWeight: "600",
                color: "#364050",
                zIndex: "1",
              }}
            >
              {JSON.stringify(hiwestdata[Object.keys(hiwestdata)[0]]?.waste_qty)} Units
            </b>
          </div>
          <div
            style={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "0.55rem",
            }}
          >
            <a
              style={{
                textDecoration: "none",
                alignSelf: "stretch",
                position: "relative",
                color: "inherit",
                zIndex: "1",
              }}
            >
              Waste by proportion
            </a>
            <a
              style={{
                textDecoration: "none",
                position: "relative",
                fontSize: "1.5rem",
                fontWeight: "600",
                color: "#e00404",
                display: "inline-block",
                minWidth: "3.856rem",
                zIndex: "1",
              }}
            >
              {Number.parseInt(JSON.stringify(hiwestdata[Object.keys(hiwestdata)[0]]?.ind_waste_avg_per)).toFixed(2)}%
            </a>
          </div>
        </div>
      </section>
    </div>
                </Box>
                <Box bg='white' borderRadius='md' p='2rem'>
                <div
      style={{
        width: "100%",
        position: "relative",
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        lineHeight: "normal",
        letterSpacing: "normal",
      }}
    >
      <section
        style={{
          flex: "1",
          borderRadius: "5px",
          backgroundColor: "#fff",
          border: "1px solid #d9dbe6",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          padding: "1.375rem 1.437rem 1.75rem",
          gap: "2.068rem",
          maxWidth: "100%",
          textAlign: "left",
          fontSize: "1rem",
          color: "#999db5",
          fontFamily: "Archivo",
        }}
      >
        <div
          style={{
            width: "25.338rem",
            height: "12.394rem",
            position: "relative",
            borderRadius: "5px",
            backgroundColor: "#fff",
            border: "1px solid #d9dbe6",
            boxSizing: "border-box",
            display: "none",
            maxWidth: "100%",
          }}
        />
        <div
          style={{
            width: "18.038rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: "0.556rem",
          }}
        >
          <a
            style={{
              textDecoration: "none",
              // width: "8.538rem",
              position: "relative",
              color: "inherit",
              display: "inline-block",
              zIndex: "1",
            }}
          >
            Highest Waste by
          </a>
          <h3
            style={{
              margin: "0",
              alignSelf: "stretch",
              position: "relative",
              fontSize: "1.5rem",
              fontWeight: "600",
              fontFamily: "inherit",
              color: "#364050",
              zIndex: "1",
            }}
          >{Object.keys(card3)[0]}</h3>
        </div>
        <div
          style={{
            width: "20.45rem",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: "2.206rem",
            maxWidth: "100%",
          }}
        >
          <div
            style={{
              width: "8.469rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "0.55rem",
            }}
          >
            <div
              style={{
                position: "relative",
                display: "inline-block",
                minWidth: "5.713rem",
                zIndex: "1",
              }}
            >
              Total Waste
            </div>
            <b
              style={{
                alignSelf: "stretch",
                position: "relative",
                fontSize: "1.5rem",
                fontWeight: "600",
                color: "#364050",
                zIndex: "1",
              }}
            >
              {JSON.stringify(card3[Object.keys(card3)[0]]?.waste_qty)} Units
            </b>
          </div>
          <div
            style={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "0.55rem",
            }}
          >
            <a
              style={{
                textDecoration: "none",
                alignSelf: "stretch",
                position: "relative",
                color: "inherit",
                zIndex: "1",
              }}
            >
              Waste by proportion
            </a>
            <a
              style={{
                textDecoration: "none",
                position: "relative",
                fontSize: "1.5rem",
                fontWeight: "600",
                color: "#089158",
                display: "inline-block",
                minWidth: "3.856rem",
                zIndex: "1",
              }}
            >
              {Number.parseInt(JSON.stringify(card3[Object.keys(card3)[0]]?.ind_waste_avg_per)).toFixed(2)}%
            </a>
          </div>
        </div>
      </section>
    </div>
                </Box>
                
               
                </Box>
                </Box>


                {/* data 2 */}
                <Box>
            <Box display='grid' gridTemplateColumns='repeat(3, 1fr)' gap='2rem'>
                <Box bg='white' borderRadius='md' p='2rem'>
                <div
      style={{
        width: "100%",
        position: "relative",
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        lineHeight: "normal",
        letterSpacing: "normal",
      }}
    >
      <section
        style={{
          flex: "1",
          borderRadius: "5px",
          backgroundColor: "#fff",
          border: "1px solid #d9dbe6",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          padding: "1.375rem 1.437rem 1.75rem",
          gap: "2.068rem",
          maxWidth: "100%",
          textAlign: "left",
          fontSize: "1rem",
          color: "#999db5",
          fontFamily: "Archivo",
        }}
      >
        <div
          style={{
            width: "25.338rem",
            height: "12.394rem",
            position: "relative",
            borderRadius: "5px",
            backgroundColor: "#fff",
            border: "1px solid #d9dbe6",
            boxSizing: "border-box",
            display: "none",
            maxWidth: "100%",
          }}
        />
        <div
          style={{
            width: "18.038rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: "0.556rem",
          }}
        >
          <a
            style={{
              textDecoration: "none",
              // width: "8.538rem",
              position: "relative",
              color: "inherit",
              display: "inline-block",
              zIndex: "1",
            }}
          >
            Highest Waste by Category
          </a>
          <h3
            style={{
              margin: "0",
              alignSelf: "stretch",
              position: "relative",
              fontSize: "1.5rem",
              fontWeight: "600",
              fontFamily: "inherit",
              color: "#364050",
              zIndex: "1",
            }}
          >{highestWaste?.key}</h3>
        </div>
        <div
          style={{
            width: "20.45rem",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: "2.206rem",
            maxWidth: "100%",
          }}
        >
          <div
            style={{
              width: "8.469rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "0.55rem",
            }}
          >
            <div
              style={{
                position: "relative",
                display: "inline-block",
                minWidth: "5.713rem",
                zIndex: "1",
              }}
            >
              Total Waste
            </div>
            <b
              style={{
                alignSelf: "stretch",
                position: "relative",
                fontSize: "1.5rem",
                fontWeight: "600",
                color: "#364050",
                zIndex: "1",
              }}
            >
              {highestWaste?.value?.waste_qty} Units
            </b>
          </div>
          <div
            style={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "0.55rem",
            }}
          >
            <a
              style={{
                textDecoration: "none",
                alignSelf: "stretch",
                position: "relative",
                color: "inherit",
                zIndex: "1",
              }}
            >
              Waste by proportion
            </a>
            <a
              style={{
                textDecoration: "none",
                position: "relative",
                fontSize: "1.5rem",
                fontWeight: "600",
                color: "#e00404",
                display: "inline-block",
                minWidth: "3.856rem",
                zIndex: "1",
              }}
            >
              {Number.parseInt(JSON.stringify(card1[Object.keys(card1)[0]]?.ind_waste_avg_per)).toFixed(2)}%
            </a>
          </div>
        </div>
      </section>
    </div>
                </Box>
                <Box bg='white' borderRadius='md' p='2rem'>
                <div
      style={{
        width: "100%",
        position: "relative",
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        lineHeight: "normal",
        letterSpacing: "normal",
      }}
    >
      <section
        style={{
          flex: "1",
          borderRadius: "5px",
          backgroundColor: "#fff",
          border: "1px solid #d9dbe6",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          padding: "1.375rem 1.437rem 1.75rem",
          gap: "2.068rem",
          maxWidth: "100%",
          textAlign: "left",
          fontSize: "1rem",
          color: "#999db5",
          fontFamily: "Archivo",
        }}
      >
        <div
          style={{
            width: "25.338rem",
            height: "12.394rem",
            position: "relative",
            borderRadius: "5px",
            backgroundColor: "#fff",
            border: "1px solid #d9dbe6",
            boxSizing: "border-box",
            display: "none",
            maxWidth: "100%",
          }}
        />
        <div
          style={{
            width: "18.038rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: "0.556rem",
          }}
        >
          <a
            style={{
              textDecoration: "none",
              // width: "8.538rem",
              position: "relative",
              color: "inherit",
              display: "inline-block",
              zIndex: "1",
            }}
          >
            Biggest Loss by Category
          </a>
          <h3
            style={{
              margin: "0",
              alignSelf: "stretch",
              position: "relative",
              fontSize: "1.5rem",
              fontWeight: "600",
              fontFamily: "inherit",
              color: "#364050",
              zIndex: "1",
            }}
          >{highestWastePrice?.key}</h3>
        </div>
        <div
          style={{
            width: "20.45rem",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: "2.206rem",
            maxWidth: "100%",
          }}
        >
          <div
            style={{
              width: "8.469rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "0.55rem",
            }}
          >
            <div
              style={{
                position: "relative",
                display: "inline-block",
                minWidth: "5.713rem",
                zIndex: "1",
              }}
            >
              Total Waste
            </div>
            <b
              style={{
                alignSelf: "stretch",
                position: "relative",
                fontSize: "1.5rem",
                fontWeight: "600",
                color: "#364050",
                zIndex: "1",
              }}
            >
              {highestWastePrice?.value?.waste_qty} Units
            </b>
          </div>
          <div
            style={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "0.55rem",
            }}
          >
            <a
              style={{
                textDecoration: "none",
                alignSelf: "stretch",
                position: "relative",
                color: "inherit",
                zIndex: "1",
              }}
            >
              Waste by proportion
            </a>
            <a
              style={{
                textDecoration: "none",
                position: "relative",
                fontSize: "1.5rem",
                fontWeight: "600",
                color: "#e00404",
                display: "inline-block",
                minWidth: "3.856rem",
                zIndex: "1",
              }}
            >
              ₹ {Number.parseInt(highestWastePrice?.value?.waste_price).toFixed(2)}
            </a>
          </div>
        </div>
      </section>
    </div>
                </Box>
                <Box bg='white' borderRadius='md' p='2rem'>
                <div
      style={{
        width: "100%",
        position: "relative",
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        lineHeight: "normal",
        letterSpacing: "normal",
      }}
    >
      <section
        style={{
          flex: "1",
          borderRadius: "5px",
          backgroundColor: "#fff",
          border: "1px solid #d9dbe6",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          padding: "1.375rem 1.437rem 1.75rem",
          gap: "2.068rem",
          maxWidth: "100%",
          textAlign: "left",
          fontSize: "1rem",
          color: "#999db5",
          fontFamily: "Archivo",
        }}
      >
        <div
          style={{
            width: "25.338rem",
            height: "12.394rem",
            position: "relative",
            borderRadius: "5px",
            backgroundColor: "#fff",
            border: "1px solid #d9dbe6",
            boxSizing: "border-box",
            display: "none",
            maxWidth: "100%",
          }}
        />
        <div
          style={{
            width: "18.038rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: "0.556rem",
          }}
        >
          <a
            style={{
              textDecoration: "none",
              // width: "8.538rem",
              position: "relative",
              color: "inherit",
              display: "inline-block",
              zIndex: "1",
            }}
          >
            Lowest Waste by Category
          </a>
          <h3
            style={{
              margin: "0",
              alignSelf: "stretch",
              position: "relative",
              fontSize: "1.5rem",
              fontWeight: "600",
              fontFamily: "inherit",
              color: "#364050",
              zIndex: "1",
            }}
          >{lowestWastePrice?.key}</h3>
        </div>
        <div
          style={{
            width: "20.45rem",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: "2.206rem",
            maxWidth: "100%",
          }}
        >
          <div
            style={{
              width: "8.469rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "0.55rem",
            }}
          >
            <div
              style={{
                position: "relative",
                display: "inline-block",
                minWidth: "5.713rem",
                zIndex: "1",
              }}
            >
              Total Waste
            </div>
            <b
              style={{
                alignSelf: "stretch",
                position: "relative",
                fontSize: "1.5rem",
                fontWeight: "600",
                color: "#364050",
                zIndex: "1",
              }}
            >
              {lowestWastePrice?.value?.waste_qty} Units
            </b>
          </div>
          <div
            style={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "0.55rem",
            }}
          >
            <a
              style={{
                textDecoration: "none",
                alignSelf: "stretch",
                position: "relative",
                color: "inherit",
                zIndex: "1",
              }}
            >
              Waste by proportion
            </a>
            <a
              style={{
                textDecoration: "none",
                position: "relative",
                fontSize: "1.5rem",
                fontWeight: "600",
                color: "#089158",
                display: "inline-block",
                minWidth: "3.856rem",
                zIndex: "1",
              }}
            >
              ₹ {Number.parseInt(lowestWastePrice?.value?.waste_price).toFixed(2)}
            </a>
          </div>
        </div>
      </section>
    </div>
                </Box>
                
               
                </Box>
                </Box>
                {/* data 2 */}
               
                </>
                : null
            }
            {/* data 1 */}
            {/* data 2 */}
            {
                tab == 2 ?

                    <Box px={30} mt={20} gap={4}>
                        <Grid borderBottom={"1px solid #D9DBE6"} p={5} templateColumns='repeat(7, 1fr)' gap={6} >

                            <GridItem style={{ fontFamily: 'Archivo', fontSize: '16px', fontWeight: '600', lineHeight: '17.41px', textAlign: 'left' }}>S.No</GridItem>
                            <GridItem style={{ fontFamily: 'Archivo', fontSize: '16px', fontWeight: '600', lineHeight: '17.41px', textAlign: 'left' }}  >Delivery Date</GridItem>
                            <GridItem style={{ fontFamily: 'Archivo', fontSize: '16px', fontWeight: '600', lineHeight: '17.41px', textAlign: 'left' }}  >Product Category</GridItem>
                            <GridItem style={{ fontFamily: 'Archivo', fontSize: '16px', fontWeight: '600', lineHeight: '17.41px', textAlign: 'left' }}  >Vendor</GridItem>
                            <GridItem style={{ fontFamily: 'Archivo', fontSize: '16px', fontWeight: '600', lineHeight: '17.41px', textAlign: 'left' }}  >Qty</GridItem>
                            <GridItem style={{ fontFamily: 'Archivo', fontSize: '16px', fontWeight: '600', lineHeight: '17.41px', textAlign: 'left' }}  >Waste</GridItem>
                            <GridItem style={{ fontFamily: 'Archivo', fontSize: '16px', fontWeight: '600', lineHeight: '17.41px', textAlign: 'left' }}  >Export Data</GridItem>

                        </Grid>
                        {
                            data?.map((res) => {
                                return (
                                    <Grid mt={5}  borderBottom={"1px solid #D9DBE6"} p={5} templateColumns='repeat(7, 1fr)' gap={6} >

                                        <GridItem>#{res?.id}</GridItem>
                                        <GridItem>{new Date(res.delivery_date_time).toDateString()}</GridItem>
                                        <GridItem>
                                            <p>{res.category}</p>
                                            <p> <span><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.66663 7.33333L12 10.6667M12 10.6667L8.66663 14M12 10.6667H6.79796C6.05263 10.6667 5.67929 10.6667 5.39463 10.5213C5.14375 10.3935 4.93979 10.1895 4.81196 9.93867C4.66663 9.65333 4.66663 9.28 4.66663 8.53333V2" stroke="#364050" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                            </span> <span>{res.sub_category}</span> </p>
                                        </GridItem>
                                        <GridItem>{res.vendor}</GridItem>
                                        <GridItem>{res.total_qty}</GridItem>
                                        <GridItem>{res.waste_qty}</GridItem>
                                        <GridItem></GridItem>

                                    </Grid>
                                )
                            })
                        }

                    </Box>
                    : null
            }
            {/* data 2 */}
        </Box>
    )
}

export default WasteManagement