import React from 'react';
import './AccessMessage.css'; // Import your CSS file

const AccessMessage = ({ message }) => {
  return (
    <div className="access-message">
      <div className="message-box">
        <p>{message}</p>
      </div>
    </div>
  );
};

export default AccessMessage;
