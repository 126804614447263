import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import axiosInstance from './axiosInstance';

export const login = createAsyncThunk(
  "login",
  async ({ email, password }, { rejectWithValue }) => {
    let url = `user/login`;
    try {
      const response = await axiosInstance.post(
        url,
        {
          email,
          password,
        }
      ); // replace with your API endpoint and data
      console.log(response.data);
      const token = response.data.token; // Assuming your token is returned in the response
      localStorage.setItem("token", token);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.error);
    }
  }
);
export const addvendors = createAsyncThunk(
  "addvendors",
  async ({ name, email, number, gst_number,
    b_address_line_1,
    b_area,
    b_city,
    b_zip_code,
    b_state,
    s_address_line_1,
    s_area,
    s_city,
    s_zip_code,
    s_state
  }, { rejectWithValue }) => {
    let url = `user/create/customer`;
    const token = localStorage.getItem("token");
    console.log(token);
    try {
      const response = await axiosInstance.post(
        url,
        {
          name, email, number, gst_number,
          b_address_line_1,
          b_area,
          b_city,
          b_zip_code,
          b_state,
          s_address_line_1,
          s_area,
          s_city,
          s_zip_code,
          s_state,
        }
      ); // replace with your API endpoint and data
      console.log(response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.error);
    }
  }
);
export const addvendors2 = createAsyncThunk(
  "addvendors2",
  async ({ name, email, number, gst_number
  }, { rejectWithValue }) => {
    let url = `user/add/Vendors`;
    const token = localStorage.getItem("token");
    console.log(token);
    try {
      const response = await axiosInstance.post(
        url,
        {
          name, email, number, gst_number
        }
      ); // replace with your API endpoint and data
      console.log(response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.error);
    }
  }
);
export const Addcustomer = createAsyncThunk(
  "addcustomer",
  async ({ name, email, number, gst_number }, { rejectWithValue }) => {
    let url = `user/create/customer`;
    const token = localStorage.getItem("token");
    console.log(token);
    try {
      const response = await axiosInstance.post(
        url,
        {
          name, email, number, gst_number
        }
      ); // replace with your API endpoint and data
      console.log(response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.error);
    }
  }
);
export const getvendors = createAsyncThunk(
  "getvendors",
  async ({ date}, { rejectWithValue }) => {
    let url = `/user/get/vendors?date=${date}`;
    const token = localStorage.getItem("token");
    console.log(token);
    try {
      const response = await axiosInstance.get(
        url
      ); // replace with your API endpoint and data
      console.log(response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.error);
    }
  }
);
export const getUserbyid = createAsyncThunk(
  "getUserbyid",
  async ({ }, { rejectWithValue }) => {
    let url = `/getUserbyid`;
    const token = localStorage.getItem("token");
    console.log(token);
    try {
      const response = await axiosInstance.get(
        url
      ); // replace with your API endpoint and data
      console.log(response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.error);
    }
  }
);

export const getvendorsid = createAsyncThunk(
  "getvendorsid",
  async ({ id }, { rejectWithValue }) => {

    let url = `/user/get/vendors/id`;
    const token = localStorage.getItem("token");
    console.log(token);
    try {
      const response = await axiosInstance.get(
        url
      ); // replace with your API endpoint and data
      console.log(response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.error);
    }
  }
);
export const getSalery = createAsyncThunk(
  "getSalery",
  async ({  }, { rejectWithValue }) => {

    let url = `/admin/paysalary/get`;
    const token = localStorage.getItem("token");
    console.log(token);
    try {
      const response = await axiosInstance.get(
        url
      ); // replace with your API endpoint and data
      console.log(response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.error);
    }
  }
);
export const getWesthome = createAsyncThunk(
  "getWesthome",
  async ({  }, { rejectWithValue }) => {

    let url = `/user/get/home/west`;
    const token = localStorage.getItem("token");
    console.log(token);
    try {
      const response = await axiosInstance.get(
        url
      ); // replace with your API endpoint and data
      console.log(response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.error);
    }
  }
);

export const getStock = createAsyncThunk(
  "getStock",
  async ({  }, { rejectWithValue }) => {

    let url = `/user/get/stock`;
    const token = localStorage.getItem("token");
    console.log(token);
    try {
      const response = await axiosInstance.get(
        url
      ); // replace with your API endpoint and data
      console.log(response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.error);
    }
  }
);

export const editvendors = createAsyncThunk(
  "editvendors",
  async ({ id, name, email, number, gst_number }, { rejectWithValue }) => {
    let url = `user/edit/Vendors`;
    const token = localStorage.getItem("token");
    console.log(token);
    try {
      const response = await axiosInstance.put(
        url,
        {
          id, name, email, number, gst_number
        }
      ); // replace with your API endpoint and data
      console.log(response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.error);
    }
  }
);
export const deletevendors = createAsyncThunk(
  "deletevendors",
  async ({ id }, { rejectWithValue }) => {
    let url = `user/delete/vendors`;
    const token = localStorage.getItem("token");
    console.log(token);
    const config = {
      headers: {
        "Content-Type": "application/json", //bina config ke cookie nhi set honi dhyan rakhna
        "Authorization": `Bearer ${token}`
      },
    };
    try {
      console.log(config);
      const response = await axiosInstance.post(
        url,
        {
          id
        }
      ); // replace with your API endpoint and data
      console.log(response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.error);
    }
  }
);



export const salary = createAsyncThunk(
  "salary",
  async ({employee, amount, payment_type, date, remarks, img}, { rejectWithValue }) => {
    let url = `/admin/paysalary/create`;
    const token = localStorage.getItem("token");
    console.log(token);

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    const formdata = new FormData();
    formdata.append("employee", employee);
    formdata.append("amount", amount);
    formdata.append("payment_type", payment_type);
    formdata.append("date", date);
    formdata.append("remarks", remarks);
    formdata.append("img", img);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow"
    };

    try {
      const response = await fetch(url, requestOptions);
      console.log(response.status);
      return response.json();
    } catch (error) {
      return rejectWithValue(error.response.data.error);
    }
  }
);





export const userSlice = createSlice({
  name: "user",
  initialState: {
    isLoading: false,
    isAuthenticated: false,
    error: null,
    userData: null,
    getVendors: [],
    isUpdated: false,
    activeChats: [],
    singervendedata: [],
    chatId: null,
    myOneChatWithOther: null,
    myMobileNo: null,
    myChatData: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isAuthenticated = true;
        state.userData = action.payload.user;
      })
      .addCase(login.rejected, (state, action) => {
        state.error = action.payload;
        state.isAuthenticated = false;
        state.isLoading = false;
        state.userData = null;
      })
      .addCase(addvendors.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addvendors.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isAuthenticated = true;
        state.userData = action.payload.user;
        toast.success(action.payload.message)
      })
      .addCase(addvendors.rejected, (state, action) => {
        state.error = action.payload;
        state.isAuthenticated = false;
        state.isLoading = false;
        state.userData = null;
        toast.error(action.payload)

      })
      .addCase(getvendors.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getvendors.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isAuthenticated = true;
        state.getVendors = action.payload;
      })
      .addCase(getvendors.rejected, (state, action) => {
        state.error = action.payload;
        state.isAuthenticated = false;
        state.isLoading = false;
        state.userData = null;

      })
      .addCase(getvendorsid.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getvendorsid.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isAuthenticated = true;
        state.singervendedata = action.payload;// Store fetched data in singervendedata
      })
      .addCase(getvendorsid.rejected, (state, action) => {
        state.error = action.payload;
        state.isAuthenticated = false;
        state.isLoading = false;
        state.singervendedata = []; // Clear singervendedata on rejection
      })
      .addCase(editvendors.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editvendors.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isAuthenticated = true;
        state.singervendedata = action.payload;
        toast.success(action.payload.message)
      })
      .addCase(editvendors.rejected, (state, action) => {
        state.error = action.payload;
        state.isAuthenticated = false;
        state.isLoading = false;
        state.singervendedata = []; // Clear singervendedata on rejection
        toast.error(action.payload)
      })
      .addCase(deletevendors.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deletevendors.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isAuthenticated = true;
        state.singervendedata = action.payload;
        toast.success(action.payload.message)
      })
      .addCase(deletevendors.rejected, (state, action) => {
        state.error = action.payload;
        state.isAuthenticated = false;
        state.isLoading = false;
        state.singervendedata = []; // Clear singervendedata on rejection
        toast.error(action.payload)
      })
      .addCase(Addcustomer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(Addcustomer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isAuthenticated = true;
        state.singervendedata = action.payload;
        toast.success(action.payload.message)
      })
      .addCase(Addcustomer.rejected, (state, action) => {
        state.error = action.payload;
        state.isAuthenticated = false;
        state.isLoading = false;
        state.singervendedata = []; // Clear singervendedata on rejection
        toast.error(action.payload)
      })
      .addCase(addvendors2.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addvendors2.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isAuthenticated = true;
        state.singervendedata = action.payload;
        toast.success(action.payload.message)
      })
      .addCase(addvendors2.rejected, (state, action) => {
        state.error = action.payload;
        state.isAuthenticated = false;
        state.isLoading = false;
        state.singervendedata = []; // Clear singervendedata on rejection
        toast.error(action.payload)
      })
      .addCase(getUserbyid.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserbyid.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isAuthenticated = true;
        state.singervendedata = action.payload;
        toast.success(action.payload.message)
      })
      .addCase(getUserbyid.rejected, (state, action) => {
        state.error = action.payload;
        state.isAuthenticated = false;
        state.isLoading = false;
        state.singervendedata = []; // Clear singervendedata on rejection
        toast.error(action.payload)
      })
      .addCase(salary.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(salary.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isAuthenticated = true;
        state.singervendedata = action.payload;
        toast.success(action.payload.message)
      })
      .addCase(salary.rejected, (state, action) => {
        state.error = action.payload;
        state.isAuthenticated = false;
        state.isLoading = false;
        state.singervendedata = []; // Clear singervendedata on rejection
        toast.error(action.payload)
      })
      .addCase(getSalery.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSalery.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isAuthenticated = true;
        state.singervendedata = action.payload;
        toast.success(action.payload.message)
      })
      .addCase(getSalery.rejected, (state, action) => {
        state.error = action.payload;
        state.isAuthenticated = false;
        state.isLoading = false;
        state.singervendedata = []; // Clear singervendedata on rejection
        toast.error(action.payload)
      })
      .addCase(getStock.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getStock.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isAuthenticated = true;
        state.singervendedata = action.payload;
        toast.success(action.payload.message)
      })
      .addCase(getStock.rejected, (state, action) => {
        state.error = action.payload;
        state.isAuthenticated = false;
        state.isLoading = false;
        state.singervendedata = []; // Clear singervendedata on rejection
        toast.error(action.payload)
      })
      .addCase(getWesthome.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getWesthome.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isAuthenticated = true;
        state.singervendedata = action.payload;
        toast.success(action.payload.message)
      })
      .addCase(getWesthome.rejected, (state, action) => {
        state.error = action.payload;
        state.isAuthenticated = false;
        state.isLoading = false;
        state.singervendedata = []; // Clear singervendedata on rejection
        toast.error(action.payload)
      });
  },
});

export default userSlice.reducer;
