import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import axiosInstance from './axiosInstance';


export const categoryAdd = createAsyncThunk(
  "categoryadd",
  async ({ name }, { rejectWithValue }) => {
    let url = `user/category/add`;
    try {
      const response = await axiosInstance.post(
        url,
        {
          name
        }
      ); // replace with your API endpoint and data
      console.log(response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.error);
    }
  }
);
export const PdfGnret = createAsyncThunk(
  "PdfGnret",
  async ({ wca,Inventory,Receivables,Payable,Salaries }, { rejectWithValue }) => {
    let url = `admin/pdf/send`;
    try {
      const response = await axiosInstance.post(
        url,
        {
          wca,
          Inventory,
          Receivables,
          Payable,
          Salaries
        }
      ); // replace with your API endpoint and data
      console.log(response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.error);
    }
  }
);
export const addRoute = createAsyncThunk(
  "addroute",
  async ({ route, user_id }, { rejectWithValue }) => {
    let url = `user/addaccess`;
    try {
      const response = await axiosInstance.post(
        url,
        {
          route, user_id
        }
      ); // replace with your API endpoint and data
      console.log(response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.error);
    }
  }
);
export const subcategoryAdd = createAsyncThunk(
  "categoryadd",
  async ({ name }, { rejectWithValue }) => {
    let url = `user/sub/category/add`;
    try {
      const response = await axiosInstance.post(
        url,
        {
          name
        }
      ); // replace with your API endpoint and data
      console.log(response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.error);
    }
  }
);
export const categoryGet = createAsyncThunk(
  "categoryget",
  async ({ rejectWithValue }) => {
    let url = `user/category/get/all`;
    try {
      const response = await axiosInstance.get(
        url
      ); // replace with your API endpoint and data
      console.log(response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.error);
    }
  }
);
export const subcategoryGet = createAsyncThunk(
  "subcategoryget",
  async ({ id }, { rejectWithValue }) => {
    let url = `user/sub/category/get`;
    try {
      const response = await axiosInstance.post(
        url,
        { id }
      ); // replace with your API endpoint and data
      console.log(response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.error);
    }
  }
);
export const subcategoryadd = createAsyncThunk(
  "subcategoryadd",
  async ({ name, category_id, si_unit }, { rejectWithValue }) => {
    let url = `user/sub/category/add`;
    try {
      const response = await axiosInstance.post(
        url,
        { name, category_id, si_unit }
      ); // replace with your API endpoint and data
      console.log(response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.error);
    }
  }
);
export const stockadd = createAsyncThunk(
  "stockadd",
  async ({ name, category_id, category, vendor, deliverydate, deliverytime, payoutdate, payouttime, enter_unit_price, enter_qty, notes,waste_qty,vendor_name,sub_category_name,category_name }, { rejectWithValue }) => {
    let url = `user/add/stock`;
    try {
      const response = await axiosInstance.post(
        url,
        { name, category_id, category, vendor, deliverydate, deliverytime, payoutdate, payouttime, enter_unit_price, enter_qty, notes,waste_qty,vendor_name,sub_category_name,category_name }
      ); // replace with your API endpoint and data
      console.log(response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.error);
    }
  }
);

export const getUser = createAsyncThunk(
  "getuser",
  async ({ rejectWithValue }) => {
    let url = `user/get/invoice/data`;
    try {
      const response = await axiosInstance.get(
        url
      ); // replace with your API endpoint and data
      console.log(response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.error);
    }
  }
);

export const createInvoice = createAsyncThunk(
  "createInvoice",
  async ({ in_number, customer, gstin, billing_address_line_1, billing_area, billing_zip_code, billing_city, billing_state, phone_number, email, shipping_address_line_1, shipping_area, shipping_zip_code, shipping_city, shipping_state,allcategery, total_rate, issue_date, total_amount, gst_amount, expiry_date, grand_total, customer_notes, terms_conditions }, { rejectWithValue }) => {
    let url = `user/create/invoice`;
    try {
      const response = await axiosInstance.post(
        url,
        { in_number, customer, gstin, billing_address_line_1, billing_area, billing_zip_code, billing_city, billing_state, phone_number, email, shipping_address_line_1, shipping_area, shipping_zip_code, shipping_city, shipping_state,allcategery, total_rate, issue_date, total_amount, gst_amount, expiry_date, grand_total, customer_notes, terms_conditions }
      ); // replace with your API endpoint and data
      console.log(response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.error);
    }
  }
);
export const createPaymentCycle = createAsyncThunk(
  "createPaymentCycle",
  async ({ data, total_amount, total_gst, grand_total, invoices_id }, { rejectWithValue }) => {
    let url = `user/payments/cycles/create`;
    try {
      const response = await axiosInstance.post(
        url,
        { data, total_amount, total_gst, grand_total, invoices_id }
      ); // replace with your API endpoint and data
      console.log(response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.error);
    }
  }
);
// export const Receivablesget = createAsyncThunk(
//   "Receivables",
//   async ({},{ rejectWithValue }) => {
//     let url = `receivables/get`;
//     const token = localStorage.getItem("token");
//     const config = {
//       headers: {
//         "Content-Type": "application/json", //bina config ke cookie nhi set honi dhyan rakhna
//         "Authorization": `Bearer ${token}`
//       },
//     };
//     try {
//       const response = await axiosInstance.get(
//         url,
//         {},
//         config
//       ); // replace with your API endpoint and data
//       console.log(response.data);
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.response.data.error);
//     }
//   }
// );

export const Receivablesget = createAsyncThunk(
  "receivablesget",
  async ({date},{ rejectWithValue }) => {
    let url = `receivables/get?date=${date}`;
    try {
      const response = await axiosInstance.get(
        url
      ); // replace with your API endpoint and data
      console.log(response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.error);
    }
  }
);
export const Payables = createAsyncThunk(
  "Payables",
  async ({ rejectWithValue }) => {
    let url = `payables/get`;
    try {
      const response = await axiosInstance.get(
        url
      ); // replace with your API endpoint and data
      console.log(response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.error);
    }
  }
);
export const Invoices = createAsyncThunk(
  "Invoices",
  async ({ rejectWithValue }) => {
    let url = `invoices/get`;
    try {
      const response = await axiosInstance.get(
        url
      ); // replace with your API endpoint and data
      console.log(response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.error);
    }
  }
);
export const Defaulters = createAsyncThunk(
  "Defaulters",
  async ({ rejectWithValue }) => {
    let url = `defaulters/get`;
    try {
      const response = await axiosInstance.get(
        url
      ); // replace with your API endpoint and data
      console.log(response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.error);
    }
  }
);
export const Worcqpital = createAsyncThunk(
  "worcqpital",
  async ({date},{ rejectWithValue }) => {
    let url = `wor/cqpital/get?date=${date}`;
    try {
      const response = await axiosInstance.get(
        url
      ); // replace with your API endpoint and data
      console.log(response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.error);
    }
  }
);
export const Usergetall = createAsyncThunk(
  "Usergetall",
  async ({ rejectWithValue }) => {
    let url = `user/get/all`;
    try {
      const response = await axiosInstance.get(
        url
      ); // replace with your API endpoint and data
      console.log(response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.error);
    }
  }
);

export const Useradd = createAsyncThunk(
  "Useradd",
  async ({ name, email, ph_number, password }, { rejectWithValue }) => {
    let url = `user/add`;
    try {
      console.log(name, email, ph_number, password, "kko")
      const response = await axiosInstance.post(
        url,
        { name, email, ph_number, password }
      ); // replace with your API endpoint and data
      console.log(response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.error);
    }
  }
);

export const categorySlice = createSlice({
  name: "user",
  initialState: {
    isLoading: false,
    isAuthenticated: false,
    error: null,
    userData: null,
    isUpdated: false,
    category_data: [],
    chatId: null,
    myOneChatWithOther: null,
    myMobileNo: null,
    myChatData: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(categoryAdd.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(categoryAdd.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isAuthenticated = true;
        state.userData = action.payload.user;
        toast.success(action.payload.message)
      })
      .addCase(categoryAdd.rejected, (state, action) => {
        state.error = action.payload;
        state.isAuthenticated = false;
        state.isLoading = false;
        state.userData = null;
        toast.error(action.payload)
      })
      .addCase(categoryGet.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(categoryGet.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isAuthenticated = true;
        state.category_data = action.payload;

      })
      .addCase(categoryGet.rejected, (state, action) => {
        state.error = action.payload;
        state.isAuthenticated = false;
        state.isLoading = false;
        state.category_data = null;

      })
      .addCase(subcategoryGet.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(subcategoryGet.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isAuthenticated = true;
        state.category_data = action.payload;

      })
      .addCase(subcategoryGet.rejected, (state, action) => {
        state.error = action.payload;
        state.isAuthenticated = false;
        state.isLoading = false;
        state.category_data = null;

      })
      .addCase(subcategoryadd.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(subcategoryadd.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isAuthenticated = true;
        state.category_data = action.payload;
        toast.success(action.payload.message)

      })
      .addCase(subcategoryadd.rejected, (state, action) => {
        state.error = action.payload;
        state.isAuthenticated = false;
        state.isLoading = false;
        state.category_data = null;
        toast.error(action.payload)
      })
      .addCase(stockadd.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(stockadd.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isAuthenticated = true;
        state.userData = action.payload;
        toast.success(action.payload.message)

      })
      .addCase(stockadd.rejected, (state, action) => {
        state.error = action.payload;
        state.isAuthenticated = false;
        state.isLoading = false;
        state.userData = null;
        toast.error(action.payload)
      })
      .addCase(getUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isAuthenticated = true;
        state.userData = action.payload;
        toast.success(action.payload.message)

      })
      .addCase(getUser.rejected, (state, action) => {
        state.error = action.payload;
        state.isAuthenticated = false;
        state.isLoading = false;
        state.userData = null;
        toast.error(action.payload)
      })
      .addCase(createInvoice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createInvoice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isAuthenticated = true;
        state.userData = action.payload;
        toast.success(action.payload.message)

      })
      .addCase(createInvoice.rejected, (state, action) => {
        state.error = action.payload;
        state.isAuthenticated = false;
        state.isLoading = false;
        state.userData = null;
        toast.error(action.payload)
      })
      .addCase(createPaymentCycle.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createPaymentCycle.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isAuthenticated = true;
        state.userData = action.payload;
        toast.success(action.payload.message)

      })
      .addCase(createPaymentCycle.rejected, (state, action) => {
        state.error = action.payload;
        state.isAuthenticated = false;
        state.isLoading = false;
        state.userData = null;
        toast.error(action.payload)
      })
      .addCase(Receivablesget.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(Receivablesget.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isAuthenticated = true;
        state.userData = action.payload;
        toast.success(action.payload.message)

      })
      .addCase(Receivablesget.rejected, (state, action) => {
        state.error = action.payload;
        state.isAuthenticated = false;
        state.isLoading = false;
        state.userData = null;
        toast.error(action.payload)
      })
      .addCase(Payables.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(Payables.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isAuthenticated = true;
        state.userData = action.payload;
        toast.success(action.payload.message)

      })
      .addCase(Payables.rejected, (state, action) => {
        state.error = action.payload;
        state.isAuthenticated = false;
        state.isLoading = false;
        state.userData = null;
        toast.error(action.payload)
      })
      .addCase(Invoices.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(Invoices.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isAuthenticated = true;
        state.userData = action.payload;
        toast.success(action.payload.message)

      })
      .addCase(Invoices.rejected, (state, action) => {
        state.error = action.payload;
        state.isAuthenticated = false;
        state.isLoading = false;
        state.userData = null;
        toast.error(action.payload)
      })
      .addCase(Defaulters.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(Defaulters.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isAuthenticated = true;
        state.userData = action.payload;
        toast.success(action.payload.message)

      })
      .addCase(Defaulters.rejected, (state, action) => {
        state.error = action.payload;
        state.isAuthenticated = false;
        state.isLoading = false;
        state.userData = null;
        toast.error(action.payload)
      })
      .addCase(Worcqpital.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(Worcqpital.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isAuthenticated = true;
        state.userData = action.payload;
        toast.success(action.payload.message)

      })
      .addCase(Worcqpital.rejected, (state, action) => {
        state.error = action.payload;
        state.isAuthenticated = false;
        state.isLoading = false;
        state.userData = null;
        toast.error(action.payload)
      })
      .addCase(Usergetall.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(Usergetall.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isAuthenticated = true;
        state.userData = action.payload;
        toast.success(action.payload.message)

      })
      .addCase(Usergetall.rejected, (state, action) => {
        state.error = action.payload;
        state.isAuthenticated = false;
        state.isLoading = false;
        state.userData = null;
        toast.error(action.payload)
      })
      .addCase(Useradd.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(Useradd.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isAuthenticated = true;
        state.userData = action.payload;
        toast.success(action.payload.message)

      })
      .addCase(Useradd.rejected, (state, action) => {
        state.error = action.payload;
        state.isAuthenticated = false;
        state.isLoading = false;
        state.userData = null;
        toast.error(action.payload)
      })
      .addCase(addRoute.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addRoute.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isAuthenticated = true;
        state.userData = action.payload;
        toast.success(action.payload.message)

      })
      .addCase(addRoute.rejected, (state, action) => {
        state.error = action.payload;
        state.isAuthenticated = false;
        state.isLoading = false;
        state.userData = null;
        toast.error(action.payload)
      })
      .addCase(PdfGnret.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(PdfGnret.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isAuthenticated = true;
        state.userData = action.payload;
        toast.success(action.payload.message)

      })
      .addCase(PdfGnret.rejected, (state, action) => {
        state.error = action.payload;
        state.isAuthenticated = false;
        state.isLoading = false;
        state.userData = null;
        toast.error(action.payload)
      })
  },
});


export default categorySlice.reducer;