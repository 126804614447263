import React, { useState, useEffect } from 'react';
import { TreeSelect } from 'antd';

const { TreeNode } = TreeSelect;

function TaskPage() {
//   const [lines, setLines] = useState([{ name: '', email: '', task: '' }]);
//   const [treeData, setTreeData] = useState([]);
//   const [valueTree, setValueTree] = useState(null);

//   useEffect(() => {
//     // Fetch data from API
//     fetchTreeData();
//   }, []);

//   const fetchTreeData = async () => {
//     try {
//       // Fetch data from API
//       const response = await fetch('your_api_url');
//       const data = await response.json();
//       // Transform the data if needed
//       const transformedData = transformData(data);
//       // Set the tree data
//       setTreeData(transformedData);
//     } catch (error) {
//       console.error('Error fetching tree data:', error);
//     }
//   };

//   const handleAddLine = () => {
//     setLines([...lines, { name: '', email: '', task: '' }]);
//   };

//   const handleInputChange = (index, field, value) => {
//     const newLines = [...lines];
//     newLines[index][field] = value;
//     setLines(newLines);
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     console.log(lines);
//   };

//   const onChangetree = (value) => {
//     setValueTree(value);
//   };

  return (
    <div>
      {/* {lines.map((line, index) => (
        <div key={index} className="row">
          <div className="col">
            <TreeSelect
              style={{ width: '100%' }}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              placeholder="Select a person"
              treeDefaultExpandAll
              value={line.name}
              onChange={(value) => handleInputChange(index, 'name', value)}
              treeData={treeData}
            />
          </div>
          <div className="col">
            <input
              type="email"
              className="form-control"
              placeholder="Email"
              name="email"
              value={line.email}
              onChange={(e) => handleInputChange(index, 'email', e.target.value)}
            />
          </div>
          <div className="col">
            <input
              type="text"
              className="form-control"
              placeholder="Task"
              name="task"
              value={line.task}
              onChange={(e) => handleInputChange(index, 'task', e.target.value)}
            />
          </div>
          <div className="col">
            {index === lines.length - 1 && (
              <button onClick={handleAddLine}>add new line</button>
            )}
          </div>
        </div>
      ))} */}

      {/* <button onClick={handleSubmit}>Submit</button> */}
    </div>
  );
}

export default TaskPage;
